import { Component, OnInit, ViewChild } from '@angular/core';
import { Movie } from 'app/_models/movie';
import { MovieService } from 'app/_services/movie.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { Http, ResponseContentType } from '@angular/http';
import { MatDialog } from '@angular/material';
import { CinemaService } from '../../../_services/cinema.service';
import { Page } from '../../../_models/page';
import { MovieReleaseService } from '../../../_services/movie_release.service';

const API_URL = environment.apiUrl;

@Component({
  selector: 'fuse-distributor-movies',
  templateUrl: './distributor-movies.component.html',
  styleUrls: ['./distributor-movies.component.scss']
})

export class DistributorMoviesComponent implements OnInit {

  @ViewChild('myTable') table: any;
  title = 'moje filmy';
  context = '';
  rows: Movie[] = [];
  temp: any = [];
  expanded: any[] = [];
  loadingIndicator = true;
  reorderable = true;
  api_url = API_URL;
  active = 0;
  only_new_movies = 0;
  movie: string = null;
  user: any;
  page = new Page();
  sort: any = null;
  filter: any = null;
  sub: any;

  constructor(
    public dialog: MatDialog,
    private http: Http,
    private router: Router,
    private movieService: MovieService,
    private toastr: ToastrService,
    private movieReleaseService: MovieReleaseService,
    private cinemaService: CinemaService,
        private route: ActivatedRoute
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.user = user;

    this.context = user.distributor_name;
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.only_new_movies = +params['news'] || 0;
        this.filterTable();
      });
  }

  setPage(pageInfo: any) {
    this.loadingIndicator = true;
    this.rows = [];
    if (pageInfo) {
      this.page.pageNumber = pageInfo.offset;
    }

    this.movieService.getAll(this.page, this.sort, this.filter)
      .subscribe(data => {
        this.page = data.paging;
        this.rows = data.data;
        this.temp = [...data.data];
        this.loadingIndicator = false;
      });
  }

  expand() {
    this.table.rowDetail.collapseAllRows();
    for (let i = 0; i < this.rows.length; i++) {
      if (this.expanded.indexOf(this.rows[i].id) > -1) {
        this.table.rowDetail.toggleExpandRow(this.rows[i]);
      }
    }
  }

  toggleExpandRow(row: any) {
    const index = this.rows.indexOf(row);
    if (this.temp[index].movie_release_full === undefined) {
      this.movieReleaseService.getViewId(row.id)
        .subscribe(data => {
          (<any>this.rows[index]).movie_release_full = data.data;
          if (this.expanded.includes(row.id)) {
            this.expanded.splice(this.expanded.indexOf(row.id), 1);
          } else {
            this.expanded.push(row.id);
          }
          this.table.rowDetail.toggleExpandRow(row);
        });
    }
    else {
      if (this.expanded.includes(row.id)) {
        this.expanded.splice(this.expanded.indexOf(row.id), 1);
      }
      else {
        this.expanded.push(row.id);
      }
      this.table.rowDetail.toggleExpandRow(row);
    }
  }

  downloadFile(package_id: number, movie_release_id: number) {
    this.cinemaService.downloadPackage(package_id, movie_release_id)
      .subscribe(data => {
        const url = this.api_url + '/' + data.data;
        const filename = url.split('/').pop();
        return this.http
          .get(url, {
            responseType: ResponseContentType.Blob,
          })
          .map(res => {
            return {
              filename: filename,
              data: res.blob()
            };
          })
          .subscribe(res => {
            const _url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = _url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(_url);
            a.remove(); // remove the element
          }, error => {
            this.toastr.error(error.error.message, 'Błąd');
          }, () => {
          });
      });
  }

  filterTable() {
    this.filter = {
      search: null,
      active: 0,
      only_new_movies: 0
    };

    if (this.active) {
      this.filter.active = this.active;
    }
    if (this.only_new_movies) {
      this.filter.only_new_movies = this.only_new_movies;
    }
    if (this.movie != null) {
      this.filter.search = this.movie;
    }

    this.setPage({ offset: 0 });
  }

  sortTable(data: any) {
    const prop = data.sorts[0].prop,
      dir = data.sorts[0].dir;

    this.sort = {};
    this.sort[prop] = dir;

    this.setPage({ offset: 0 });
  }

  getRowClass(row: any) {
    if (row.invalid === 2) {
      return 'error-row';
    }
    else if (row.invalid === 1) {
      return 'warning-row';
    }
    return '';
  }

  addMovie(): void {
      this.router.navigate(['distributor_movie/add']);
  }
}
