import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Cinema} from 'app/_models/cinema';
import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class CinemaService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/cinemas', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getView(word: any = null) {
        return this.http.post<any>(API_URL + '/api/cinemas/view', {loggedInId: this.currentUser.id, token: this.currentUser.token, word});
    }
    getFull(word: any) {
        return this.http.post<any>(API_URL + '/api/cinemas/full', {loggedInId: this.currentUser.id, token: this.currentUser.token, word});
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinemas/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getMyCinema() {
        return this.http.post<any>(API_URL + '/api/cinemas/get/' + this.currentUser.cinema_id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    locate(id: any) {
        return this.http.post<any>(API_URL + '/api/cinemas/locate/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(cinema: Cinema) {
        return this.http.post<any>(API_URL + '/api/cinemas/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema});
    }

    update(cinema: Cinema) {
        return this.http.put<any>(API_URL + '/api/cinemas/edit/' + cinema.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema});
    }
    updateMyCinema(cinema: Cinema) {
        cinema.id = this.currentUser.cinema_id;
        return this.http.put<any>(API_URL + '/api/cinemas/edit/' + cinema.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/cinemas/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    find(cinema: any) {
        return this.http.post<any>(API_URL + '/api/cinemas/find', {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema});
    }

    getKdmsById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinemas/get_kdms/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getKdmsModalById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinemas/get_kdms_modal/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getHistory(id: number) {
        return this.http.post<any>(API_URL + '/api/cinemas/history/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    downloadPackage(package_id: any, movie_release_id: any) {
        return this.http.post<any>(API_URL + '/api/cinemas/package', {loggedInId: this.currentUser.id, token: this.currentUser.token, package_id, movie_release_id});
    }
}