import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {PasswordResetService} from 'app/_services/password-reset.service';
import {fuseAnimations} from '@fuse/animations';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../login.component.scss'],
    animations: fuseAnimations

})
export class PasswordResetComponent implements OnInit {

    resetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private passwordResetService: PasswordResetService,
        private fuseConfig: FuseConfigService,
        private toastr: ToastrService
    ) {
        this.fuseConfig.setConfig({
            layout: {
                navigation: 'none',
                toolbar: 'none',
                footer: 'none'
            }
        });
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            username: ['', Validators.required],
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    }

    get f() {return this.resetForm.controls;}

    onSubmit() {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return;
        }
        this.loading = true;
        this.passwordResetService.reset(this.f.username.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.toastr.success(data.message, 'Sukces');
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                    this.loading = false;
                });
    }

}
