import {Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DistributorService} from 'app/_services/distributor.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-distributor-add',
    templateUrl: './distributor-add.component.html',
    styleUrls: ['./distributor-add.component.scss']
})
export class DistributorAddComponent implements OnInit {

    addForm: FormGroup;
    formErrors: any;
    mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
    fileImg: any = '';
    logo: any = null;

    constructor(
        private formBuilder: FormBuilder,
        private distributorService: DistributorService,
        public dialogRef: MatDialogRef<DistributorAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            email: {},
            description: {},
            active: {},
            logo: {},
        };
    }

    ngOnInit() {
        this.addForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required],
            email: ['', [
                Validators.required,
            ]],
            contact_address: [''],
            contact_city: [''],
            contact_postcode: [''],
            billing_address: [''],
            billing_postcode: [''],
            billing_city: [''],
            nip: [''],
            description: [''],
            active: [true],
            logo: [''],
        });
        this.addForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }
    onSubmit() {
        this.onFormValuesChanged();
        if (this.addForm.invalid) {
            return;
        }
        const addDistributor = this.addForm.value;
        if (this.fileImg !== '') {
            addDistributor.logo = this.fileImg.name;
            addDistributor.logo_result = this.fileImg.result;
            addDistributor.logo_type = this.fileImg.type;
        }
        this.distributorService.create(addDistributor)
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }
    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.addForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.logo = reader.result;
                }
                let filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.fileImg = filex;
                } else {
                    this.toastr.error('błędny format wymagany jpg lub png', 'Błąd');
                }
            };
        }
    }
}
