export class MainChart {
    values: any = [];
    all: any = null;
    labels: any = [];
    type: string = 'line';
    colors: any = [
        {
            borderColor: '#42a5f5',
            backgroundColor: '#42a5f5',
            pointBackgroundColor: '#1e88e5',
            pointHoverBackgroundColor: '#1e88e5',
            pointBorderColor: '#ffffff',
            pointHoverBorderColor: '#ffffff'
//            borderColor: '#ffffff',
//            backgroundColor: '#ffffff',
//            pointBackgroundColor: '#000000',
//            pointHoverBackgroundColor: '#000000',
//            pointBorderColor: '#ffffff',
//            pointHoverBorderColor: '#ffffff'
        }
    ];
    options: any = {
        spanGaps: false,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 32,
                left: 32,
                right: 32
            }
        },
        elements: {
            point: {
                radius: 4,
                borderWidth: 2,
                hoverRadius: 4,
                hoverBorderWidth: 2
            },
            line: {
                tension: 0
            }
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        tickMarkLength: 18
                    },
                    ticks: {
                        fontColor: '#ffffff'
                    }
                }
            ],
            yAxes: [
                {
                    display: false,
                    ticks: {
                        min: 0,
                        max: 750,
                        stepSize: 1
                    }
                }
            ]
        },
        plugins: {
            filler: {
                propagate: false
            },
            xLabelsOnTop: {
                active: true
            }
        }
    };

}
