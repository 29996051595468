import {Component, OnInit} from '@angular/core';
import {User} from 'app/_models/user';
import {UserService} from 'app/_services/user.service';
import {ToastrService} from 'ngx-toastr';
import {PasswordResetService} from 'app/_services/password-reset.service';
import {first} from 'rxjs/operators';
import {EditUserComponent} from './edit-user/edit-user.component';
import {AddUserComponent} from './add-user/add-user.component';
import {MatDialog} from '@angular/material';
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    rows: User[] = [];
    loadingIndicator = true;
    reorderable = true;
    constructor(
        private userService: UserService,
        private passwordResetService: PasswordResetService,
        private toastr: ToastrService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.userService.getAll()
            .subscribe(data => {
                this.rows = data.data;
                this.loadingIndicator = false;
            });
    }

    deleteUser(user: number): void {
        this.userService.delete(user)
            .subscribe(
                data => {
                    this.rows = this.rows.filter(u => u.id !== user);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                })
    };

    editUser(user: User): void {
        localStorage.removeItem("editUserId");
        localStorage.setItem("editUserId", user.toString());
        let userEditDialog = this.dialog.open(EditUserComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        userEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(user)) {
                        let rows = [...this.rows];
                        rows[i] = result.data;
                        this.rows = rows;
                    }
                }
            }
        });
    };
    sendResetEmailUser(user: User): void {
        this.userService.getById(+user)
            .subscribe(data => {
                this.passwordResetService.reset(data.data.username)
                    .pipe(first())
                    .subscribe(
                        data => {
                            this.toastr.success(data.message, 'Sukces');
                        },
                        error => {
                            this.toastr.error(error.error.message, 'Błąd');
                        });
            });

    };
    addUser(): void {
        let userAddDialog = this.dialog.open(AddUserComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        userAddDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                let rows = [...this.rows];
                rows.push(result.data);
                this.rows = rows;
            }
        });
    };
}
