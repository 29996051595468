import {Injectable} from '@angular/core';
import {of as ofObservable, BehaviorSubject} from 'rxjs';

export class TodoItemNodeFinish {
    error: any;
    children: TodoItemNodeFinish[];
    kdms_list: any[];
    date_time_from: Date;
    date_time_to: Date;
    days: string;
    item: string;
    cinema_id: string;
    id: string;

}

export class TodoItemFlatNodeFinish {
    error: any;
    cinema_id: string;
    item: string;
    kdms_list: any[];
    level: number;
    date_time_from: Date;
    date_time_to: Date;
    days: string;
    expandable: boolean;
    id: string;
}

@Injectable()
export class KdmDatabaseFinish {
    dataChange: BehaviorSubject<TodoItemNodeFinish[]> = new BehaviorSubject<TodoItemNodeFinish[]>([]);
    dataCopy: any[] = [];
    subscription: any = null;
    get data(): TodoItemNodeFinish[] { return this.dataChange.value; }
    constructor() {
        this.initialize();
    }

    initialize() {}

    buildFileTree(value: any, level: number) {
        const data: any[] = [];
        for (const k in value) {
          if (value.hasOwnProperty(k)) {
            const v = value[k];
            const node = new TodoItemNodeFinish();
            node.item = `${k}`;
            if (typeof v.childrens === 'object'){
              node.item = `${v.index}. ${k}`;
            }
            if (typeof v.id !== 'undefined') {
                node.id = `${v.id}`;
                node.date_time_from = v.from;
                node.date_time_to = v.to;
                node.days = v.days;
            } else {
                node.id = v;
                node.date_time_from = v.from;
                node.date_time_to = v.to;
                node.days = v.days;
            }
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v.childrens === 'object') {
                node.children = this.buildFileTree(v.childrens, level + 1);
            } else {
                node.kdms_list = v.kdms_list;
                node.cinema_id = v.cinema_id;
                node.item = v.name;
                node.id = v.id;
            }
            data.push(node);
          }
        }
        return data;
    }

    searchInTree(search: string) {
        const val = search.toLowerCase();
        const dataCopyIner: any[] = JSON.parse(JSON.stringify(this.dataCopy));
        this.dataCopy.forEach((item, index) => {
            if (item.item.toLowerCase().indexOf(val) === -1) {
                for (let i = 0; i < dataCopyIner.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (item.id == dataCopyIner[i].id && item.item == dataCopyIner[i].item) {
                        dataCopyIner.splice(i, 1);
                    }
                }
            }
        });
        this.dataChange.next(dataCopyIner);
    }

    generateKdmFinishTree(finished: any) {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
        this.dataChange = new BehaviorSubject<TodoItemNodeFinish[]>([]);
        this.dataCopy = [];
        const to_build: any = [];
        for (let j = 0; j < finished.length; j++) {
            const childrens: any = [];
            for (let _i = 0; _i < finished[j].rooms.length; _i++) {
                childrens[finished[j].rooms[_i].item] = {
                    'id': finished[j].rooms[_i].id,
                    'cinema_id': finished[j].rooms[_i].cinema_id,
                    'name': finished[j].rooms[_i].item,
                    'kdms_list': []
                };
            }
            to_build[finished[j].item] = {'id': finished[j].id, 'from': finished[j].from, 'to': finished[j].to, 'days': finished[j].days, 'childrens': childrens, 'index': (j + 1).toString()};
        }
        const xdata = this.buildFileTree(to_build, 0);
        this.dataChange.next(xdata);
        this.dataCopy = this.dataChange.getValue();
        return this.dataChange;
    }

}
