import {NgModule} from '@angular/core';

import {MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';

import {LoginComponent} from './login.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {routing} from 'app/app.routing';
import {AuthenticationService} from 'app/_services/authentication.service';
import {UserService} from 'app/_services/user.service';
import {PasswordResetService} from 'app/_services/password-reset.service';
import {AuthGuard} from 'app/_guards/auth.guard';
import { PasswordResetFinishComponent } from './password-reset-finish/password-reset-finish.component';
@NgModule({
    declarations: [
        LoginComponent,
        PasswordResetComponent,
        PasswordResetFinishComponent
    ],
    imports: [
        routing,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,

        FuseSharedModule
    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        PasswordResetService,
        UserService,
    ]
})
export class LoginModule {
}