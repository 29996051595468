import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CinemaRoom} from 'app/_models/cinema_room';
import {CinemaRoomExt} from 'app/_models/cinema_room_ext';
import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class CinemaRoomService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/cinema_rooms', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getView(word: any = null) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/get/view', {loggedInId: this.currentUser.id, token: this.currentUser.token, word});
    }
    getViewId(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/get/view/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(cinema_room: CinemaRoom) {
        return this.http.post(API_URL + '/api/cinema_rooms/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema_room});
    }

    update(cinema_room: CinemaRoom) {
        return this.http.put(API_URL + '/api/cinema_rooms/edit/' + cinema_room.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema_room});
    }

    changeDKDM(cinema_room: CinemaRoomExt) {
        return this.http.put(API_URL + '/api/cinema_rooms/change_dkdm/' + cinema_room.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema_room});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getKdmsById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/get_kdms/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getHistoryById(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/get_history/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    certUpdate(id: number) {
        return this.http.post<any>(API_URL + '/api/cinema_rooms/cert_update/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
}