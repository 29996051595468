import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'app/_services/user.service';
import { Router } from '@angular/router';
import { User } from 'app/_models/user';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Role } from 'app/_models/role';
import { RoleService } from 'app/_services/role.service';
import { Distributor } from 'app/_models/distributor';
import { DistributorService } from 'app/_services/distributor.service';
import { Cinema } from 'app/_models/cinema';
import { CinemaService } from 'app/_services/cinema.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MultiplexService } from '../../../../_services/multiplex.service';
import { Multiplex } from '../../../../_models/multiplex';
import { ListsService } from 'app/_services/lists.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  roles: Role[] = [];
  user: User;
  editForm: FormGroup;
  formErrors: any;
  distributors: Distributor[] = [];
  cinemas: Cinema[] = [];
  multiplexes: Multiplex[] = [];
  selectedCinemas: any[] = this.cinemas;
  searchInputCinema: string = null;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    private cinemaService: CinemaService,
    private distributorService: DistributorService,
    private multiplexService: MultiplexService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private listsService: ListsService
  ) {
    this.formErrors = {
      first_name: {},
      last_name: {},
      username: {},
      role_id: {},
      active: {},
      distributor_id: {},
      cinema_id: {},
      multiplex_id: {},
    };
  }

  ngOnInit() {
    this.roleService.getAll()
      .subscribe(data => {
        this.roles = data.data;
      });
    const userId = localStorage.getItem('editUserId');
    if (!userId) {
      alert('Invalid action.');
      this.router.navigate(['users']);
      return;
    }
    this.listsService.cinema(true)
      .subscribe(data => {
        this.cinemas = data.data;
        this.selectedCinemas = this.cinemas;
      });
    this.distributorService.getAll()
      .subscribe(data => {
        this.distributors = data.data;
      });
    this.multiplexService.getAll()
      .subscribe(data => {
        this.multiplexes = data.data;
      });
    this.editForm = this.formBuilder.group({
      id: [],
      username: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]],
      first_name: ['', [
        Validators.required,
        Validators.maxLength(100),
      ]],
      last_name: ['', [
        Validators.required,
        Validators.maxLength(100),
      ]],
      role_id: ['', Validators.required],
      active: ['', Validators.required],
    });

    this.userService.getById(+userId)
      .subscribe(data => {
        this.editForm.controls['id'].setValue(data.data.id);
        this.editForm.controls['first_name'].setValue(data.data.first_name);
        this.editForm.controls['last_name'].setValue(data.data.last_name);
        this.editForm.controls['username'].setValue(data.data.username);
        this.editForm.controls['role_id'].setValue(data.data.role_id);
        this.editForm.controls['active'].setValue(data.data.active);
        if (data.data.role_id === 2) {
          this.editForm.addControl('cinema_id', new FormControl(data.data.cinema_id, Validators.required));
        }
        else if (data.data.role_id === 3) {
          this.editForm.addControl('distributor_id', new FormControl(data.data.distributor_id, Validators.required));
        }
        else if (data.data.role_id === 4) {
          this.editForm.addControl('multiplex_id', new FormControl(data.data.multiplex_id, Validators.required));
        }
      });

    this.editForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }
  onSubmit() {
    this.onFormValuesChanged();
    if (this.editForm.invalid) {
      return;
    }
    this.userService.update(this.editForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.dialogRef.close(data);
        },
        error => {
          this.toastr.error(error.error.message, 'Błąd');
        });
  }
  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.editForm.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
  changeRole(event: any) {
    this.editForm.removeControl('cinema_id');
    this.editForm.removeControl('distributor_id');
    this.editForm.removeControl('multiplex_id');
    if (event.value === 2) {
      this.editForm.addControl('cinema_id', new FormControl('', Validators.required));
    }
    else if (event.value === 3) {
      this.editForm.addControl('distributor_id', new FormControl('', Validators.required));
    }
    else if (event.value === 4) {
      this.editForm.addControl('multiplex_id', new FormControl('', Validators.required));
    }
  }

  searchCinema(query: string) {
    const result = this.selectCinema(query);
    this.selectedCinemas = result;
  }

  selectCinema(query: string): Cinema[] {
    const result: Cinema[] = [];
    // tslint:disable-next-line:prefer-const
    for (let a of this.cinemas) {
      if ((<any> a).full_name.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  cleanSearchImputCinema() {
    this.searchInputCinema = '';
    this.selectedCinemas = this.cinemas;
  }
}
