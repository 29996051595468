import { Pipe, PipeTransform } from '@angular/core';
import { Cinema } from './../_models/cinema';

@Pipe({ name: 'cinemaHideMultiplexInfo' })
export class CinemaHideMultiplexInfoPipe implements PipeTransform {
  transform(cinema: Cinema, firstCity = true) {
    let result = '';
    if (cinema.city) {
      result += cinema.city.trim();
    }
    if (cinema.name) {
      if (result.length) {
        if (firstCity) {
          result += ', ';
        }
        else {
          result = ', ' + result;
        }
      }

      if (firstCity) {
        result += cinema.name.trim();
      }
      else {
        result = cinema.name.trim() + result;
      }
    }
    return result;
  }
}
