import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class KdmService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    generate(release_id: number, cinema_room_id: number, cinema_id: number, date_from: string, date_to: string, new_cert: number = 0) {
        return this.http.post<any>(API_URL + '/api/kdm/generate', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
            release_id: release_id,
            cinema_room_id: cinema_room_id,
            cinema_id: cinema_id,
            date_from: date_from,
            date_to: date_to,
            new_cert: new_cert
        });
    }
    getByRoom(cinemas: any) {
        return this.http.post<any>(API_URL + '/api/kdm/generated', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
            cinemas: cinemas,
        });
    }
    sendEmail(cinemaRooms: any, cinemaId: string, message: string, release: any) {
        return this.http.post<any>(API_URL + '/api/messages/send', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
            cinemaRooms: cinemaRooms,
            cinemaId: cinemaId,
            message: message,
            release: release,
        });
    }
    getCurrentMoviesCinemaRoom(cinemaRoomId: any) {
        return this.http.post<any>(API_URL + '/api/movies/get_by_room/' + cinemaRoomId, {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
}