import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from 'app/_models/user';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class LogService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/logs', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    saveLog(action: any, message: any) {
        return this.http.post<any>(API_URL + '/api/logs/save', {loggedInId: this.currentUser.id, token: this.currentUser.token, action: action, message: message});
    }
}