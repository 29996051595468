export class MovieChart {
    values: any = [];
    labels: any = [];
    all: any = null;
    type: string = 'bar';
    conversion: any = {
        value: 492,
        ofTarget: 13
    };
    colors: any = [
        {
            borderColor: '#42a5f5',
            backgroundColor: '#42a5f5'
        }
    ];
    options: any = {
        spanGaps: false,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 16,
                left: 16,
                right: 16,
                bottom: 16
            }
        },
        scales: {
            xAxes: [
                {
                    display: false
                }
            ],
            yAxes: [
                {
                    display: false,
                    ticks: {
                        min: 0,
                        max: 30
                    }
                }
            ]
        }
    };

}
