import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {PasswordResetService} from 'app/_services/password-reset.service';
import {fuseAnimations} from '@fuse/animations';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-password-reset-finish',
    templateUrl: './password-reset-finish.component.html',
    styleUrls: ['../login.component.scss'],
    animations: fuseAnimations

})
export class PasswordResetFinishComponent implements OnInit {

    resetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    passkey: string;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private passwordResetService: PasswordResetService,
        private fuseConfig: FuseConfigService,
        private toastr: ToastrService
    ) {
        this.fuseConfig.setConfig({
            layout: {
                navigation: 'none',
                toolbar: 'none',
                footer: 'none',
                navbar: 'none'
            }
        });
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            password: ['', Validators.required],
            repeat: ['', Validators.required],
        }, {validator: this.matchingPasswords('password', 'repeat')});
        this.route.params.subscribe(params => {
            this.passkey = params['passkey'];
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    }

    get f() {return this.resetForm.controls;}

    onSubmit() {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return;
        }
        this.loading = true;
        this.passwordResetService.resetFinish(this.f.password.value, this.passkey)
            .pipe(first())
            .subscribe(
                data => {
                    this.toastr.success(data.message, 'Sukces');
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                    this.loading = false;
                });
    }
    matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            let passwordInput = group.controls[passwordKey];
            let passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({notEquivalent: true})
            }
        }
    }
}
