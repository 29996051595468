import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {MovieRelease} from 'app/_models/movie_release';
import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class MovieReleaseService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/movie_releases', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/movie_releases/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getViewId(id: number, release_id: number = null) {
        return this.http.post<any>(API_URL + '/api/movie_releases/get/view/' + id + (release_id ? '/' + release_id : ''), 
            {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(movie_release: MovieRelease) {
        return this.http.post(API_URL + '/api/movie_releases/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, movie_release});
    }

    update(movie_release: MovieRelease) {
        return this.http.put(API_URL + '/api/movie_releases/edit/' + movie_release.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, movie_release});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/movie_releases/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getKdmsById(id: number) {
        return this.http.post<any>(API_URL + '/api/movie_releases/get_kdms/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    downloadPackage(cinema_id: any, release_id: any) {
        return this.http.post<any>(API_URL + '/api/movie_releases/download_package', {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema_id, release_id});
    }
}