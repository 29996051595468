import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpModule} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {ToastrModule} from 'ngx-toastr';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';

import {fuseConfig} from './fuse-config';

import {AppComponent} from './app.component';
import {FuseMainModule} from './main/main.module';
import {LoginModule} from './login/login.module';
import {AuthenticationService} from './_services/authentication.service';

import {UserService} from './_services/user.service';
import {AuthGuard} from './_guards/auth.guard';
import {MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatRadioModule} from '@angular/material';

import {routing} from './app.routing';
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        LoginModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        HttpModule,
        routing,
        TranslateModule.forRoot(),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,

    ], providers: [
        AuthGuard,
        AuthenticationService,
        UserService,
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [
        MatDialogModule,
    ],
})

export class AppModule {
}
