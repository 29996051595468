import {Component, OnInit, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {CinemaRoomService} from 'app/_services/cinema_room.service';

@Component({
    selector: 'app-cinema-room-cert-change',
    templateUrl: './cinema-room-cert-change.component.html',
    styleUrls: ['./cinema-room-cert-change.component.scss']
})
export class CinemaRoomCertChangeComponent implements OnInit {

    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<CinemaRoomCertChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService,
        private cinemaRoomService: CinemaRoomService,
    ) {}

    ngOnInit() {
        let cinemaRoomId = localStorage.getItem("certCinemaRoomId");
        if (!cinemaRoomId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }
    }
    generateKdms() {
        this.cinemaRoomService.certUpdate(+localStorage.getItem("certCinemaRoomId"))
            .subscribe(data => {
                this.toastr.success('Wysłano ponownie: ' + data.data, 'Sukces');
            });
    }
}
