export const navigation = [
    {
        'id': 'menu',
        'title': 'Menu',
        'type': 'group',
        'children': [
            {
                'id': 'dashboard',
                'title': 'Dashboard',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/dashboard',
            },
            {
                'id': 'movies',
                'title': 'Filmy',
                'type': 'item',
                'icon': 'theaters',
                'url': '/movies',

            },
            {
                'id': 'cinemas',
                'title': 'Kina',
                'type': 'item',
                'icon': 'video_call',
                'url': '/cinemas',

            },
            {
                'id': 'reports',
                'title': 'Klucze',
                'type': 'collapse',
                'icon': 'timeline',
                'children': [
                    {
                        'id': 'distributors_reports',
                        'title': 'Raporty',
                        'type': 'item',
                        'url': '/reports/distributores',
                    },
                    {
                        'id': 'kdm_reports',
                        'title': 'Wysłane',
                        'type': 'item',
                        'url': '/reports/kdm',
                    }
                ]
            },
            {
                'id': 'settings',
                'title': 'Ustawienia',
                'type': 'collapse',
                'icon': 'settings',
                'children': [
                    {
                        'id': 'kdm_settings',
                        'title': 'Certyfikat serwera',
                        'type': 'item',
                        'url': '/settings/certificate',

                    },
                    {
                        'id': 'kdm_settings_edit',
                        'title': 'Ustawienia',
                        'type': 'item',
                        'url': '/settings/edit',

                    },
                    {
                        'id': 'distributors',
                        'title': 'Dystrybutorzy',
                        'type': 'item',
                        'url': '/distributors',

                    },
                    {
                        'id': 'multiplexes',
                        'title': 'Grupy',
                        'type': 'item',
                        'url': '/multiplexes',

                    },
                    {
                        'id': 'users',
                        'title': 'Użytkownicy',
                        'type': 'item',
                        'url': '/users',

                    },
                    {
                        'id': 'logs',
                        'title': 'Logi',
                        'type': 'item',
                        'url': '/logs',

                    },
                ]
            }
        ]
    }
];
