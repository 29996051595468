import {Component, OnInit, ViewChild} from '@angular/core';
import {Movie} from 'app/_models/movie';
import {MovieService} from 'app/_services/movie.service';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
import {MatDialog} from '@angular/material';
import {MovieEditComponent} from './movie-edit/movie-edit.component';
import {MovieReleaseEditComponent} from './movie-release-edit/movie-release-edit.component';
import {MovieReleaseAddComponent} from './movie-release-add/movie-release-add.component';
import {MovieReleaseKdmsComponent} from './movie-release-kdms/movie-release-kdms.component';
import {MovieKdmsComponent} from './movie-kdms/movie-kdms.component';
import { Page } from '../../../_models/page';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MovieRelease } from '../../../_models/movie_release';
import { MovieReleaseEditFullComponent } from './movie-release-edit/movie-release-edit-full.component';

const API_URL = environment.apiUrl;

@Component({
    selector: 'app-movies',
    templateUrl: './movies.component.html',
    styleUrls: ['./movies.component.scss']
})

export class MoviesComponent implements OnInit {

    @ViewChild('myTable') table: DatatableComponent;
    rows: Movie[] = [];
    temp: any = [];
    expanded: any[] = [];
    loadingIndicator = true;
    reorderable = true;
    api_url = API_URL;
    only_new_movies = 0;
    movie: string = null;
    page = new Page();
    sort: any = null;
    filter: any = null;
    sub: any;

    constructor(
        public dialog: MatDialog,
        private http: Http,
        private router: Router,
        private movieService: MovieService,
        private movieReleaseService: MovieReleaseService,
        private toastr: ToastrService,
        private route: ActivatedRoute
    ) {
      this.page.pageNumber = 0;
      this.page.size = 25;
    }
    ngOnInit() {
      this.sub = this.route
        .queryParams
        .subscribe(params => {
          this.only_new_movies = +params['news'] || 0;
          this.filterTable();
        });
    }

    setPage(pageInfo: any) {
      this.loadingIndicator = true;
      this.rows = [];
      if (pageInfo) {
        this.page.pageNumber = pageInfo.offset;
      }
      this.movieService.getAll(this.page, this.sort, this.filter)
        .subscribe(data => {
          this.page = data.paging;
          this.rows = data.data;
          this.temp = [...data.data];
          this.loadingIndicator = false;

          if (!pageInfo) {
            this.expand();
          }
        });
    }

    deleteMovie(movie: number): void {
        this.movieService.delete(movie)
            .subscribe(
                data => {
                    this.rows = this.rows.filter(u => u.id !== movie);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    deleteMovieRelease(movie_release: number): void {
        this.movieReleaseService.delete(movie_release)
            .subscribe(
                data => {
                    if (this.rows.length > 0 && data !== undefined && data.data !== undefined) {
                        for (let i = 0; i < this.rows.length; i++) {
                            if (this.rows[i].id === Number(data.data.movie_id)) {
                              const tmp = (<any>this.rows[i]).movie_release_full;
                              let invalid = 0;

                                for (let j = 0; j < tmp.length; j++) {

                                    if (tmp[j].id === Number(data.data.id)) {
                                        tmp.splice(j, 1);
                                        (<any>this.rows[i]).releases_cnt -= 1;
                                    }
                                    else if (invalid < tmp[j].invalid) {
                                      invalid = tmp[j].invalid;
                                    }

                                    if (tmp.length === 0) {
                                      (<any>this.rows[i]).invalid = 0;
                                      this.toggleExpandRow(this.rows[i]);
                                      return;
                                    }
                                }

                                (<any>this.rows[i]).invalid = invalid;
                            }
                        }
                    }
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    editMovie(movie: Movie): void {
        localStorage.removeItem('editMovieId');
        localStorage.setItem('editMovieId', movie.toString());
        const movieEditDialog = this.dialog.open(MovieEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        movieEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(movie)) {
                        const rows = [...this.rows];
                        rows[i] = result.data;
                        this.rows = rows;
                    }
                }
            }
        });
    }

    addMovieRelease(movieId: number): void {
        localStorage.removeItem('movieId');
        localStorage.setItem('movieId', movieId.toString());
        const releaseAddDialog = this.dialog.open(MovieReleaseAddComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
        releaseAddDialog.afterClosed().subscribe(result => {
          if (this.rows.length > 0 && result !== undefined && result.data !== undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id === Number(result.data.movie_id)) {
                      (<any>this.rows[i]).movie_release_full.push(result.data);
                      (<any>this.rows[i]).releases_cnt += 1;
                    }
                }
            }
           // this.setPage(false);
        });
    }

    editMovieReleaseFull(movieRelease: MovieRelease): void {
        const releaseEditDialog = this.dialog.open(MovieReleaseEditFullComponent, {
            width: '500px',
            data: {name: this.reorderable, data: movieRelease}
        });
        releaseEditDialog.afterClosed().subscribe(result => {
          if (this.rows.length > 0 && result !== undefined && result.data !== undefined) {
            for (let i = 0; i < this.rows.length; i++) {
              if (this.rows[i].id === Number(result.data.movie_id)) {
                const tmp = (<any>this.rows[i]).movie_release_full;
                let invalid = 0;

                for (let j = 0; j < tmp.length; j++) {
                  if (tmp[j].id === result.data.id) {
                    tmp[j].certificate_path = result.data.certificate_path;
                    tmp[j].supplement = result.data.supplement;
                    tmp[j].name = result.data.name;
                    tmp[j].active = result.data.active;
                    tmp[j].valid_to = result.data.valid_to;
                    tmp[j].invalid = result.data.invalid;
                  }

                  if (invalid < tmp[j].invalid) {
                    invalid = tmp[j].invalid;
                  }
                }

                (<any>this.rows[i]).invalid = invalid;
              }
            }
          }
        });
    }

    editMovieRelease(movieReleaseId: number): void {
        localStorage.removeItem('editMovieReleaseId');
        localStorage.setItem('editMovieReleaseId', movieReleaseId.toString());
        const releaseEditDialog = this.dialog.open(MovieReleaseEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
        releaseEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(result.data.movie_id)) {
                        for (let j = 0; j < (<any>this.rows[i]).movie_release_full.length; j++) {
                            if ((<any>this.rows[i]).movie_release_full[j].id == Number(movieReleaseId)) {
                                (<any>this.rows[i]).movie_release_full[j] = result.data;
                            }
                        }
                    }
                }
            }
        });
    }

    movieKdms(cinemaId: number) {
        localStorage.removeItem('kdmMovieId');
        localStorage.setItem('kdmMovieId', cinemaId.toString());
        this.dialog.open(MovieKdmsComponent, {
            width: '800px',
            data: {name: this.reorderable}
        });
    }
    movieReleaseKdms(cinemaId: number) {
        localStorage.removeItem('kdmMovieReleaseId');
        localStorage.setItem('kdmMovieReleaseId', cinemaId.toString());
        this.dialog.open(MovieReleaseKdmsComponent, {
            width: '750px',
            data: {name: this.reorderable}
        });
    }

    expand() {
        this.table.rowDetail.collapseAllRows();
        for (let i = 0; i < this.rows.length; i++) {
            if (this.expanded.indexOf(this.rows[i].id) > -1) {
                this.toggleExpandRow(this.rows[i]);
            }
        }
    }

    addMovie(): void {
        this.router.navigate(['movie/add']);
    }

    toggleExpandRow(row: any) {
        const index = this.rows.indexOf(row);
        if (this.temp[index].movie_release_full === undefined) {
          this.movieReleaseService.getViewId(row.id)
            .subscribe(data => {
              (<any>this.rows[index]).movie_release_full = data.data;
              if (this.expanded.includes(row.id)) {
                this.expanded.splice(this.expanded.indexOf(row.id), 1);
              } else {
                this.expanded.push(row.id);
              }
              this.table.rowDetail.toggleExpandRow(row);
            });
        }
        else {
          if (this.expanded.includes(row.id)) {
            this.expanded.splice(this.expanded.indexOf(row.id), 1);
          }
          else {
            this.expanded.push(row.id);
          }
          this.table.rowDetail.toggleExpandRow(row);
        }
    }

    downloadFile(url: string) {
        const filename = url.split('/').pop();
        return this.http
            .get(url, {
                responseType: ResponseContentType.Blob,
            })
            .map(res => {
                return {
                    filename: filename,
                    data: res.blob()
                };
            })
            .subscribe(res => {
                const _url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = _url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(_url);
                a.remove();
            }, error => {
                this.toastr.error(error.error.message, 'Błąd');
            }, () => {
            });
    }

    filterTable() {
      this.filter = {
        search: null,
        only_new_movies: 0
      };

      if (this.only_new_movies) {
        this.filter.only_new_movies = this.only_new_movies;
      }
      if (this.movie != null) {
        this.filter.search = this.movie;
      }

      this.setPage({ offset: 0 });
    }

    sortTable(data: any) {
      const prop = data.sorts[0].prop,
        dir = data.sorts[0].dir;

      this.sort = {};
      this.sort[prop] = dir;

      this.setPage({ offset: 0 });
    }

    getRowClass(row: any) {
        if (row.invalid === 2) {
            return 'error-row';
        } 
        else if (row.invalid === 1) {
            return 'warning-row';
        }
        return '';
    }
}
