import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class UserService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        if (this.currentUser != null) {
            return this.http.post<any>(API_URL + '/api/users', {loggedInId: this.currentUser.id, token: this.currentUser.token});
        } else {
            return null;
        }
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/users/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(user: User) {
        return this.http.post<any>(API_URL + '/api/users/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, user});
    }

    update(user: User) {
        return this.http.put<any>(API_URL + '/api/users/edit/' + user.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, user});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/users/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
}