import {FuseSharedModule} from '@fuse/shared.module';
import {FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {FuseContentModule} from 'app/main/content/content.module';
import {FuseFooterModule} from 'app/main/footer/footer.module';
import {FuseNavbarModule} from 'app/main/navbar/navbar.module';
import {FuseQuickPanelModule} from 'app/main/quick-panel/quick-panel.module';
import {FuseToolbarModule} from 'app/main/toolbar/toolbar.module';
import {FuseMainComponent} from './main.component';

import {MatSidenavModule} from '@angular/material';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';


import {CinemaService} from 'app/_services/cinema.service';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {DistributorService} from 'app/_services/distributor.service';
import {DashboardService} from 'app/_services/dashboard.service';
import {ListsService} from 'app/_services/lists.service';
import {LogService} from 'app/_services/log.service';
import {MovieService} from 'app/_services/movie.service';
import {MovieKdmService} from 'app/_services/movie_kdm.service';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {MultiplexService} from 'app/_services/multiplex.service';
import {ReportsService} from 'app/_services/reports.service';
import {RoleService} from 'app/_services/role.service';
import {KdmService} from 'app/_services/kdm.service';
import {MessageService} from 'app/_services/message.service';
import {SettingsService} from 'app/_services/settings.service';
import {UserProfileService} from 'app/_services/user_profile.service';



@NgModule({
    declarations: [
        FuseMainComponent,
    ],
    imports: [
        FuseSharedModule,
        FuseThemeOptionsModule,
        FuseNavigationModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        FuseSidebarModule,
        FuseContentModule,
        FuseFooterModule,
        FuseNavbarModule,
        FuseQuickPanelModule,
        FuseToolbarModule,
        MatSidenavModule,
        RouterModule,


    ], providers: [
        CinemaService,
        CinemaRoomService,
        DashboardService,
        DistributorService,
        KdmService,
        LogService,
        ListsService,
        MessageService,
        MovieService,
        MovieKdmService,
        MovieReleaseService,
        MultiplexService,
        ReportsService,
        RoleService,
        SettingsService,
        UserProfileService,
    ],
    exports: [
        FuseMainComponent,
    ]
})
export class FuseMainModule {
}
