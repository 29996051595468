import {Component, OnInit} from '@angular/core';
import {SettingsService} from 'app/_services/settings.service';
import {ToastrService} from 'ngx-toastr';
import {Http, ResponseContentType} from '@angular/http';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
    certificate: any = '';
    api_url = API_URL;
    constructor(
        private settingsService: SettingsService,
        private http: Http,
        private toastr: ToastrService
    ) {
        this.getCert();
    }
    getCert() {
        this.settingsService.certificate()
            .subscribe(data => {
                this.certificate = data.data[0];
            });
    }
    getCertXml() {
        this.settingsService.certificateXml()
            .subscribe(data => {
                const url = this.api_url + '/' + data.data;
                const filename = url.split('/').pop();
                return this.http
                    .get(url, {
                        responseType: ResponseContentType.Blob,
                    })
                    .map(res => {
                        return {
                            filename: filename,
                            data: res.blob()
                        };
                    })
                    .subscribe(res => {
                        var url = window.URL.createObjectURL(res.data);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                    }, error => {
                        this.toastr.error(error.error.message, 'Błąd');
                    }, () => {
                    });
            });
    }
    ngOnInit() {

    }

}
