import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class ReportsService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    distributors(distributor_id: number, date_from: any, date_to: any) {
        return this.http.post<any>(API_URL + '/api/reports/distributors', {loggedInId: this.currentUser.id, token: this.currentUser.token, distributor_id, date_from, date_to});
    }
    distributorsPdf(distributor_id: number, date_from: any, date_to: any) {
        return this.http.post<any>(API_URL + '/api/reports/distributors_pdf', {loggedInId: this.currentUser.id, token: this.currentUser.token, distributor_id, date_from, date_to});
    }
}