import {Component, OnInit, Inject} from '@angular/core';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {Router} from '@angular/router';
import {CinemaRoom} from 'app/_models/cinema_room';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'fuse-app-cinema-room-edit',
    templateUrl: './cinema-room-edit.component.html',
    styleUrls: ['./cinema-room-edit.component.scss']
})
export class CinemaRoomEditComponent implements OnInit {

    cinemaRoom: CinemaRoom;
    editRoomForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private cinemaRoomService: CinemaRoomService,
        public dialogRef: MatDialogRef<CinemaRoomEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            certificate_path: {},
        };
    }


    ngOnInit() {
        const cinemaRoomId = localStorage.getItem('editCinemaRoomId');
        if (!cinemaRoomId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }
        this.editRoomForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500),
            ]],
            certificate_path: [''],
            cinema_id: [],
            active: [],
            created: [],
            modified: [],
            deleted: [],
            cinema_name: [],
            kdms: [],
            changed: []
        });

        this.cinemaRoomService.getById(+cinemaRoomId)
            .subscribe(data => {
                this.cinemaRoom = data.data;
                this.editRoomForm.setValue(data.data);
            });
        this.editRoomForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editRoomForm.invalid) {
            return;
        }
        const editCinema = this.editRoomForm.value;
        if (this.fileToUpload !== undefined) {
            // this.editRoomForm.value
            editCinema.certificate_path = this.fileToUpload.name;
            editCinema.certificate_result = this.fileToUpload.result;
            editCinema.certificate_type = this.fileToUpload.type;
        }
        this.cinemaRoomService.update(editCinema)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editRoomForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                this.fileToUpload = filex;
            };
        }
    }

}
