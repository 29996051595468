import {Component, OnInit, Inject} from '@angular/core';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
@Component({
    selector: 'app-cinema-room-kdms',
    templateUrl: './cinema-room-kdms.component.html',
    styleUrls: ['./cinema-room-kdms.component.scss']
})
export class CinemaRoomKdmsComponent implements OnInit {

    kdms: any;
    name: string;
    api_url: string = environment.apiUrl;
    loadingIndicator = true;
    reorderable = true;
    constructor(
        private router: Router,
        private cinemaRoomService: CinemaRoomService,
        public dialogRef: MatDialogRef<CinemaRoomKdmsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService,
        private http: Http,
    ) {}

    ngOnInit() {
        let cinemaRoomId = localStorage.getItem("kdmCinemaRoomId");
        if (!cinemaRoomId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }

        this.cinemaRoomService.getKdmsById(+cinemaRoomId)
            .subscribe(data => {
                this.kdms = data.data;
                this.loadingIndicator = false;
            });
        this.cinemaRoomService.getById(+cinemaRoomId)
            .subscribe(data => {
                this.name = data.data.name;
            });
    }
    downloadFile(url: string) {
        const filename = url.split('/').pop();
        return this.http
            .get(url, {
                responseType: ResponseContentType.Blob,
            })
            .map(res => {
                return {
                    filename: filename,
                    data: res.blob()
                };
            })
            .subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
                this.toastr.error(error.error.message, 'Błąd');
            }, () => {
            });
    }
}
