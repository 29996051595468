import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Movie} from 'app/_models/movie';
import {User} from 'app/_models/user';
import {GenericMovie} from 'app/_models/generic_movie';

import {environment} from 'environments/environment';
import { Page } from '../_models/page';
const API_URL = environment.apiUrl;
@Injectable()
export class MovieService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll(page: Page = null, sort: any = null, filter: any = null) {
        return this.http.post<any>(API_URL + '/api/movies', {loggedInId: this.currentUser.id, token: this.currentUser.token, page, sort, filter});
    }

    getAllActive() {
        return this.http.post<any>(API_URL + '/api/movies/active', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    getAllActiveFiltrated(search: any) {
        return this.http.post<any>(API_URL + '/api/movies/active_filtrated', {loggedInId: this.currentUser.id, token: this.currentUser.token, search: search});
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/movies/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(movie: Movie) {
        return this.http.post(API_URL + '/api/movies/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, movie});
    }

    update(movie: Movie) {
        return this.http.put(API_URL + '/api/movies/edit/' + movie.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, movie});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/movies/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getKdmsById(id: number) {
        return this.http.post<any>(API_URL + '/api/movies/get_kdms/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    
    downloadPackage(cinema_id: any, movie_id: any) {
        return this.http.post<any>(API_URL + '/api/movies/download_package', {loggedInId: this.currentUser.id, token: this.currentUser.token, cinema_id, movie_id});
    }

    movieProposal(movie: GenericMovie) {
        return this.http.put(API_URL + '/api/movies/movie_proposal', {loggedInId: this.currentUser.id, token: this.currentUser.token, movie});
    }
}