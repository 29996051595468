import {Component, OnInit, Inject} from '@angular/core';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {Router} from '@angular/router';
import {MovieRelease} from 'app/_models/movie_release';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'fuse-app-movie-release-edit-full',
    templateUrl: './movie-release-edit-full.component.html',
})
export class MovieReleaseEditFullComponent implements OnInit {

    movieRelease: MovieRelease;
    editForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';
    supplement: any = '';

    constructor(
        public dialogRef: MatDialogRef<MovieReleaseEditFullComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private router: Router,
        private movieReleaseService: MovieReleaseService
    ) {
        this.formErrors = {
            name: {},
            active: {},
            certificate_path: {},
            cert_control: {},
            supplement: {},
            supplement_control: {}
        };
    }

    ngOnInit() {
        if (!this.data.data || !this.data.data.id) {
            alert('Invalid action.');
            this.router.navigate(['movies']);
            return;
        }

        this.editForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            active: [''],
            certificate_path: [],
            supplement: [],
            cert_control: [''],
            supplement_control: [''],
        });

        const values = {
          id: this.data.data.id,
          name: this.data.data.name,
          active: this.data.data.active,
          certificate_path: this.data.data.certificate_path,
          supplement: this.data.data.supplement,
          cert_control: this.data.data.certificate_path,
          supplement_control: this.data.data.supplement
        };

        this.editForm.setValue(values);
        
        this.editForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editForm.invalid) {
            return;
        }

        const movieRelease = this.editForm.value;
        if (this.fileToUpload !== undefined) {
            movieRelease.certificate_path = this.fileToUpload.name;
            movieRelease.certificate_result = this.fileToUpload.result;
            movieRelease.certificate_type = this.fileToUpload.type;
        }
        if (this.supplement !== undefined && this.supplement !== '') {
            movieRelease.supplement = this.supplement.name;
            movieRelease.supplement_result = this.supplement.result;
            movieRelease.supplement_type = this.supplement.type;
        }

        this.movieReleaseService.update(movieRelease)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    alert(error);
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any, what: any) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: (<string> reader.result).split(',')[1]
                };
                if (what === 'cert') {
                    this.fileToUpload = filex;
                    this.editForm.patchValue({cert_control: file.name});
                } else if (what === 'supplement') {
                    this.supplement = filex;
                    this.editForm.patchValue({supplement_control: file.name});
                }
            };
        }
    }

}
