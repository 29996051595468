import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CinemaService} from '../../../../_services/cinema.service';
import {MatDialog} from '@angular/material';
import {Cinema} from '../../../../_models/cinema';
import {environment} from 'environments/environment';
import {Http} from '@angular/http';
import {Router} from '@angular/router';
import {CinemaEditComponent} from '../../cinemas/cinema-edit/cinema-edit.component';
import {MultiplexService} from '../../../../_services/multiplex.service';
import {Multiplex} from '../../../../_models/multiplex';
import {MultiplexBasicEditComponent} from '../../multiplexes/multiplex-basic-edit/multiplex-basic-edit.component';
import {UserMapComponent} from '../../user-map/user-map.component';

const API_URL = environment.apiUrl;
@Component({
    selector: 'fuse-app-user-cinema-addresses',
    templateUrl: './user-cinema-addresses.component.html',
    styleUrls: ['./user-cinema-addresses.component.scss']
})
export class UserCinemaAddressesComponent implements OnInit {
    @ViewChild('myTable') table: any;

    rows: Cinema[] = [];
    temp: any = [];
    reorderable = true;
    api_url = API_URL;
    expanded: any[] = [];
    loadingIndicator = true;
    multiplex: Multiplex = new Multiplex();
    cinemas_count: any = '';
    cinema_rooms_count: any = '';

    constructor(
        public dialog: MatDialog,
        private http: Http,
        private router: Router,
        private cinemaService: CinemaService,
        private multiplexService: MultiplexService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit() {
        this.cinemaService.getView()
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
        this.multiplexService.getMyMultiplex()
            .subscribe(data => {
                this.multiplex = data.data;
                this.cinemas_count = (<any> data.data).cinemas_count;
                this.cinema_rooms_count = (<any> data.data).cinema_rooms_count;
            });
    }

    editMultiplexEmail(): void {
        const multiplexEditDialog = this.dialog.open(MultiplexBasicEditComponent, {
            width: '500px',
            data: {model: this.multiplex}
        });
        multiplexEditDialog.afterClosed().subscribe(result => {
            if (result !== undefined && result.data) {
                this.multiplex = result.data;
            }
        });
    }

    editCinema(cinema: Cinema): void {
        localStorage.removeItem('editCinemaId');
        localStorage.setItem('editCinemaId', cinema.toString());
        const cinemaEditDialog = this.dialog.open(CinemaEditComponent, {
            width: '500px',
            data: {name: this.reorderable, softEditable: true}
        });
        cinemaEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result !== undefined && result.data !== undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id === Number(cinema)) {
                        const rows = [...this.rows];
                        if (rows[i].cinema_rooms !== undefined) {
                            result.data.cinema_rooms = rows[i].cinema_rooms;
                        }
                        rows[i] = result.data;
                        this.rows = rows;
                    }
                }
            }
        });
    }
    showMap(): void {
        console.log('mapa');
        this.dialog.open(UserMapComponent, {
            width: '1300px',
            height: '750px',
            data: {name: this.reorderable, softEditable: true}
        });
    }
    updateFilter(event: any) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            let to_return = false;
            if (d.name.toLowerCase().indexOf(val) !== -1 ||
                /* (d.address != null && d.address.toLowerCase().indexOf(val) !== -1) || */
                (d.city != null && d.city.toLowerCase().indexOf(val) !== -1) || !val) {
                to_return = true;
            }
            return to_return;
        });
        this.rows = temp;
        this.table.offset = 0;
    }

    getRowHeight(row: Cinema, index: number): number {
        return 40;
    }
}

