import {Component, OnInit, ViewChild} from '@angular/core';
import {Message} from 'app/_models/message';
import {ReportsService} from 'app/_services/reports.service';
import {ListsService} from 'app/_services/lists.service';
import {Http, ResponseContentType} from '@angular/http';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
const API_URL = environment.apiUrl;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD H:mm',
    fullPickerInput: 'YYYY-MM-DD H:mm',
    datePickerInput: 'YYYY-MM-DD H:mm',
    timePickerInput: 'YYYY-MM-DD H:mm',
    monthYearLabel: 'YYYY-MM-DD H:mm',
    dateA11yLabel: 'YYYY-MM-DD H:mm',
    monthYearA11yLabel: 'YYYY-MM-DD H:mm',
};

@Component({
    selector: 'app-distributors-reports',
    templateUrl: './distributors-reports.component.html',
    styleUrls: ['./distributors-reports.component.scss'],
    providers: [
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    ]
})
export class DistributorsReportsComponent implements OnInit {
    @ViewChild('myTable') table: any;

    rows: any[] = [];
    loadingIndicator = true;
    reorderable = true;
    distributor: number = null;
    distributors: any[] = [];
    range: any = [moment().startOf('month').format('YYYY-MM-DD 00:00'), moment().endOf('month').format('YYYY-MM-DD 23:59')];
    //Search Input--
    selectedDistributors: any[] = this.distributors;
    searchInput: string = null;
    //--Search Input
    constructor(
        private reportsService: ReportsService,
        private listsService: ListsService,
        private toastr: ToastrService,
        private http: Http,
    ) {}

    ngOnInit() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        this.reportsService.distributors(null, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'))
            .subscribe(data => {
                this.rows = data.data;
                this.loadingIndicator = false;
            });
        this.listsService.distributor(true)
            .subscribe(data => {
                this.distributors = data.data;
                this.selectedDistributors = this.distributors;
                if (user.role_id == 3 && user.distributor_id) {
                    this.distributor = user.distributor_id;
                }
            });
    }
    //Search Input--
    search(query: string) {
        let result = this.select(query)
        this.selectedDistributors = result;
    }

    select(query: string): string[] {
        let result: string[] = [];
        for (let a of this.distributors) {
            if (a.name.toLowerCase().indexOf(query) > -1) {
                result.push(a)
            }
        }
        return result
    }
    cleanSearchImput() {
        this.searchInput = '';
        this.selectedDistributors = this.distributors;
    }
    //--Search Input

    sortTable() {
        this.reportsService.distributors(this.distributor, moment(this.range[0]).format('YYYY-MM-DD'), moment(this.range[1]).format('YYYY-MM-DD'))
            .subscribe(data => {
                this.rows = data.data;
            });
        this.table.offset = 0;
    }
    clearValue() {
        this.range = null;
        this.sortTable();
    }
    summaryPackages(cells: string[]) {
        return 'Liczba zestawów: ' + cells.length;
    }
    summaryKeys(cells: number[]) {
        let sum = 0;
        for (let i = 0; i < cells.length; i++) {
            sum = sum + cells[i];
        }

        return 'Liczba KDM: ' + sum;
    }
    noSummary(cells: string[]) {
        return '';
    }
    downloadFile() {
        if (this.distributor === null) {
            this.toastr.error('Proszę wybrać dystrybutora dla którego ma być wygenerowany raport', 'Błąd');
            return;
        }
        this.reportsService.distributorsPdf(this.distributor, moment(this.range[0]).format('YYYY-MM-DD'), moment(this.range[1]).format('YYYY-MM-DD'))
            .subscribe(data => {
                let url = API_URL + data.data;
                const filename = url.split('/').pop();
                return this.http
                    .get(url, {
                        responseType: ResponseContentType.Blob,
                    })
                    .map(res => {
                        return {
                            filename: filename,
                            data: res.blob()
                        };
                    })
                    .subscribe(res => {
                        var url = window.URL.createObjectURL(res.data);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                    }, error => {
                        this.toastr.error(error.error.message, 'Błąd');
                    }, () => {
                    });
            });

    }
}
