import {Component, OnInit, Inject} from '@angular/core';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {Router} from "@angular/router";
import {CinemaRoom} from 'app/_models/cinema_room';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-cinema-room-add',
    templateUrl: './cinema-room-add.component.html',
    styleUrls: ['./cinema-room-add.component.scss']
})
export class CinemaRoomAddComponent implements OnInit {

    cinemaRoom: CinemaRoom;
    addRoomForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private cinemaRoomService: CinemaRoomService,
        public dialogRef: MatDialogRef<CinemaRoomAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            certificate_path: {},
            cert_control: {}
        };
    }


    ngOnInit() {
        let cinemaId = localStorage.getItem("addCinemaId");
        if (!cinemaId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }
        this.addRoomForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500),
            ]],
            certificate_path: [''],
            cinema_id: [cinemaId],
            cert_control: ['', Validators.required],
        });

        this.addRoomForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.addRoomForm.invalid) {
            return;
        }
        const addCinemaRoom = this.addRoomForm.value;
        if (this.fileToUpload !== undefined) {
            this.addRoomForm.value
            addCinemaRoom.certificate_path = this.fileToUpload.name;
            addCinemaRoom.certificate_result = this.fileToUpload.result;
            addCinemaRoom.certificate_type = this.fileToUpload.type;
        }
        this.cinemaRoomService.create(addCinemaRoom)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.addRoomForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                let filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                this.fileToUpload = filex;
                this.addRoomForm.patchValue({cert_control: file.name});
            };
        }
    }

}
