import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { CinemaService } from 'app/_services/cinema.service';
import { Router } from '@angular/router';
import { Cinema } from 'app/_models/cinema';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MultiplexService } from 'app/_services/multiplex.service';
import { Multiplex } from 'app/_models/multiplex';
import { SEMICOLON, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'fuse-app-cinema-edit',
  templateUrl: './cinema-edit.component.html',
  styleUrls: ['./cinema-edit.component.scss']
})
export class CinemaEditComponent implements OnInit {

  @ViewChild('emails') emailsInput: ElementRef;
  readonly separatorKeysCodes: number[] = [SEMICOLON, ENTER];
  selectable = true;
  removable = true;
  addOnBlur = true;
  submitted = false;
  emailList: string[] = [];
  multiplexes: Multiplex[] = [];
  cinema: Cinema;
  editForm: FormGroup;
  formErrors: any;
  mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cinemaService: CinemaService,
    public dialogRef: MatDialogRef<CinemaEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private multiplexService: MultiplexService,
  ) {
    this.formErrors = {
      name: {},
      address: {},
      city: {},
      postcode: {},
      email: {},
      active: {},
      multiplex_id: {},
    };
  }

  ngOnInit() {
    const cinemaId = localStorage.getItem('editCinemaId');
    if (!cinemaId) {
      this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
      this.router.navigate(['cinemas']);
      return;
    }
    this.editForm = this.formBuilder.group({
      id: [],
      name: ['', [
        Validators.maxLength(500),
      ]],
      address: ['', [
        Validators.required,
        Validators.maxLength(500),
      ]],
      postcode: ['',
        [Validators.pattern('\\d{2}-\\d{3}')]],
      city: ['', [
        Validators.required,
        Validators.maxLength(500),
      ]],
      email: [''],
      multiplex_id: ['', Validators.required],
      active: ['']
    });

    this.cinemaService.getById(+cinemaId)
      .subscribe(data => {
        const values = {
          id: data.data.id,
          name: data.data.name,
          address: data.data.address,
          postcode: data.data.postcode,
          city: data.data.city,
          email: data.data.email,
          multiplex_id: data.data.multiplex_id,
          active: data.data.active
        };

        this.emailList = data.data.email.split(';');
        values.email = '';
        this.editForm.setValue(values);
        // this.validateEmails();
      });
    this.editForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
      this.validateEmails();
    });
    this.multiplexService.getAll()
      .subscribe(data => {
        this.multiplexes = data.data;
      });
  }

  onSubmit() {
    this.onFormValuesChanged();
    this.submitted = true;
    if (this.editForm.invalid || !this.validateEmails()) {
      return;
    }

    const values = this.editForm.value;
    values.email = this.emailList.join(';');
    this.cinemaService.update(values)
      .subscribe(
        data => {
          this.cinemaService.locate(data.data.id).subscribe(_data => { });
          this.dialogRef.close(data);
        },
        error => {
          this.toastr.error(error.error.message, 'Błąd');
        });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.editForm.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  get f() {
    return this.editForm.controls;
  }

  validateEmails(): boolean {
    if (this.emailList.length === 0) {
      this.formErrors['email']['required'] = true;
      this.editForm.get('email').setErrors({ required: true/* , email: false, maxlength: false */ });
      return false;
    }

    if (this.emailList.join(';').length > 500) {
      this.formErrors['email']['maxlength'] = true;
      this.editForm.get('email').setErrors({ maxlength: true/* , required: false, email: false */ });
      return false;
    }

    for (let i = 0; i < this.emailList.length; i++) {
      if (!this.validateEmail(this.emailList[i])) {
        return false;
      }
    }

    this.formErrors['email']['required'] = false;
    this.formErrors['email']['email'] = false;
    this.formErrors['email']['maxlength'] = false;
    this.editForm.get('email').setErrors(null);

    return true;
  }

  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    email = String(email).trim().toLowerCase();
    if (email.length === 0 || re.test(email)) {
      return true;
    }

    this.formErrors.email['email'] = true;
    this.editForm.get('email').setErrors({ email: true/* , required: false, maxlength: false */ });

    return false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.validateEmail(value)) {
      this.formErrors['email']['email'] = true;
      this.editForm.get('email').setErrors({ required: true/* , email: false, maxlength: false */ });
      return;
    }

    // Add our fruit
    if ((value || '').trim()) {
      this.emailList.push(value.trim());

      this.editForm.markAsDirty();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.validateEmails();
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);

      this.editForm.markAsDirty();
    }

    this.validateEmails();
  }

  getSubmitColor(): string {
    if (this.editForm.dirty) {
      return 'red-bg';
    }

    return 'accent';
  }

}
