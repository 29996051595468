import {Component, OnInit, Inject} from '@angular/core';
import {CinemaService} from 'app/_services/cinema.service';
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-cinema-history',
    templateUrl: './cinema-history.component.html',
    styleUrls: ['./cinema-history.component.scss'],
})
export class CinemaHistoryComponent implements OnInit {

    historys: any;
    name: string;
    loadingIndicator = true;
    reorderable = true;

    constructor(
        private router: Router,
        private cinemaService: CinemaService,
        public dialogRef: MatDialogRef<CinemaHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService,
    ) {}

    ngOnInit() {
        let cinemaId = localStorage.getItem("historyCinemaId");
        if (!cinemaId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }

        this.cinemaService.getHistory(+cinemaId)
            .subscribe(data => {
                this.historys = data.data;
                this.loadingIndicator = false;
            });
        this.cinemaService.getById(+cinemaId)
            .subscribe(data => {
                this.name = data.data.full_name;
            });
    }
}
