export const navigation_cinema = [
    {
        'id': 'menu',
        'title': 'Menu',
        'type': 'group',
        'children': [
            {
                'id': 'dashboard',
                'title': 'Dashboard',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/dashboard',
            },
            {
                'id': 'user_cinema',
                'title': 'Moje kino',
                'type': 'collapse',
                'icon': 'local_movies',
                'children': [
                    {
                        'id': 'user_cinema_address',
                        'title': 'Dane adresowe',
                        'type': 'item',
                        'url': '/user_cinema/address',
                    },
                    {
                        'id': 'user_cinema_rooms',
                        'title': 'Sale',
                        'type': 'item',
                        'url': '/user_cinema/rooms',
                    },
                ]
            },
            {
                'id': 'user_movies',
                'title': 'Moje filmy',
                'type': 'item',
                'icon': 'theaters',
                'url': '/user_movies',
            }
        ]
    }
];
