import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
// CINEMAS
import { CinemasComponent } from './main/content/cinemas/cinemas.component';
import { CinemaAddComponent } from './main/content/cinemas/cinema-add/cinema-add.component';
// DASHBOARD
import { DashboardComponent } from './main/content/dashboard/dashboard.component';
// DISTRIBUTORS
import { DistributorsComponent } from './main/content/distributors/distributors.component';
import { DistributorMoviesComponent } from './main/content/distributor-movies/distributor-movies.component';
// KDM
import { KdmComponent } from './main/content/kdm/kdm.component';
import { KdmCinemaRoomComponent } from './main/content/kdm-cinema-room/kdm-cinema-room.component';
// LOGIN
import { LoginComponent } from './login/login.component';
// LOGS
import { LogComponent } from './main/content/settings/log/log.component';
// MAIN
import { FuseMainComponent } from './main/main.component';
// MOVIES
import { MoviesComponent } from './main/content/movies/movies.component';
import { MovieAddComponent } from './main/content/movies/movie-add/movie-add.component';
// MULTIPLEXES
import { MultiplexesComponent } from './main/content/multiplexes/multiplexes.component';
// REPORTS
import { ReportsComponent } from './main/content/reports/reports.component';
import { KdmReportsComponent } from './main/content/reports/kdm-reports/kdm-reports.component';
import { MessageReportsComponent } from './main/content/reports/message-reports/message-reports.component';
import { DistributorsReportsComponent } from './main/content/reports/distributors-reports/distributors-reports.component';

// PASSWORD-RESET
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { PasswordResetFinishComponent } from './login/password-reset-finish/password-reset-finish.component';
// SETTINGS
import { SettingsComponent } from './main/content/settings/settings.component';
import { CertificateComponent } from './main/content/settings/certificate/certificate.component';
import { SettingsEditComponent } from './main/content/settings/settings-edit/settings-edit.component';
// USERS
import { UsersComponent } from './main/content/users/users.component';
import { UserProfileComponent } from './main/content/user-profile/user-profile.component';
import { UserCinemaComponent } from './main/content/user-cinema/user-cinema.component';
import { UserCinemaAddressComponent } from './main/content/user-cinema/user-cinema-address/user-cinema-address.component';
import { UserCinemaAddressesComponent } from './main/content/user-cinema/user-cinema-addresses/user-cinema-addresses.component';
import { UserCinemaRoomsComponent } from './main/content/user-cinema/user-cinema-rooms/user-cinema-rooms.component';
import { UserMoviesComponent } from './main/content/user-movies/user-movies.component';
import { DistributorMovieAddComponent } from './main/content/distributor-movies/distributor-movie-add/distributor-movie-add.component';

const appRoutes: Routes = [
  { path: 'cinemas', component: CinemasComponent, canActivate: [AuthGuard] },
  { path: 'cinema/add', component: CinemaAddComponent, canActivate: [AuthGuard] },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  { path: 'distributors', component: DistributorsComponent, canActivate: [AuthGuard] },
  { path: 'distributor_movies', component: DistributorMoviesComponent, canActivate: [AuthGuard] },
  { path: 'distributor_movie/add', component: DistributorMovieAddComponent, canActivate: [AuthGuard] },

  { path: 'login', component: LoginComponent },
  { path: 'password_reset', component: PasswordResetComponent },
  { path: 'reset/:passkey', component: PasswordResetFinishComponent },

  { path: 'movies', component: MoviesComponent, canActivate: [AuthGuard] },
  { path: 'movie/add', component: MovieAddComponent, canActivate: [AuthGuard] },

  { path: 'multiplexes', component: MultiplexesComponent, canActivate: [AuthGuard] },

  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'logs', component: LogComponent, canActivate: [AuthGuard] },
  { path: 'reports/kdm', component: KdmReportsComponent, canActivate: [AuthGuard] },
  { path: 'reports/message', component: MessageReportsComponent, canActivate: [AuthGuard] },
  { path: 'reports/distributores', component: DistributorsReportsComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'settings/certificate', component: CertificateComponent, canActivate: [AuthGuard] },
  { path: 'settings/edit', component: SettingsEditComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'user_profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user_cinema', component: UserCinemaComponent, canActivate: [AuthGuard] },
  { path: 'user_cinema/addresses', component: UserCinemaAddressesComponent, canActivate: [AuthGuard] },
  { path: 'user_cinema/address', component: UserCinemaAddressComponent, canActivate: [AuthGuard] },
  { path: 'user_cinema/rooms', component: UserCinemaRoomsComponent, canActivate: [AuthGuard] },
  { path: 'user_movies', component: UserMoviesComponent, canActivate: [AuthGuard] },
  { path: 'kdm', component: KdmComponent, canActivate: [AuthGuard] },
  { path: 'kdm_cinema_room', component: KdmCinemaRoomComponent, canActivate: [AuthGuard] },
  // otherwise redirect to dashboard
  { path: '**', redirectTo: '/dashboard' }
];

export const routing = RouterModule.forRoot(appRoutes);
