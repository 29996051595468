import {Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MultiplexService} from 'app/_services/multiplex.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-multiplex-add',
    templateUrl: './multiplex-add.component.html',
    styleUrls: ['./multiplex-add.component.scss']
})
export class MultiplexAddComponent implements OnInit {

    addForm: FormGroup;
    formErrors: any;

    constructor(
        private formBuilder: FormBuilder,
        private multiplexService: MultiplexService,
        public dialogRef: MatDialogRef<MultiplexAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            email: {},
            hide_name: {},
        };
    }

    ngOnInit() {
        this.addForm = this.formBuilder.group({
            id: [],
            name: [, Validators.required],
            hide_name: [],
            email: ['', [
                Validators.maxLength(500)
            ]
            ],
        });
        this.addForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.addForm.invalid) {
            return;
        }
        this.multiplexService.create(this.addForm.value)
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.addForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

}
