import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Distributor} from 'app/_models/distributor';
import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class DistributorService {
   currentUser: User;

   constructor(private http: HttpClient) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
   }

   getAll() {
      return this.http.post<any>(API_URL + '/api/distributors', {loggedInId: this.currentUser.id, token: this.currentUser.token});
   }

   getById(id: number) {
      return this.http.post<any>(API_URL + '/api/distributors/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
   }

   create(distributor: Distributor) {
      return this.http.post(API_URL + '/api/distributors/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, distributor});
   }

   update(distributor: Distributor) {
      return this.http.put(API_URL + '/api/distributors/edit/' + distributor.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, distributor});
   }

   delete(id: number) {
      return this.http.post<any>(API_URL + '/api/distributors/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
   }
}