export const navigation_distributor = [
    {
        'id': 'menu',
        'title': 'Menu',
        'type': 'group',
        'children': [
            {
                'id': 'dashboard',
                'title': 'Dashboard',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/dashboard',
            },
            {
                'id': 'distributor_movies',
                'title': 'Filmy',
                'type': 'item',
                'icon': 'theaters',
                'url': '/distributor_movies',
            },
            {
                'id': 'reports',
                'title': 'Klucze',
                'type': 'collapse',
                'icon': 'timeline',
                'children': [
                    {
                        'id': 'kdm_reports',
                        'title': 'Wysłane',
                        'type': 'item',
                        'url': '/reports/kdm',
                    },
                ]
            },
        ]
    }
];
