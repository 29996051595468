import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MovieService} from 'app/_services/movie.service';
import {Router} from '@angular/router';
import {Distributor} from 'app/_models/distributor';
import {DistributorService} from 'app/_services/distributor.service';
import {ToastrService} from 'ngx-toastr';
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { fuseAnimations } from '@fuse/animations';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'YYYY-MM-DD',
  fullPickerInput: 'YYYY-MM-DD',
  datePickerInput: 'YYYY-MM-DD',
  timePickerInput: 'YYYY-MM-DD',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'YYYY-MM-DD',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
    selector: 'app-movie-add',
    templateUrl: './movie-add.component.html',
    styleUrls: ['./movie-add.component.scss'],
    providers: [
      { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
      { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
      { provide: OWL_DATE_TIME_LOCALE, useValue: 'pl' },
    ],
    animations: fuseAnimations
})
export class MovieAddComponent implements OnInit {

    distributors: Distributor[] = [];
    addFormMovie: FormGroup;
    addFormMovieRelease: FormGroup;
    formErrorsMovie: any;
    formErrorsMovieRelease: any;
    fileImg: any = '';
    fileCert: any = '';
    fileSupplement: any = '';
    movieName = '';
    ite = 0;
    cover: any = null;
    mask = [/\d/, /\d/, /\d/, /\d/];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private movieService: MovieService,
        private distributorService: DistributorService,
        private toastr: ToastrService
    ) {
        this.formErrorsMovie = {
            name: {},
            premiere: {},
            distributor_id: {},
            img: {},
        };
        this.formErrorsMovieRelease = {
            name: {},
            certificate_path: {},
            cert_control: {}
        };
    }

    ngOnInit() {
        this.distributorService.getAll()
            .subscribe(data => {
                this.distributors = data.data;
            });
        this.addFormMovie = this.formBuilder.group({
            id: [],
            name: ['',
                [
                    Validators.required,
                    Validators.maxLength(500),
                ]
            ],
            premiere: ['', [
              Validators.required,
            ]],
            distributor_id: ['', Validators.required],
            img: [''],
        });
        this.addFormMovieRelease = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            certificate_path: [''],
            supplement: [''],
            cert_control: ['', Validators.required],
            supplement_control: [''],
        });

        this.addFormMovie.valueChanges.subscribe(() => {
            this.onFormValuesChangedMovie();
        });
        this.addFormMovieRelease.valueChanges.subscribe(() => {
            this.onFormValuesChangedMovieReleases();
        });

    }

    finishMovieStepper() {
        this.onFormValuesChangedMovie();
        if (this.addFormMovie.invalid) {
            return;
        }
        this.onFormValuesChangedMovieReleases();
        if (this.addFormMovieRelease.invalid) {
            return;
        }
        const newMovie = this.addFormMovie.value;
        const newMovieRelease = this.addFormMovieRelease.value;
        newMovie.img = this.fileImg.name;
        newMovie.img_result = this.fileImg.result;
        newMovie.img_type = this.fileImg.type;
        newMovie.premiere = newMovie.premiere.format('YYYY-MM-DD');
        newMovieRelease.certificate_path = this.fileCert.name;
        newMovieRelease.certificate_result = this.fileCert.result;
        newMovieRelease.certificate_type = this.fileCert.type;
        if (this.fileSupplement.result != undefined) {
            newMovieRelease.supplement = this.fileSupplement.name;
            newMovieRelease.supplement_result = this.fileSupplement.result;
            newMovieRelease.supplement_type = this.fileSupplement.type;
        }
        newMovie.release = newMovieRelease;
        this.movieService.create(newMovie)
            .subscribe(data => {
                this.router.navigate(['movies']);
            });
    }

    onFormValuesChangedMovie() {
        for (const field in this.formErrorsMovie) {
            if (!this.formErrorsMovie.hasOwnProperty(field)) {
                continue;
            }
            this.formErrorsMovie[field] = {};
            const control = this.addFormMovie.get(field);
            if (control && !control.valid) {
                this.formErrorsMovie[field] = control.errors;
            }
        }
    }

    onFormValuesChangedMovieReleases() {
        for (const field in this.formErrorsMovieRelease) {
            if (!this.formErrorsMovieRelease.hasOwnProperty(field)) {
                continue;
            }
            this.formErrorsMovieRelease[field] = {};
            const control = this.addFormMovieRelease.get(field);
            if (control && !control.valid) {
                this.formErrorsMovieRelease[field] = control.errors;
            }
        }
    }
    handleFileInput(event: any, what: string) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (what == 'img' && (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg')) {
                    this.cover = reader.result;
                }
                let filex: Object = {
                    ite: this.ite,
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                if (what == 'img') {
                    if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                        this.fileImg = filex;
                    } else {
                        this.toastr.error('błędny format wymagany jpg lub png', 'Błąd');
                    }
                } else if (what == 'cert') {
                    if (file.type == 'text/xml' || file.type == 'application/x-x509-ca-cert') {
                        this.fileCert = filex;
                        this.addFormMovieRelease.patchValue({cert_control: file.name});
                    } else {
                        this.toastr.error('błędny format wymagany xml', 'Błąd');

                    }
                } else if (what == 'supplement') {
                    this.fileSupplement = filex;
                    this.addFormMovieRelease.patchValue({supplement_control: file.name});
                }
                this.ite++;
            };
        }
    }
}
