import {Component, OnInit, Inject} from '@angular/core';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {Router} from '@angular/router';
import {CinemaRoom} from 'app/_models/cinema_room';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import { CinemaRoomExt } from '../../../../_models/cinema_room_ext';
@Component({
    selector: 'fuse-app-user-cinema-room-change-dkdm',
    templateUrl: './user-cinema-room-change-dkdm.component.html',
    styleUrls: ['./user-cinema-room-change-dkdm.component.scss']
})
export class UserCinemaRoomChangeDKDMComponent implements OnInit {

    cinemaRoom: CinemaRoom;
    editRoomForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private cinemaRoomService: CinemaRoomService,
        public dialogRef: MatDialogRef<UserCinemaRoomChangeDKDMComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            certificate_number: {},
            certificate_path: {},
            cert: {},
        };
    }


    ngOnInit() {
        const cinemaRoomId = localStorage.getItem('editCinemaRoomId');
        if (!cinemaRoomId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['cinemas']);
            return;
        }
        this.editRoomForm = this.formBuilder.group({
            id: [],
            certificate_path: [''],
            certificate_number: ['', [
                Validators.maxLength(500),
            ]],
        });

        this.cinemaRoomService.getById(+cinemaRoomId)
            .subscribe(data => {
                this.cinemaRoom = data.data;
                this.editRoomForm.setValue({
                  id: data.data.id,
                  certificate_number: '',
                  certificate_path: '' // data.data.certificate_path
                });
            });
        this.editRoomForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        const valid = this.onFormValuesChanged();
        if (!valid) {
            return;
        }
        const editCinema: CinemaRoomExt = this.editRoomForm.value;
        if (this.fileToUpload !== undefined) {
            editCinema.certificate_path = this.fileToUpload.name;
            editCinema.certificate_result = this.fileToUpload.result;
            editCinema.certificate_type = this.fileToUpload.type;
        }
        this.cinemaRoomService.changeDKDM(editCinema)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                    this.toastr.success((<any>data).message, 'Sukces');
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editRoomForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }

        this.formErrors['cert'] = {};
        
        if (!this.editRoomForm.invalid && !this.fileToUpload && !this.editRoomForm.get('certificate_number').value) {
          this.formErrors['cert'] = { required: true };
          
          return false;
          /* this.editRoomForm.get('certificate_path').setErrors({
            required: true
          });
          this.editRoomForm.get('certificate_number').setErrors({
            required: true
          }); */
        }

        return !this.editRoomForm.invalid;
    }

    handleFileInput(event: any) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: (<String> reader.result).split(',')[1]
                };
                this.fileToUpload = filex;

                this.onFormValuesChanged();
            };
        }
        
    }

}
