import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;

@Injectable()
export class DashboardService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    mainChart() {
        return this.http.post<any>(API_URL + '/api/dashobard/main', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    movieChart() {
        return this.http.post<any>(API_URL + '/api/dashobard/movie', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    cinemaChart() {
        return this.http.post<any>(API_URL + '/api/dashobard/cinema', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    certsChart() {
        return this.http.post<any>(API_URL + '/api/dashobard/certs', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    newMoviesChart() {
        return this.http.post<any>(API_URL + '/api/dashobard/new_movies', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    mapMarkers() {
        return this.http.post<any>(API_URL + '/api/dashobard/map', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    popularMovies() {
        return this.http.post<any>(API_URL + '/api/dashobard/popular_movies', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    editedCerts() {
        return this.http.post<any>(API_URL + '/api/dashobard/editedCerts', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

}