
export class CinemaRoom {
    id = '';
    cinema_id = '';
    name = '';
    certificate_path = '';
    certificate_result = '';
    certificate_type = '';
    cert_control = '';
    active: any = '';
    kdms: number;
    changed = false;
}
