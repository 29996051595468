import {Component, OnInit} from '@angular/core';
import {DashboardService} from 'app/_services/dashboard.service';
import {MainChart} from 'app/_models/main_chart';
import {MovieChart} from 'app/_models/movie_chart';
import {CertsChart} from 'app/_models/certs_chart';
import {CinemaChart} from 'app/_models/cinema_chart';
import {NewMoviesChart} from 'app/_models/new_movie_chart';
import {icon, popup, marker, latLng, tileLayer} from 'leaflet';
import {MovieReleaseKdmsComponent} from 'app/main/content/movies/movie-release-kdms/movie-release-kdms.component';
import {MatDialog} from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    mainChartData: MainChart = new MainChart();
    movieChartData: MovieChart = new MovieChart();
    certsChartData: CertsChart = new CertsChart();
    newMoviesChartData: NewMoviesChart = new NewMoviesChart();
    mapMarkersStyles: any[] = [];
    popularMoviesData: any[] = [];
    editedCerts: any[] = [];
    cinemaChartData: CinemaChart = new CinemaChart();
    showChart: boolean = false;
    showChartMovie: boolean = false;
    showChartCinema: boolean = false;
    showChartCerts: boolean = false;
    showChartNewMovies = false;
    lastSelectedInfoWindow: any;
    infoWindowOpened: any = null;
    options: any = null;
    loadingIndicator = true;
    reorderable = true;
    constructor(
        public dialog: MatDialog,
        private dashboardService: DashboardService,
        private router: Router
    ) {}

    ngOnInit() {
        this.dashboardService.mainChart()
            .subscribe(data => {
                this.mainChartData.values = data.data.values;
                this.mainChartData.labels = data.data.labels;
                this.mainChartData.all = data.data.all;
                let max = 0;
                data.data.values[0].data.forEach((val: any) => {
                    if (val > max) {
                        max = val;
                    }
                })
                this.mainChartData.options.scales.yAxes["0"].ticks.max = max+50;
                this.showChart = true;
            });
        this.dashboardService.movieChart()
            .subscribe(data => {
                this.movieChartData.values = data.data.values;
                this.movieChartData.labels = data.data.labels;
                this.movieChartData.all = data.data.all;
                this.showChartMovie = true;
            });
        this.dashboardService.cinemaChart()
            .subscribe(data => {
                this.cinemaChartData.values = data.data.values;
                this.cinemaChartData.all = data.data.all;
                this.showChartCinema = true;
            });
        this.dashboardService.certsChart()
            .subscribe(data => {
                this.certsChartData.all = data.data.all;
                this.certsChartData.today = data.data.today;
                this.certsChartData.full = data.data.full;
                this.showChartCerts = true;
            });
        this.dashboardService.newMoviesChart()
            .subscribe(data => {
                this.newMoviesChartData.all = data.data.all;
                this.newMoviesChartData.full = data.data.full;
                this.showChartNewMovies = true;
            });
        this.dashboardService.editedCerts()
            .subscribe(data => {
                this.editedCerts = data.data;
            });
        this.dashboardService.mapMarkers()
            .subscribe(data => {
                let layers_copy = [];
                for (let i = 0; i < data.data.markers.length; i++) {
                    let label = '';
                        label += '<b>Kino:</b> ' + data.data.markers[i].full_name + '<br>';
                        label += '<b>Liczba aktywnych KDM:</b> ' + data.data.markers[i].sum_kdms + '<br>';
                    
                    let markerx = marker([data.data.markers[i].lat, data.data.markers[i].lon], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'leaflet/marker-icon.png',
                            shadowUrl: 'leaflet/marker-shadow.png',

                        })
                    }).bindPopup(label);
                    layers_copy.push(markerx);
                }
                let streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    detectRetina: true,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                });

                layers_copy.push(streetMaps);
                this.options = {
                    layers: layers_copy,
                    zoom: 6,
                    scrollWheelZoom: false,
                    center: latLng([52, 20])
                };
            });
        this.dashboardService.popularMovies()
            .subscribe(data => {
                this.popularMoviesData = data.data;
                this.loadingIndicator = false;
            });

    }

    goToMovies() {
      const user = JSON.parse(localStorage.getItem('currentUser'));

      if (user.role_id === 2 || user.role_id === 4) {
        this.router.navigate(['/user_movies'], {queryParams: {news: 1}});
      }
      else if (user.role_id === 3) {
        this.router.navigate(['/distributor_movies'], {queryParams: {news: 1}});
      }
      else {
        this.router.navigate(['/movies'], {queryParams: {news: 1}});
      }
    }

    markerClick(infoWindow: any) {
        if (this.infoWindowOpened === infoWindow)
            return;
        if (this.infoWindowOpened !== null)
            this.infoWindowOpened.close();

        this.infoWindowOpened = infoWindow;
    }
    movieReleaseKdms(cinemaId: number) {
        localStorage.removeItem("kdmMovieReleaseId");
        localStorage.setItem("kdmMovieReleaseId", cinemaId.toString());
        this.dialog.open(MovieReleaseKdmsComponent, {
            width: '750px',
            data: {name: this.reorderable}
        });
    }
}
