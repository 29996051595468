import {Component, OnInit, Inject} from '@angular/core';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {Router} from "@angular/router";
import {MovieRelease} from 'app/_models/movie_release';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-movie-release-add',
    templateUrl: './movie-release-add.component.html',
    styleUrls: ['./movie-release-add.component.scss']
})
export class MovieReleaseAddComponent implements OnInit {


    movieRelease: MovieRelease;
    addForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';
    supplement: any = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private movieReleaseService: MovieReleaseService,
        public dialogRef: MatDialogRef<MovieReleaseAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            certificate_path: {},
            cert_control: {}
        };
    }

    ngOnInit() {
        let movieId = localStorage.getItem("movieId");
        if (!movieId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['movies']);
            return;
        }
        this.addForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            certificate_path: [''],
            movie_id: [movieId],
            cert_control: ['', Validators.required],
            supplement_control: [''],
        });

        this.addForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.addForm.invalid) {
            return;
        }
        const addMovieRelease = this.addForm.value;
        if (this.fileToUpload !== undefined) {
            this.addForm.value
            addMovieRelease.certificate_path = this.fileToUpload.name;
            addMovieRelease.certificate_result = this.fileToUpload.result;
            addMovieRelease.certificate_type = this.fileToUpload.type;
        }
        if (this.supplement !== undefined && this.supplement != '') {
            this.addForm.value
            addMovieRelease.supplement = this.supplement.name;
            addMovieRelease.supplement_result = this.supplement.result;
            addMovieRelease.supplement_type = this.supplement.type;
        }

        this.movieReleaseService.create(addMovieRelease)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.addForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any, what: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                let filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                if (what == 'cert') {
                    this.fileToUpload = filex;
                    this.addForm.patchValue({cert_control: file.name});
                } else if (what == 'supplement') {
                    this.supplement = filex;
                    this.addForm.patchValue({supplement_control: file.name});
                }
            };
        }
    }

}
