import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Role} from 'app/_models/role';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class RoleService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/roles', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    getById(id: number) {
        return this.http.post<any>(API_URL + '/api/roles/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    create(role: Role) {
        return this.http.post(API_URL + '/api/roles/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, role});
    }

    update(role: Role) {
        return this.http.put(API_URL + '/api/roles/edit/' + role.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, role});
    }

    delete(id: number) {
        return this.http.post<any>(API_URL + '/api/roles/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
}