import {Injectable} from '@angular/core';
import {CinemaService} from 'app/_services/cinema.service';
import {BehaviorSubject} from 'rxjs';

export class TodoItemNode {
    error: any;
    children: TodoItemNode[];
    kdms_list: any[];
    date_time_from: Date;
    date_time_to: Date;
    days: string;
    item: string;
    cinema_id: string;
    id: string;

}

export class TodoItemFlatNode {
    error: any;
    cinema_id: string;
    item: string;
    kdms_list: any[];
    level: number;
    date_time_from: Date;
    date_time_to: Date;
    days: string;
    expandable: boolean;
    id: string;
}

@Injectable()
export class KdmDatabase {
    dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);
    dataCopy: any[] = [];
    subscription: any = null;
    get data(): TodoItemNode[] { return this.dataChange.value; }
    constructor(private cinemaService: CinemaService) {
        this.initialize();
    }

    initialize() {}

    buildFileTree(value: any, level: number) {
        const data: any[] = [];
        for (const k in value) {
          if (value.hasOwnProperty(k)) {
            const v = value[k];
            const node = new TodoItemNode();
            node.item = `${k}`;
            if (typeof v.id !== 'undefined') {
                node.id = `${v.id}`;
            } else {
                node.id = v;
            }
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v.childrens === 'object') {
                node.children = this.buildFileTree(v.childrens, level + 1);
            } else {
                node.kdms_list = v.kdms_list;
                node.cinema_id = v.cinema_id;
                node.item = v.name;
                node.id = v.id;
            }
            data.push(node);
          }
        }
        return data;
    }

    searchInTree(search: string) {
        const val = search.toLowerCase();
        const dataCopyIner: any[] = JSON.parse(JSON.stringify(this.dataCopy));
        this.dataCopy.forEach((item, index) => {
            if (item.item.toLowerCase().indexOf(val) === -1) {
                for (let i = 0; i < dataCopyIner.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (item.id == dataCopyIner[i].id && item.item == dataCopyIner[i].item) {
                        dataCopyIner.splice(i, 1);
                    }
                }
            }
        });
        this.dataChange.next(dataCopyIner);
    }

    generateKdmTree(word: any) {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
        this.dataChange = new BehaviorSubject<TodoItemNode[]>([]);
        this.subscription = this.cinemaService.getFull(word)
            .subscribe(data => {
                const to_build: any = [];
                for (let _i = 0; _i < data.data.length; _i++) {
                    const childrens: any = [];
                    for (let _j = 0; _j < data.data[_i].cinema_room_full.length; _j++) {
                        childrens[data.data[_i].cinema_room_full[_j].name] = {
                            'id': data.data[_i].cinema_room_full[_j].id,
                            'cinema_id': data.data[_i].cinema_room_full[_j].cinema_id,
                            'name': data.data[_i].cinema_room_full[_j].name,
                            'kdms_list': []
                        };
                    }
                    to_build[data.data[_i].full_name] = {'id': data.data[_i].id, 'childrens': childrens};
                }
                const xdata = this.buildFileTree(to_build, 0);
                this.dataChange.next(xdata);
                this.dataCopy = this.dataChange.getValue();
            });
        return this.dataChange;
    }

}
