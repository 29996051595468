import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {Subscription} from 'rxjs';

import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseConfigService} from '@fuse/services/config.service';

import {navigation} from 'app/navigation/navigation';
import {navigation_cinema} from 'app/navigation/navigation_cinema';
import {navigation_distributor} from 'app/navigation/navigation_distributor';
import {navigation_multiplex} from 'app/navigation/navigation_multiplex';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarComponent} from '@fuse/components/sidebar/sidebar.component';
import {User} from 'app/_models/user';

@Component({
    selector: 'fuse-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavbarComponent implements OnInit, OnDestroy {
    private fusePerfectScrollbar: FusePerfectScrollbarDirective;
    public innerWidth: any;
    currentUser: User;
    onConfigChanged: Subscription;
    fuseSettings: any;
    @ViewChild(FusePerfectScrollbarDirective) set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this.fusePerfectScrollbar = theDirective;

        this.navigationServiceWatcher =
            this.navigationService.onItemCollapseToggled.subscribe(() => {
                this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
                    this.fusePerfectScrollbar.update();
                }, 310);
            });
    }

    @Input() layout;
    navigation: any;
    navigationServiceWatcher: Subscription;
    fusePerfectScrollbarUpdateTimeout;

    constructor(
        private sidebarService: FuseSidebarService,
        private navigationService: FuseNavigationService,
        private router: Router,
        private fuseConfig: FuseConfigService,

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.fuseSettings = newSettings;
                    }
                );
        // Navigation data
        if (this.currentUser !== null && this.currentUser.role_id === 2 && this.currentUser.cinema_id !== null) {
            this.navigation = navigation_cinema;
        } else if (this.currentUser !== null && this.currentUser.role_id === 3 && this.currentUser.distributor_id !== null) {
            this.navigation = navigation_distributor;
        } else if (this.currentUser !== null && this.currentUser.role_id === 4 && this.currentUser.multiplex_id !== null) {
            this.navigation = navigation_multiplex;
        } else {
            this.navigation = navigation;
        }

        // Default layout
        this.layout = 'vertical';
    }

    ngOnInit() {
        this.router.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd) {
                    if (this.sidebarService.getSidebar('navbar')) {
                        this.sidebarService.getSidebar('navbar').close();
                    }
                }
            }
        );
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 1400) {
            this.sidebarService.getSidebar('navbar').toggleFold();
        }
    }

    ngOnChanges() {
        if (this.innerWidth < 1400) {
            this.sidebarService.getSidebar('navbar').toggleFold();
        }
    }

    ngOnDestroy() {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }

        if (this.navigationServiceWatcher) {
            this.navigationServiceWatcher.unsubscribe();
        }
    }

    toggleSidebarOpened() {
        this.sidebarService.getSidebar('navbar').toggleOpen();
    }

    toggleSidebarFolded() {
        this.sidebarService.getSidebar('navbar').toggleFold();
    }
}
