import {Component, OnInit, Inject} from '@angular/core';
import {DashboardService} from 'app/_services/dashboard.service';
import {icon, popup, marker, latLng, tileLayer} from 'leaflet';
import {MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent} from '@angular/material';

@Component({
    selector: 'app-user-map',
    templateUrl: './user-map.component.html',
    styleUrls: ['./user-map.component.scss']
})
export class UserMapComponent implements OnInit {
    options: any = null;
    infoWindowOpened: any = null;

    constructor(
        public dialogRef: MatDialogRef<UserMapComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dashboardService: DashboardService,
    ) {}

    ngOnInit() {
        this.dashboardService.mapMarkers()
            .subscribe(data => {
                let layers_copy = [];
                for (let i = 0; i < data.data.markers.length; i++) {
                    let label = '';
                    label += '<b>Kino:</b> ' + data.data.markers[i].full_name + '<br>';
                    label += '<b>Liczba aktywnych KDM:</b> ' + data.data.markers[i].sum_kdms + '<br>';

                    let markerx = marker([data.data.markers[i].lat, data.data.markers[i].lon], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'leaflet/marker-icon.png',
                            shadowUrl: 'leaflet/marker-shadow.png',

                        })
                    }).bindPopup(label);
                    layers_copy.push(markerx);
                }
                let streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    detectRetina: true,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                });

                layers_copy.push(streetMaps);
                this.options = {
                    layers: layers_copy,
                    zoom: 6,
                    scrollWheelZoom: false,
                    center: latLng([52, 20])
                };
            });
    }
    markerClick(infoWindow: any) {
        if (this.infoWindowOpened === infoWindow)
            return;
        if (this.infoWindowOpened !== null)
            this.infoWindowOpened.close();

        this.infoWindowOpened = infoWindow;
    }
}
