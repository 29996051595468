import {Component, OnInit} from '@angular/core';
import {SettingsService} from 'app/_services/settings.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators, FormArray} from "@angular/forms";

@Component({
    selector: 'app-settings-edit',
    templateUrl: './settings-edit.component.html',
    styleUrls: ['./settings-edit.component.scss']
})
export class SettingsEditComponent implements OnInit {
    editForm: FormGroup;
    formErrors: any;

    constructor(
        private settingsService: SettingsService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,

    ) {}
    get settingsForms() {
        return this.editForm.get('settings') as FormArray
    }
    ngOnInit() {
        this.editForm = this.formBuilder.group({
            settings: this.formBuilder.array([])
        });
        this.settingsService.getAll()
            .subscribe(data => {
                for (let i = 0; i < data.data.length; i++) {
                    const setting = this.formBuilder.group({
                        id: [data.data[i].id],
                        option: [data.data[i].option],
                        value: [data.data[i].value],
                    })
                    this.settingsForms.push(setting);
                }
            });

    }
    onSubmit() {
        this.settingsService.update(this.editForm.value)
            .subscribe(
                data => {
                    this.toastr.success(data.message, 'Sukces');
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }
}
