import {Component, OnInit, Inject} from '@angular/core';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
@Component({
    selector: 'app-movie-release-kdms',
    templateUrl: './movie-release-kdms.component.html',
    styleUrls: ['./movie-release-kdms.component.scss']
})
export class MovieReleaseKdmsComponent implements OnInit {

    kdms: any;
    name: string;
    api_url: string = environment.apiUrl;
    loadingIndicator = true;
    reorderable = true;
    constructor(
        private router: Router,
        private movieReleaseService: MovieReleaseService,
        public dialogRef: MatDialogRef<MovieReleaseKdmsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService,
        private http: Http,
    ) {}

    ngOnInit() {
        let movieReleaseId = localStorage.getItem("kdmMovieReleaseId");
        if (!movieReleaseId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['movies']);
            return;
        }

        this.movieReleaseService.getKdmsById(+movieReleaseId)
            .subscribe(data => {
                this.kdms = data.data;
                this.loadingIndicator = false;
            });
        this.movieReleaseService.getById(+movieReleaseId)
            .subscribe(data => {
                this.name = data.data.movie_name;
            });
    }
    downloadFile(id: string) {
        this.movieReleaseService.downloadPackage(id, localStorage.getItem("kdmMovieReleaseId"))
            .subscribe(data => {
                let url = this.api_url + '/' + data.data;
                const filename = url.split('/').pop();
                return this.http
                    .get(url, {
                        responseType: ResponseContentType.Blob,
                    })
                    .map(res => {
                        return {
                            filename: filename,
                            data: res.blob()
                        };
                    })
                    .subscribe(res => {
                        var url = window.URL.createObjectURL(res.data);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove(); // remove the element
                    }, error => {
                        this.toastr.error(error.error.message, 'Błąd');
                    }, () => {
                    });

            });
    }
}
