import {Component} from '@angular/core';
import {SettingsService} from 'app/_services/settings.service';

@Component({
    selector: 'fuse-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],

})

export class FuseFooterComponent {
    version: any = '';

    constructor(
        private settingsService: SettingsService,
    ) {
        this.getVersion();
    }
    getVersion() {
        this.settingsService.version()
            .subscribe(data => {
                this.version = data.message;
            });
    }
}
