import {Component, OnInit, ViewChild} from '@angular/core';
import {Message} from 'app/_models/message';
import {LogService} from 'app/_services/log.service';

import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD H:mm',
    fullPickerInput: 'YYYY-MM-DD H:mm',
    datePickerInput: 'YYYY-MM-DD H:mm',
    timePickerInput: 'YYYY-MM-DD H:mm',
    monthYearLabel: 'YYYY-MM-DD H:mm',
    dateA11yLabel: 'YYYY-MM-DD H:mm',
    monthYearA11yLabel: 'YYYY-MM-DD H:mm',
};

@Component({
    selector: 'app-log',
    templateUrl: './log.component.html',
    styleUrls: ['./log.component.scss'],
    providers: [
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    ]
})
export class LogComponent implements OnInit {
    @ViewChild('myTable') table: any;

    rows: Message[] = [];
    loadingIndicator = true;
    reorderable = true;
    constructor(
        private logService: LogService,
    ) {}

    ngOnInit() {
        this.logService.getAll()
            .subscribe(data => {
                this.rows = data.data;
                this.loadingIndicator = false;
            });
    }
}
