import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {KdmCinemaRoomDatabase, MoviesReleasesItemFlatNode, MoviesReleasesItemNode} from './kdm_cinema_room_database'
import {KdmService} from 'app/_services/kdm.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MessageService} from 'app/_services/message.service';
import {LogService} from 'app/_services/log.service';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD HH:mm',
    fullPickerInput: 'YYYY-MM-DD HH:mm',
    datePickerInput: 'YYYY-MM-DD HH:mm',
    timePickerInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'YYYY-MM-DD HH:mm',
    dateA11yLabel: 'YYYY-MM-DD HH:mm',
    monthYearA11yLabel: 'YYYY-MM-DD HH:mm',
};
@Component({
    selector: 'app-kdm-cinema-room',
    templateUrl: './kdm-cinema-room.component.html',
    styleUrls: ['./kdm-cinema-room.component.scss'],
    providers: [KdmCinemaRoomDatabase,
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'}
    ]
})
export class KdmCinemaRoomComponent implements OnInit {
    flatNodeMapMoviesReleases: Map<MoviesReleasesItemFlatNode, MoviesReleasesItemNode> = new Map<MoviesReleasesItemFlatNode, MoviesReleasesItemNode>();
    nestedNodeMapMoviesReleases: Map<MoviesReleasesItemNode, MoviesReleasesItemFlatNode> = new Map<MoviesReleasesItemNode, MoviesReleasesItemFlatNode>();
    treeControlMoviesReleases: FlatTreeControl<MoviesReleasesItemFlatNode>;
    treeFlattenerMoviesReleases: MatTreeFlattener<MoviesReleasesItemNode, MoviesReleasesItemFlatNode>;
    dataSourceMoviesReleases: MatTreeFlatDataSource<MoviesReleasesItemNode, MoviesReleasesItemFlatNode>;
    listSelectionMoviesReleases = new SelectionModel<MoviesReleasesItemFlatNode>(true /* multiple */);
    selectedMovies: any[];
    cinemaRoomId: any = null;
    selectMovies: FormGroup;
    generate_kdm: FormGroup;
    selectMoviesErrors: any;
    CinemaRoom: any = null;
    editable: any = true;
    summary: FormGroup;
    sendMessage: FormGroup;
    generated_kdms_count: number = 0;
    sendedMessages: any[];
    constructor(
        private router: Router,
        private toastr: ToastrService,
        public database: KdmCinemaRoomDatabase,
        private formBuilder: FormBuilder,
        private cinemaRoomService: CinemaRoomService,
        private messageService: MessageService,
        private kdmService: KdmService,
        private logService: LogService,
    ) {
        this.treeFlattenerMoviesReleases = new MatTreeFlattener(this.transformerMoviesReleases, this.getLevelMoviesReleases, this.isExpandableMoviesReleases, this.getChildrenMoviesReleases);
        this.treeControlMoviesReleases = new FlatTreeControl<MoviesReleasesItemFlatNode>(this.getLevelMoviesReleases, this.isExpandableMoviesReleases);
        this.dataSourceMoviesReleases = new MatTreeFlatDataSource(this.treeControlMoviesReleases, this.treeFlattenerMoviesReleases);
        this.database.dataChangeMoviesReleases.subscribe(data => {
            this.dataSourceMoviesReleases.data = data;
        });
        this.selectMoviesErrors = {
            address: {},
            date_from: {},
            date_to: {}
        };
        this.messageService.kdm()
            .subscribe(data => {
                this.sendMessage.controls['message'].setValue(data.data);
            });
    }

    ngOnInit() {
        this.cinemaRoomId = localStorage.getItem("certCinemaRoomId");
        this.selectMovies = this.formBuilder.group({
            movies: ['', Validators.required],
        });
        if (!this.cinemaRoomId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['dashboard']);
            return;
        } else {
            this.database.generateCinemaRoomTree(this.cinemaRoomId);
            if (this.database != undefined) {
                this.database.dataChangeMoviesReleases.subscribe(data => {
                    this.dataSourceMoviesReleases.data = data;
                    this.selectAllMovieReleases();
                    this.getSelected();
                });
            }
            this.cinemaRoomService.getById(+this.cinemaRoomId)
                .subscribe(data => {
                    this.CinemaRoom = data.data;
                });
        }

        this.selectMovies.valueChanges.subscribe(() => {
            this.onFormValuesChangedMovies();
        });
        this.generate_kdm = this.formBuilder.group({});
        this.summary = this.formBuilder.group({});
        this.sendMessage = this.formBuilder.group({
            message: [''],
        });
    }
    selectAllMovieReleases() {
        this.nestedNodeMapMoviesReleases.forEach((val: MoviesReleasesItemFlatNode) => {
            if (val.expandable) {
                this.treeControlMoviesReleases.expand(val);
            }
            this.listSelectionMoviesReleases.toggle(val);

        });
    }
    doSomething(event: any) {
        switch (event.selectedIndex) {
            case 1:
                this.getSelected();
                break;
            case 2:
                this.generateKDM();
                break;
            case 4:
                this.sendMessages();
                break;
        }
    }
    //TREE-CINEMA
    getLevelMoviesReleases = (node: MoviesReleasesItemFlatNode) => {return node.level;};
    isExpandableMoviesReleases = (node: MoviesReleasesItemFlatNode) => {return node.expandable;};
    getChildrenMoviesReleases = (node: MoviesReleasesItemNode): Observable<MoviesReleasesItemNode[]> => {
        return ofObservable(node.children);
    }
    hasChildMoviesReleases = (_: number, _nodeData: MoviesReleasesItemFlatNode) => {return _nodeData.expandable;};
    hasNoContentMoviesReleases = (_: number, _nodeData: MoviesReleasesItemFlatNode) => {return _nodeData.item === '';};
    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformerMoviesReleases = (node: MoviesReleasesItemNode, level: number) => {
        let flatNode = this.nestedNodeMapMoviesReleases.has(node) && this.nestedNodeMapMoviesReleases.get(node)!.item === node.item
            ? this.nestedNodeMapMoviesReleases.get(node)!
            : new MoviesReleasesItemFlatNode();
        flatNode.id = node.id;
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.date_time_to = node.date_time_to;
        flatNode.movie_id = node.movie_id;
        flatNode.date_time_from = node.date_time_from;
        flatNode.expandable = !!node.children;
        this.flatNodeMapMoviesReleases.set(flatNode, node);
        this.nestedNodeMapMoviesReleases.set(node, flatNode);
        return flatNode;
    }
    /** Whether all the descendants of the node are selected */
    descendantsAllSelectedMoviesReleases(node: MoviesReleasesItemFlatNode): boolean {
        const descendants = this.treeControlMoviesReleases.getDescendants(node);
        return descendants.every(child => this.listSelectionMoviesReleases.isSelected(child));
    }
    selectAllMoviesReleases() {
        this.nestedNodeMapMoviesReleases.forEach((val: MoviesReleasesItemFlatNode) => {
            if (val.expandable) {
                this.treeControlMoviesReleases.expand(val);
            }
            this.listSelectionMoviesReleases.toggle(val);

        });
    }
    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedMoviesReleases(node: MoviesReleasesItemFlatNode): boolean {
        const descendants = this.treeControlMoviesReleases.getDescendants(node);
        const result = descendants.some(child => this.listSelectionMoviesReleases.isSelected(child));
        return result && !this.descendantsAllSelectedMoviesReleases(node);
    }
    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleMoviesReleases(node: MoviesReleasesItemFlatNode): void {
        this.listSelectionMoviesReleases.toggle(node);
        const descendants = this.treeControlMoviesReleases.getDescendants(node);
        this.listSelectionMoviesReleases.isSelected(node)
            ? this.listSelectionMoviesReleases.select(...descendants)
            : this.listSelectionMoviesReleases.deselect(...descendants);
        this.getSelected();
    }

    selectedNodeMoviesReleases(node: MoviesReleasesItemFlatNode) {
        this.treeControlMoviesReleases.expand(node);
        this.listSelectionMoviesReleases.toggle(node);
    }
    onFormValuesChangedMovies() {
        for (const field in this.selectMoviesErrors) {
            if (!this.selectMoviesErrors.hasOwnProperty(field)) {
                continue;
            }
            this.selectMoviesErrors[field] = {};
            const control = this.selectMovies.get(field);
            if (control && !control.valid) {
                this.selectMoviesErrors[field] = control.errors;
            }
        }
    }

    getSelected() {
        if (this.selectedMovies == undefined) {
            this.selectedMovies = [];
        }
        this.nestedNodeMapMoviesReleases.forEach((val: MoviesReleasesItemFlatNode) => {
            if (this.listSelectionMoviesReleases.isSelected(val) && val.expandable == false) {
                let cinema_index = null;
                for (let i = 0; i < this.selectedMovies.length; i++) {
                    if (this.selectedMovies[i].id == val.movie_id) {
                        cinema_index = i;
                    }
                }
                let arrayToAdd: any = {};
                arrayToAdd.id = val.id;
                arrayToAdd.item = val.item;
                arrayToAdd.status = 'W TRAKCIE';
                arrayToAdd.from = moment(val.date_time_from).format('YYYY-MM-DD H:mm');
                arrayToAdd.to = moment(val.date_time_to).format('YYYY-MM-DD H:mm');
                arrayToAdd.message = '';
                arrayToAdd.file = '';

                let added = false;
                if (this.selectedMovies != undefined) {
                    for (let j = 0; j < this.selectedMovies[cinema_index].releases.length; j++) {
                        if (this.selectedMovies[cinema_index].releases[j].id == val.id && this.selectedMovies[cinema_index].releases[j].item == val.item) {
                            this.selectedMovies[cinema_index].releases[j] = arrayToAdd;
                            added = true;
                        }
                    }
                    if (!added) {
                        for (let i = 0; i < this.selectedMovies.length; i++) {
                            if (this.selectedMovies[i].id == val.movie_id) {
                                this.selectedMovies[i].releases.push(arrayToAdd);
                            }
                        }
                    }
                }
            } else if (((this.listSelectionMoviesReleases.isSelected(val) && (this.descendantsPartiallySelectedMoviesReleases(val) || this.descendantsAllSelectedMoviesReleases(val))) || this.descendantsPartiallySelectedMoviesReleases(val) || this.descendantsAllSelectedMoviesReleases(val)) && val.expandable == true) {
                let arrayToAdd: any = {};
                arrayToAdd.id = val.id;
                arrayToAdd.item = val.item;
                arrayToAdd.releases = [];
                let added = false;
                if (this.selectedMovies != undefined) {
                    for (let i = 0; i < this.selectedMovies.length; i++) {
                        if (this.selectedMovies[i].id == val.id && this.selectedMovies[i].item == val.item) {
                            this.selectedMovies[i] = arrayToAdd;
                            added = true;
                        }
                    }
                }
                if (!added) {
                    this.selectedMovies.push(arrayToAdd);
                }
            } else if (!this.descendantsPartiallySelectedMoviesReleases(val) && !this.descendantsAllSelectedMoviesReleases(val)) {
                if (this.selectedMovies != undefined) {
                    for (let i = 0; i < this.selectedMovies.length; i++) {
                        if (this.selectedMovies[i].id == val.id && this.selectedMovies[i].item == val.item) {
                            val.date_time_from = null;
                            val.date_time_to = null;
                            this.selectedMovies.splice(i, 1);
                        }
                    }
                }
            }
        });
        if (this.selectedMovies.length != 0) {
            this.selectMovies.controls['movies'].setValue(this.selectedMovies.length);
        } else {
            this.selectMovies.controls['movies'].setValue('');
        }
    }


    generateKDM() {
        let ite = 0;
        this.editable = false;
        for (let i = 0; i < this.selectedMovies.length; i++) {
            for (let j = 0; j < this.selectedMovies[i].releases.length; j++) {
                ite++;
                this.kdmService.generate(this.selectedMovies[i].releases[j].id, this.CinemaRoom.id, this.CinemaRoom.cinema_id, this.selectedMovies[i].releases[j].from, this.selectedMovies[i].releases[j].to, 1)
                    .subscribe(
                        data => {
                            this.generated_kdms_count++;
                            this.selectedMovies[i].releases[j].status = data.data;
                            this.selectedMovies[i].releases[j].file = data.message;
                        },
                        error => {
                            this.selectedMovies[i].releases[j].status = 'BŁĄD';
                            this.selectedMovies[i].releases[j].message = error.error.message;
                        });
            }
        }
        this.logService.saveLog('KDM/generate', this.CinemaRoom.cinema_name + ' KDM: ' + ite).subscribe();
    }
    sendMessages() {
        this.sendedMessages = [];

        for (let i = 0; i < this.selectedMovies.length; i++) {
            for (let j = 0; j < this.selectedMovies[i].releases.length; j++) {
                if (this.selectedMovies[i].releases[j].status == "OK") {
                    let arrayToAdd: any = {};
                    arrayToAdd.id = this.selectedMovies[i].releases[j].id;
                    arrayToAdd.movie_name = this.selectedMovies[i].item + ' ' + this.selectedMovies[i].releases[j].item;
                    arrayToAdd.status = 'W TRAKCIE';
                    arrayToAdd.from = this.selectedMovies[i].releases[j].from;
                    arrayToAdd.to = this.selectedMovies[i].releases[j].to;
                    arrayToAdd.file = this.selectedMovies[i].releases[j].file;
                    this.sendedMessages.push(arrayToAdd);
                }
            }
        }
        this.sendedMessages.forEach((val: any, key: any) => {
            this.kdmService.sendEmail(this.CinemaRoom.id, this.CinemaRoom.cinema_id, this.sendMessage.value, val)
                .subscribe(
                    data => {
                        this.sendedMessages[key].status = data.message;
                    },
                    error => {
                        this.sendedMessages[key].status = error.error.message;
                    });

        })
        this.logService.saveLog('KDM/sent', this.CinemaRoom.cinema_name + ' Kina: ' + this.sendedMessages.length).subscribe();
    }
    finishKDM(): void {
        this.router.navigate(['/']);
    };
}
