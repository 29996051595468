import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatStepperModule,
    MatDialogModule,
    MatTreeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatMenuModule,
    MatChipsModule,
    MatToolbarModule,
    MatCardModule,
    MatListModule,
    MatProgressBarModule
} from '@angular/material';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {ChartsModule} from 'ng2-charts';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DebounceModule} from 'ngx-debounce';

import {FuseContentComponent} from 'app/main/content/content.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MoviesComponent} from './movies/movies.component';
import {ReportsComponent} from './reports/reports.component';
import {SettingsComponent} from './settings/settings.component';
import {CinemasComponent} from './cinemas/cinemas.component';
import {MultiplexesComponent} from './multiplexes/multiplexes.component';
import {MultiplexAddComponent} from './multiplexes/multiplex-add/multiplex-add.component';
import {MultiplexEditComponent} from './multiplexes/multiplex-edit/multiplex-edit.component';
import {MultiplexBasicEditComponent} from './multiplexes/multiplex-basic-edit/multiplex-basic-edit.component';
import {CinemaEditComponent} from './cinemas/cinema-edit/cinema-edit.component';
import {CinemaAddComponent} from './cinemas/cinema-add/cinema-add.component';
import {CinemaRoomEditComponent} from './cinemas/cinema-room-edit/cinema-room-edit.component';
import {MovieAddComponent} from './movies/movie-add/movie-add.component';
import {MovieEditComponent} from './movies/movie-edit/movie-edit.component';
import {DistributorsComponent} from './distributors/distributors.component';
import {DistributorAddComponent} from './distributors/distributor-add/distributor-add.component';
import {DistributorEditComponent} from './distributors/distributor-edit/distributor-edit.component';
import {MovieReleaseEditComponent} from './movies/movie-release-edit/movie-release-edit.component';
import {UsersComponent} from './users/users.component';
import {AddUserComponent} from './users/add-user/add-user.component';
import {EditUserComponent} from './users/edit-user/edit-user.component';
import {KdmComponent} from './kdm/kdm.component';
import {Ng2CompleterModule} from 'ng2-completer';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {FuseHighlightModule} from '@fuse/components';
import {MomentModule} from 'ngx-moment';
import {MatSlideToggleModule} from '@angular/material';

import {NgxEditorModule} from 'ngx-editor';
import {CertificateComponent} from './settings/certificate/certificate.component';
import {CinemaRoomAddComponent} from './cinemas/cinema-room-add/cinema-room-add.component';
import {MovieReleaseAddComponent} from './movies/movie-release-add/movie-release-add.component';
import {KdmReportsComponent} from './reports/kdm-reports/kdm-reports.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {MessageReportsComponent} from './reports/message-reports/message-reports.component';
import {LogComponent} from './settings/log/log.component';
import {CinemaKdmsComponent} from './cinemas/cinema-kdms/cinema-kdms.component';
import {CinemaRoomKdmsComponent} from './cinemas/cinema-room-kdms/cinema-room-kdms.component';
import {MovieKdmsComponent} from './movies/movie-kdms/movie-kdms.component';
import {MovieReleaseKdmsComponent} from './movies/movie-release-kdms/movie-release-kdms.component';
import {TextMaskModule} from 'angular2-text-mask';
import {CinemaHistoryComponent} from './cinemas/cinema-history/cinema-history.component';
import {DistributorsReportsComponent} from './reports/distributors-reports/distributors-reports.component';
import {FuseWidgetModule} from '@fuse/components/widget/widget.module';
import {CinemaRoomCertChangeComponent} from './cinemas/cinema-room-cert-change/cinema-room-cert-change.component';
import {KdmCinemaRoomComponent} from './kdm-cinema-room/kdm-cinema-room.component';
import {CinemaRoomKdmHistoryComponent} from './cinemas/cinema-room-kdm-history/cinema-room-kdm-history.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {JsonDecode} from 'app/_pipes/json_decode.pipe';
import {ParseDate} from 'app/_pipes/parse_date.pipe';
import {CinemaHideMultiplexInfoPipe} from 'app/_pipes/cinema_hide_multiplex_info.pipe';
import {SettingsEditComponent} from './settings/settings-edit/settings-edit.component';
import {UserCinemaComponent} from './user-cinema/user-cinema.component';
import {UserCinemaAddressComponent} from './user-cinema/user-cinema-address/user-cinema-address.component';
import {TagInputModule} from 'ngx-chips';
import {UserCinemaRoomsComponent} from './user-cinema/user-cinema-rooms/user-cinema-rooms.component';
import {UserMoviesComponent} from './user-movies/user-movies.component';
import {UserCinemaAddressesComponent} from './user-cinema/user-cinema-addresses/user-cinema-addresses.component';
import {UserCinemaRoomChangeDKDMComponent} from './user-cinema/user-cinema-room-change-dkdm/user-cinema-room-change-dkdm.component';
import {CinemaHideCityInfoPipe} from 'app/_pipes/cinema_hide_city_info.pipe';
import {DistributorMoviesComponent} from './distributor-movies/distributor-movies.component';
import {DistributorMovieAddComponent} from './distributor-movies/distributor-movie-add/distributor-movie-add.component';
import {MovieReleaseEditFullComponent} from './movies/movie-release-edit/movie-release-edit-full.component';
import {UserMapComponent} from './user-map/user-map.component';

@NgModule({
    declarations: [
        JsonDecode,
        ParseDate,
        CinemaHideMultiplexInfoPipe,
        CinemaHideCityInfoPipe,
        FuseContentComponent,
        DashboardComponent,
        MoviesComponent,
        ReportsComponent,
        SettingsComponent,
        CinemasComponent,
        MultiplexesComponent,
        MultiplexAddComponent,
        MultiplexEditComponent,
        MultiplexBasicEditComponent,
        CinemaEditComponent,
        CinemaAddComponent,
        CinemaRoomEditComponent,
        MovieAddComponent,
        MovieEditComponent,
        DistributorsComponent,
        DistributorAddComponent,
        DistributorEditComponent,
        MovieReleaseEditComponent,
        UsersComponent,
        AddUserComponent,
        EditUserComponent,
        KdmComponent,
        CertificateComponent,
        CinemaRoomAddComponent,
        MovieReleaseAddComponent,
        KdmReportsComponent,
        UserProfileComponent,
        MessageReportsComponent,
        LogComponent,
        CinemaKdmsComponent,
        CinemaRoomKdmsComponent,
        MovieKdmsComponent,
        MovieReleaseKdmsComponent,
        CinemaHistoryComponent,
        DistributorsReportsComponent,
        CinemaRoomCertChangeComponent,
        KdmCinemaRoomComponent,
        CinemaRoomKdmHistoryComponent,
        SettingsEditComponent,
        UserCinemaComponent,
        UserCinemaAddressComponent,
        UserCinemaRoomsComponent,
        UserMoviesComponent,
        UserCinemaAddressesComponent,
        UserCinemaRoomChangeDKDMComponent,
        DistributorMoviesComponent,
        DistributorMovieAddComponent,
        MovieReleaseEditFullComponent,
        UserMapComponent,
    ],
    imports: [
        DebounceModule,
        NgxChartsModule,
        ChartsModule,
        MomentModule,
        RouterModule,
        MatButtonModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTreeModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatDialogModule,
        NgxDatatableModule,
        FuseSharedModule,
        Ng2CompleterModule,
        FormsModule,
        TextMaskModule,
        MatTooltipModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxEditorModule,
        FuseHighlightModule,
        FuseWidgetModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger'
        }),
        LeafletModule.forRoot(),
        MatChipsModule,
        TagInputModule,
        MatToolbarModule,
        MatCardModule,
        MatProgressBarModule
    ],
    exports: [
        FuseContentComponent
    ],
    entryComponents: [
        MovieEditComponent,
        MovieReleaseEditComponent,
        MovieReleaseAddComponent,
        MovieKdmsComponent,
        MovieReleaseKdmsComponent,
        CinemaEditComponent,
        CinemaHistoryComponent,
        CinemaRoomEditComponent,
        CinemaKdmsComponent,
        CinemaRoomKdmsComponent,
        CinemaRoomAddComponent,
        MultiplexAddComponent,
        MultiplexEditComponent,
        MultiplexBasicEditComponent,
        DistributorAddComponent,
        DistributorEditComponent,
        AddUserComponent,
        EditUserComponent,
        CinemaRoomCertChangeComponent,
        CinemaRoomKdmHistoryComponent,
        UserCinemaRoomChangeDKDMComponent,
        MovieReleaseEditFullComponent,
        UserMapComponent
    ],
})
export class FuseContentModule {
}
