import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from 'app/_models/user';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class UserProfileService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    changePassword(password: string) {
        return this.http.post<any>(API_URL + '/api/user/change_password', {loggedInId: this.currentUser.id, token: this.currentUser.token, password});
    }

}