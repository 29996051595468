import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {AuthenticationService} from 'app/_services/authentication.service';
import {fuseAnimations} from '@fuse/animations';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    currentUser: any = null;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fuseConfig: FuseConfigService,
        private toastr: ToastrService
    ) {
        this.fuseConfig.setConfig({
            layout: {
                navigation: 'none',
                toolbar: 'none',
                footer: 'none'
            }
        });
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            this.authenticationService.logout().subscribe();
        }
        localStorage.removeItem('currentUser');
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    }

    get f() {
      return this.loginForm.controls; 
    }

    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                    this.loading = false;
                });
    }

    passwordReset(): void {
        this.router.navigate(['password_reset']);
    }
}
