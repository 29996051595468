import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Multiplex } from 'app/_models/multiplex';
import { User } from 'app/_models/user';

import { environment } from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class MultiplexService {
  currentUser: User;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  getAll() {
    return this.http.post<any>(API_URL + '/api/multiplexes', { loggedInId: this.currentUser.id, token: this.currentUser.token });
  }

  getById(id: number) {
    return this.http.post<any>(API_URL + '/api/multiplexes/get/' + id, { loggedInId: this.currentUser.id, token: this.currentUser.token });
  }

  getMyMultiplex() {
    return this.http.post<any>(API_URL + '/api/multiplexes/get/' + this.currentUser.multiplex_id, { loggedInId: this.currentUser.id, token: this.currentUser.token });
  }

  create(multiplex: Multiplex) {
    return this.http.post(API_URL + '/api/multiplexes/add', { loggedInId: this.currentUser.id, token: this.currentUser.token, multiplex });
  }

  update(multiplex: Multiplex) {
    return this.http.put(API_URL + '/api/multiplexes/edit/' + multiplex.id, { loggedInId: this.currentUser.id, token: this.currentUser.token, multiplex });
  }

  delete(id: number) {
    return this.http.post<any>(API_URL + '/api/multiplexes/delete/' + id, { loggedInId: this.currentUser.id, token: this.currentUser.token });
  }
}
