import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {MovieKdm} from 'app/_models/movie_kdm';
import {User} from 'app/_models/user';
import {Page} from 'app/_models/page';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class MovieKdmService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll(page: Page, sort: any = null) {
        return this.http.post<any>(API_URL + '/api/movie_kdms', {loggedInId: this.currentUser.id, token: this.currentUser.token, page, sort});
    }
    getList(release_id: any) {
        return this.http.post<any>(API_URL + '/api/movie_kdms/list', {loggedInId: this.currentUser.id, token: this.currentUser.token, release_id});
    }
    sendAgain(message_id: any) {
        return this.http.post<any>(API_URL + '/api/movie_kdms/send_again/' + message_id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    deletePackage(package_id: any) {
        return this.http.post<any>(API_URL + '/api/movie_kdms/delete/' + package_id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

    //   getById(id: number) {
    //      return this.http.post<any>(API_URL + '/api/movies/get/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    //   }
    //
    //   create(movie: Movie) {
    //      return this.http.post(API_URL + '/api/movies/add', {loggedInId: this.currentUser.id, token: this.currentUser.token, movie});
    //   }
    //
    //   update(movie: Movie) {
    //      return this.http.put(API_URL + '/api/movies/edit/' + movie.id, {loggedInId: this.currentUser.id, token: this.currentUser.token, movie});
    //   }
    //
    //   delete(id: number) {
    //      return this.http.post<any>(API_URL + '/api/movies/delete/' + id, {loggedInId: this.currentUser.id, token: this.currentUser.token});
    //   }
}