import { Pipe, PipeTransform } from '@angular/core';
import { Cinema } from './../_models/cinema';

@Pipe({ name: 'cinemaHideCityInfo' })
export class CinemaHideCityInfoPipe implements PipeTransform {
  transform(cinema: Cinema, firstMultiplex = true) {
    let result = '';
    if ((<any>cinema).multiplex) {
      result += (<any>cinema).multiplex;
    }
    if (cinema.name) {
      if (firstMultiplex) {
        result += (result.length ? ', ' : '') + cinema.name;
      }
      else {
        result = cinema.name + (result.length ? ', ' : '') + result;
      }
    }
    return result;
  }
}
