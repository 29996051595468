import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) {}

    login(username: string, password: string) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.http.post<any>(API_URL + '/api/authenticate', {username: username, password: password})
            .pipe(map(user => {
                if (user.data && user.data.token) {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                }
                return user;
            }));
    }

    logout() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser != null) {
           return this.http.post<any>(API_URL + '/api/logout', {loggedInId: currentUser.id, token: currentUser.token});
        }
        return this.http.post<any>(API_URL + '/api/logout', {loggedInId: null, token: null});
    }

}