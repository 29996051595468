import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CinemaService } from '../../../../_services/cinema.service';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { CinemaRoom } from '../../../../_models/cinema_room';
import { CinemaRoomService } from '../../../../_services/cinema_room.service';
import { Http, ResponseContentType } from '@angular/http';
import { CinemaRoomKdmsComponent } from '../../cinemas/cinema-room-kdms/cinema-room-kdms.component';
import { MultiplexService } from '../../../../_services/multiplex.service';
import { ListsService } from '../../../../_services/lists.service';
import { UserCinemaRoomChangeDKDMComponent } from '../user-cinema-room-change-dkdm/user-cinema-room-change-dkdm.component';
import { CinemaHideMultiplexInfoPipe } from '../../../../_pipes/cinema_hide_multiplex_info.pipe';
import { CinemaRoomKdmHistoryComponent } from '../../cinemas/cinema-room-kdm-history/cinema-room-kdm-history.component';

const API_URL = environment.apiUrl;

@Component({
  selector: 'fuse-app-user-cinema-rooms',
  templateUrl: './user-cinema-rooms.component.html',
  styleUrls: ['./user-cinema-rooms.component.scss']
})
export class UserCinemaRoomsComponent implements OnInit {

  @ViewChild('emails') emailsInput: ElementRef;
  @ViewChild('myTable') table: any;
  rows: CinemaRoom[] = [];
  title = 'moje sale';
  context = '';
  temp: any = [];
  user: any;
  reorderable = false;
  api_url = API_URL;
  loadingIndicator = true;
  filter = '';

  constructor(
    public dialog: MatDialog,
    private http: Http,
    private cinemaService: CinemaService,
    private cinemaRoomService: CinemaRoomService,
    private toastr: ToastrService,
    private multiplexService: MultiplexService,
    private listsService: ListsService,
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.user = user;
  }

  ngOnInit() {
    if (this.user.role_id === 2) {
      this.cinemaService.getMyCinema()
        .subscribe(data => {
          this.context = (new CinemaHideMultiplexInfoPipe()).transform(data.data, false);
          this.findMyRooms();
        });
    }
    else if (this.user.role_id === 4) {
      this.multiplexService.getMyMultiplex()
        .subscribe(data => {
          this.context = 'Grupa ' + data.data.name;
          this.findMyRooms();
        });
    }
  }

  findMyRooms() {
    this.updateFilter();
  }

  updateFilter() {
    this.rows = [];
    this.loadingIndicator = true;
    this.cinemaRoomService.getView(this.filter)
      .subscribe(data => {
        this.rows = data.data;
        this.temp = [...data.data];
        this.loadingIndicator = false;
      });
  }

  cinemaRoomKdms(cinemaId: number) {
    localStorage.removeItem('kdmCinemaRoomId');
    localStorage.setItem('kdmCinemaRoomId', cinemaId.toString());
    this.dialog.open(CinemaRoomKdmsComponent, {
      width: '700px',
      data: { name: this.reorderable }
    });
  }

  reportChangeDKDM(cinemaRoomId: number): void {
    localStorage.removeItem('editCinemaRoomId');
    localStorage.setItem('editCinemaRoomId', cinemaRoomId.toString());
    const cinemaRoomEditDialog = this.dialog.open(UserCinemaRoomChangeDKDMComponent, {
      width: '500px',
      data: {}
    });

    cinemaRoomEditDialog.afterClosed().subscribe(result => {
      if (this.rows.length > 0 && result !== undefined && result.data !== undefined) {
        for (let i = 0; i < this.rows.length; i++) {
          if (Number(this.rows[i].id) === cinemaRoomId) {
            const rows = [...this.rows];
            rows[i].changed = result.data.changed;
            this.rows = rows;
          }
        }
      }
    });
  }

  rowStatus(row: CinemaRoom): string {
    if (row.changed) {
      return 'red-50-bg';
    }
    return '';
  }

  downloadFile(url: string) {
    const filename = url.split('/').pop();
    return this.http
      .get(url, {
        responseType: ResponseContentType.Blob,
      })
      .map(res => {
        return {
          filename: filename,
          data: res.blob()
        };
      })
      .subscribe(res => {
        const _url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');

        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = _url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(_url);
        a.remove(); // remove the element
      }, error => {
        this.toastr.error(error.error.message, 'Błąd');
      }, () => {
      });
  }

  cinemaRoomCertsHistory(cinemaRoomId: number) {
    localStorage.removeItem('historyCinemaRoomId');
    localStorage.setItem('historyCinemaRoomId', cinemaRoomId.toString());
    this.dialog.open(CinemaRoomKdmHistoryComponent, {
      width: '500px',
      data: { name: this.reorderable }
    });
  }

  getCertificateLinkWrapingClass(linkText: string) {
    if (/[\-]+/.test(linkText)) {
        return 'link--break-word';
    }
    return 'link--break-all';
  }
}

