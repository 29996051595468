import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from 'app/_models/user';

import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class ListsService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    multiplex(deleted = false) {
        return this.http.post<any>(API_URL + '/api/list/multiplex', {loggedInId: this.currentUser.id, token: this.currentUser.token, deleted: deleted});
    }
    cinema(deleted = false) {
        return this.http.post<any>(API_URL + '/api/list/cinema', {loggedInId: this.currentUser.id, token: this.currentUser.token, deleted: deleted});
    }
    distributor(deleted = false) {
        return this.http.post<any>(API_URL + '/api/list/distributor', {loggedInId: this.currentUser.id, token: this.currentUser.token, deleted: deleted});
    }
    releaseTypes() {
        return this.http.post<any>(API_URL + '/api/list/release-types', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
    movie() {
        return this.http.post<any>(API_URL + '/api/list/movie', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }

}
