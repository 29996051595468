import {Component, OnInit, ViewChild} from '@angular/core';
import {Cinema} from 'app/_models/cinema';
import {CinemaService} from 'app/_services/cinema.service';
import {CinemaRoomService} from 'app/_services/cinema_room.service';
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
import {CinemaEditComponent} from './cinema-edit/cinema-edit.component';
import {CinemaRoomEditComponent} from './cinema-room-edit/cinema-room-edit.component';
import {CinemaRoomAddComponent} from './cinema-room-add/cinema-room-add.component';
import {CinemaKdmsComponent} from './cinema-kdms/cinema-kdms.component';
import {CinemaRoomKdmsComponent} from './cinema-room-kdms/cinema-room-kdms.component';
import {CinemaRoomKdmHistoryComponent} from './cinema-room-kdm-history/cinema-room-kdm-history.component';
import {CinemaHistoryComponent} from './cinema-history/cinema-history.component';
import {CinemaRoomCertChangeComponent} from './cinema-room-cert-change/cinema-room-cert-change.component';
import {MatDialog} from '@angular/material';

const API_URL = environment.apiUrl;
@Component({
    selector: 'app-cinemas',
    templateUrl: './cinemas.component.html',
    styleUrls: ['./cinemas.component.scss']
})
export class CinemasComponent implements OnInit {
    @ViewChild('myTable') table: any;

    rows: Cinema[] = [];
    temp: any = [];
    reorderable = true;
    api_url = API_URL;
    expanded: any[] = [];
    loadingIndicator = true;
    filter: any = '';
    constructor(
        public dialog: MatDialog,
        private http: Http,
        private router: Router,
        private cinemaService: CinemaService,
        private cinemaRoomService: CinemaRoomService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.cinemaService.getView()
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
    }

    deleteCinema(cinema: any) {
        this.cinemaService.delete(cinema)
            .subscribe(
                data => {
                    this.rows = this.rows.filter(u => u.id !== cinema);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                })
    }
    deleteCinemaRoom(cinema_room: any) {
        this.cinemaRoomService.delete(cinema_room)
            .subscribe(
                data => {
                    for (let i = 0; i < this.rows.length; i++) {
                        if (this.rows[i].id == data.data.cinema_id && this.rows[i].cinema_rooms != undefined && this.rows[i].cinema_rooms.length > 0) {
                            this.rows[i].rooms_cnt = String(parseInt(this.rows[i].rooms_cnt) - 1);
                            this.rows[i].cinema_rooms = this.rows[i].cinema_rooms.filter(u => u.id !== cinema_room);
                        }
                    }
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                })
    }

    editCinema(cinema: Cinema): void {
        localStorage.removeItem("editCinemaId");
        localStorage.setItem("editCinemaId", cinema.toString());
        let cinemaEditDialog = this.dialog.open(CinemaEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
        cinemaEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(cinema)) {
                        let rows = [...this.rows];
                        if (rows[i].cinema_rooms != undefined) {
                            result.data.cinema_rooms = rows[i].cinema_rooms;
                        }
                        rows[i] = result.data;
                        this.rows = rows;
                        setTimeout(() => {
                            this.expand();
                        }, 10);
                    }
                }
            }
        });
    };
    addCinemaRoom(cinemaId: number, row: any) {
        localStorage.removeItem("addCinemaId");
        localStorage.setItem("addCinemaId", cinemaId.toString());
        let cinemaRoomAddDialog = this.dialog.open(CinemaRoomAddComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
        cinemaRoomAddDialog.afterClosed().subscribe(result => {
            this.cinemaRoomService.getViewId(result.data.cinema_id)
                .subscribe(data => {
                    const index = this.rows.indexOf(row);
                    this.rows[index].cinema_rooms = data.data;
                    this.rows[index].rooms_cnt += 1;
                    setTimeout(() => {
                        this.expand();
                    }, 10);
                });
        });
    }
    cinemaKdms(cinemaId: number) {
        localStorage.removeItem("kdmCinemaId");
        localStorage.setItem("kdmCinemaId", cinemaId.toString());
        this.dialog.open(CinemaKdmsComponent, {
            width: '700px',
            data: {name: this.reorderable}
        });
    }
    cinemaHistory(cinemaId: number) {
        localStorage.removeItem("historyCinemaId");
        localStorage.setItem("historyCinemaId", cinemaId.toString());
        this.dialog.open(CinemaHistoryComponent, {
            width: '800px',
            data: {name: this.reorderable}
        });
    }
    cinemaRoomKdms(cinemaId: number) {
        localStorage.removeItem("kdmCinemaRoomId");
        localStorage.setItem("kdmCinemaRoomId", cinemaId.toString());
        this.dialog.open(CinemaRoomKdmsComponent, {
            width: '700px',
            data: {name: this.reorderable}
        });
    }
    cinemaRoomCertsHistory(cinemaId: number) {
        localStorage.removeItem("historyCinemaRoomId");
        localStorage.setItem("historyCinemaRoomId", cinemaId.toString());
        this.dialog.open(CinemaRoomKdmHistoryComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
    }
    cinemaRoomCertChange(cinemaId: number) {
        localStorage.removeItem("certCinemaRoomId");
        localStorage.setItem("certCinemaRoomId", cinemaId.toString());
        this.dialog.open(CinemaRoomCertChangeComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });
    }
    editCinemaRoom(cinemaRoomId: number): void {
        localStorage.removeItem("editCinemaRoomId");
        localStorage.setItem("editCinemaRoomId", cinemaRoomId.toString());
        let cinemaRoomEditDialog = this.dialog.open(CinemaRoomEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        cinemaRoomEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != 'undefined' && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(result.data.cinema_id)) {
                        for (let j = 0; j < this.rows[i].cinema_rooms.length; j++) {
                            if (this.rows[i].cinema_rooms[j].id == Number(cinemaRoomId)) {
                                let rows = [...this.rows];
                                if (rows[i].cinema_rooms[j].certificate_path != result.data.certificate_path && result.data.sum_kdms > 0) {
                                    this.cinemaRoomCertChange(cinemaRoomId);
                                }
                                rows[i].cinema_rooms[j] = result.data;
                                this.rows = rows;
                                setTimeout(() => {
                                    this.expand();
                                }, 10);
                            }
                        }
                    }
                }
            }
        });
    };
    addCinema(): void {
        this.router.navigate(['cinema/add']);
    };
    toggleExpandRow(row: any) {
        const index = this.rows.indexOf(row);
        if (this.temp[index].cinema_rooms == undefined) {
            this.cinemaRoomService.getViewId(row.id)
                .subscribe(data => {
                    this.rows[index].cinema_rooms = data.data;
                    if (this.expanded.includes(row.id)) {
                        this.expanded.splice(this.expanded.indexOf(row.id), 1);
                    } else {
                        this.expanded.push(row.id);
                    }
                    this.table.rowDetail.toggleExpandRow(row);
                });
        } else {
            if (this.expanded.includes(row.id)) {
                this.expanded.splice(this.expanded.indexOf(row.id), 1);
            } else {
                this.expanded.push(row.id);
            }
            this.table.rowDetail.toggleExpandRow(row);
        }

    }
    downloadFile(url: string) {
        const filename = url.split('/').pop();
        return this.http
            .get(url, {
                responseType: ResponseContentType.Blob,
            })
            .map(res => {
                return {
                    filename: filename,
                    data: res.blob()
                };
            })
            .subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
                this.toastr.error(error.error.message, 'Błąd');
            }, () => {
            });
    }
    expand() {
        this.table.rowDetail.collapseAllRows();
        for (var i = 0; i < this.rows.length; i++) {
            if (this.expanded.indexOf(this.rows[i].id) > -1) {
                this.table.rowDetail.toggleExpandRow(this.rows[i])
            }
        }
    }
    updateFilter() {
        this.rows = [];
        this.loadingIndicator = true;
        this.cinemaService.getView(this.filter)
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
    }
}
