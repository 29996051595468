import {Component, OnInit} from '@angular/core';
import {Distributor} from 'app/_models/distributor';
import {DistributorService} from 'app/_services/distributor.service';
import {ToastrService} from 'ngx-toastr';
import {DistributorEditComponent} from './distributor-edit/distributor-edit.component';
import {DistributorAddComponent} from './distributor-add/distributor-add.component';
import {MatDialog} from '@angular/material';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-distributors',
    templateUrl: './distributors.component.html',
    styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit {

    rows: Distributor[] = [];
    loadingIndicator = true;
    reorderable = true;
    api_url = environment.apiUrl;
    constructor(
        public dialog: MatDialog,
        private distributorService: DistributorService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.distributorService.getAll()
            .subscribe(data => {
                this.rows = data.data;
                this.loadingIndicator = false;
            });
    }

    deleteDistributor(distributor: number): void {
        this.distributorService.delete(distributor)
            .subscribe(
                data => {
                    this.rows = this.rows.filter(u => u.id !== distributor);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                })
    };

    editDistributor(distributor: Distributor): void {
        localStorage.removeItem("editDistributorId");
        localStorage.setItem("editDistributorId", distributor.toString());
        let distributorEditDialog = this.dialog.open(DistributorEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        distributorEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(distributor)) {
                        let rows = [...this.rows];
                        rows[i] = result.data;
                        this.rows = rows;
                    }
                }
            }
        });
    };

    addDistributor(): void {
        let distributorAddDialog = this.dialog.open(DistributorAddComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        distributorAddDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                let rows = [...this.rows];
                rows.push(result.data);
                this.rows = rows;
            }
        });
    };

}
