import {Component, OnInit, ViewChild} from '@angular/core';
import {Movie} from 'app/_models/movie';
import {MovieService} from 'app/_services/movie.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
import {MatDialog} from '@angular/material';
import {CinemaService} from '../../../_services/cinema.service';
import {MultiplexService} from '../../../_services/multiplex.service';
import {ListsService} from '../../../_services/lists.service';
import {Page} from '../../../_models/page';
import {MovieReleaseService} from '../../../_services/movie_release.service';
import {CinemaHideMultiplexInfoPipe} from '../../../_pipes/cinema_hide_multiplex_info.pipe';
import {MovieKdmService} from 'app/_services/movie_kdm.service';

const API_URL = environment.apiUrl;

@Component({
    selector: 'fuse-user-movies',
    templateUrl: './user-movies.component.html',
    styleUrls: ['./user-movies.component.scss']
})

export class UserMoviesComponent implements OnInit {

    @ViewChild('myTable') table: any;
    title = 'moje filmy';
    context = '';
    rows: Movie[] = [];
    temp: any = [];
    expanded: any[] = [];
    loadingIndicator = true;
    reorderable = true;
    api_url = API_URL;
    active = 1;
    user: any;
    cinema: string = null;
    cinemas: any[] = [];
    selectedCinemas: any[] = this.cinemas;
    movie: string = null;
    movies: any[] = [];
    selectedMovies: any[] = this.cinemas;
    searchInputCinema: string = null;
    searchInputMovie: string = null;
    page = new Page();
    sort: any = null;
    filter: any = null;

    constructor(
        private movieKdmService: MovieKdmService,
        public dialog: MatDialog,
        private http: Http,
        private router: Router,
        private movieService: MovieService,
        private toastr: ToastrService,
        private cinemaService: CinemaService,
        private multiplexService: MultiplexService,
        private listsService: ListsService,
        private movieReleaseService: MovieReleaseService
    ) {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.user = user;

        if (user.role_id === 2) {
            this.cinemaService.getMyCinema()
                .subscribe(data => {
                    this.context = (new CinemaHideMultiplexInfoPipe()).transform(data.data, false);
                });
        }
        else if (user.role_id === 4) {
            this.multiplexService.getMyMultiplex()
                .subscribe(data => {
                    this.context = 'Grupa ' + data.data.name;
                });
        }

        this.listsService.cinema()
            .subscribe(data => {
                this.cinemas = data.data;
                this.selectedCinemas = this.cinemas;
            });
        this.listsService.movie()
            .subscribe(data => {
                this.movies = data.data;
                this.selectedMovies = this.movies;
            });

        this.page.pageNumber = 0;
        this.page.size = 25;
    }

    ngOnInit() {
        this.filterTable();
    }

    setPage(pageInfo: any) {
        this.loadingIndicator = true;
        this.rows = [];
        this.page.pageNumber = pageInfo.offset;
        this.movieService.getAll(this.page, this.sort, this.filter)
            .subscribe(data => {
                this.page = data.paging;
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
    }

    expand() {
        this.table.rowDetail.collapseAllRows();
        for (let i = 0; i < this.rows.length; i++) {
            if (this.expanded.indexOf(this.rows[i].id) > -1) {
                this.table.rowDetail.toggleExpandRow(this.rows[i]);
            }
        }
    }

    toggleExpandRow(row: any) {
        const index = this.rows.indexOf(row);
        if (this.temp[index].cinemas_movie_release_full === undefined) {
            this.movieReleaseService.getViewId(row.id, row.release_id)
                .subscribe(data => {
                    (<any> this.rows[index]).cinemas_movie_release_full = data.data;
                    if (this.expanded.includes(row.id)) {
                        this.expanded.splice(this.expanded.indexOf(row.id), 1);
                    } else {
                        this.expanded.push(row.id);
                    }
                    this.table.rowDetail.toggleExpandRow(row);
                });
        }
        else {
            if (this.expanded.includes(row.id)) {
                this.expanded.splice(this.expanded.indexOf(row.id), 1);
            }
            else {
                this.expanded.push(row.id);
            }
            this.table.rowDetail.toggleExpandRow(row);
        }
    }

    downloadFile(package_id: number, movie_release_id: number) {
        this.cinemaService.downloadPackage(package_id, movie_release_id)
            .subscribe(data => {
                const url = this.api_url + '/' + data.data;
                const filename = url.split('/').pop();
                return this.http
                    .get(url, {
                        responseType: ResponseContentType.Blob,
                    })
                    .map(res => {
                        return {
                            filename: filename,
                            data: res.blob()
                        };
                    })
                    .subscribe(res => {
                        const _url = window.URL.createObjectURL(res.data);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = _url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(_url);
                        a.remove(); // remove the element
                    }, error => {
                        this.toastr.error(error.error.message, 'Błąd');
                    }, () => {
                    });
            });
    }

    filterTable() {
        this.filter = {
            cinema_id: null,
            movie_id: null,
            active: 0
        };

        if (this.active) {
            this.filter.active = this.active;
        }
        if (this.cinema) {
            this.filter.cinema_id = this.cinema;
        }
        if (this.movie) {
            this.filter.movie_id = this.movie;
        }

        this.setPage({offset: 0});
    }

    sortTable(data: any) {
        const prop = data.sorts[1].prop,
            dir = data.sorts[1].dir;
        console.log(prop);
        console.log(dir);
        this.sort = {};
        this.sort[prop] = dir;

        this.setPage({offset: 0});
    }

    searchCinema(query: string) {
        const result = this.selectCinema(query);
        this.selectedCinemas = result;
    }

    selectCinema(query: string): string[] {
        const result: string[] = [];
        // tslint:disable-next-line:prefer-const
        for (let a of this.cinemas) {
            if (a.full_name.toLowerCase().indexOf(query) > -1) {
                result.push(a);
            }
        }
        return result;
    }

    cleanSearchInputCinema() {
        this.searchInputCinema = '';
        this.selectedCinemas = this.cinemas;
    }

    searchMovie(query: string) {
        const result = this.selectMovie(query);
        this.selectedMovies = result;
    }

    selectMovie(query: string): string[] {
        const result: string[] = [];
        // tslint:disable-next-line:prefer-const
        for (let a of this.movies) {
            if (a.name.toLowerCase().indexOf(query) > -1) {
                result.push(a);
            }
        }
        return result;
    }

    cleanSearchInputMovie() {
        this.searchInputMovie = '';
        this.selectedMovies = this.movies;
    }

    getRowClass(row: any) {
        if (row.invalid === 2) {
            return 'error-row';
        }
        else if (row.invalid === 1) {
            return 'warning-row';
        }
        return '';
    }

    sendAgain(message_id: any) {
        this.movieKdmService.sendAgain(message_id)
            .subscribe(data => {
                this.toastr.success('Wiadomość wysłana', data.data);
            });
    }
}
