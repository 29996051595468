import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SettingsService} from 'app/_services/settings.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'fuse-quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseQuickPanelComponent implements OnInit {
    date: Date;
    settings: any;
    development: any = false;
    currentUser: any = false;
    version = environment.version;
    constructor(
        private settingsService: SettingsService,

    ) {
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            this.settingsService.getAll()
                .subscribe(data => {
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i].option == 'development') {
                            this.development = data.data[i].value;
                        }
                    }
                });
        }
    }

}
