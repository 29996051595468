import {Injectable} from '@angular/core';
import {KdmService} from 'app/_services/kdm.service';
import {of as ofObservable, BehaviorSubject} from 'rxjs';

export class MoviesReleasesItemNode {
    children: MoviesReleasesItemNode[];
    date_time_from: Date;
    date_time_to: Date;
    movie_id: string;
    item: string;
    id: string;
}

export class MoviesReleasesItemFlatNode {
    item: string;
    date_time_from: Date;
    date_time_to: Date;
    level: number;
    movie_id: string;
    expandable: boolean;
    id: string;
}

@Injectable()
export class KdmCinemaRoomDatabase {
    dataChangeMoviesReleases: BehaviorSubject<MoviesReleasesItemNode[]> = new BehaviorSubject<MoviesReleasesItemNode[]>([]);
    get data(): MoviesReleasesItemNode[] {return this.dataChangeMoviesReleases.value;}
    constructor(
        private kdmService: KdmService
    ) {
        this.initialize();
    }

    initialize() {
    }

    buildFileTree(value: any, level: number) {
        let data: any[] = [];
        for (let k in value) {
            let v = value[k];
            let node = new MoviesReleasesItemNode();
            node.item = `${k}`;
            if (typeof v.id !== undefined) {
                node.id = `${v.id}`;
            } else {
                node.id = v;
            }
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v.childrens === 'object') {
                node.children = this.buildFileTree(v.childrens, level + 1);
            } else {
                node.date_time_to = v.date_time_to;
                node.date_time_from = v.date_time_from;
                node.movie_id = v.movie_id;
                node.item = v.name;
                node.id = k;
            }
            data.push(node);
        }
        return data;
    }
    insertItem(parent: MoviesReleasesItemNode, name: string) {
        const child = <MoviesReleasesItemNode> {item: name};
        if (parent.children) {
            parent.children.push(child);
            this.dataChangeMoviesReleases.next(this.data);
        }
    }

    updateItem(node: MoviesReleasesItemNode, name: string) {
        node.item = name;
        this.dataChangeMoviesReleases.next(this.data);
    }
    generateCinemaRoomTree(cinema_room: any) {
        this.dataChangeMoviesReleases = new BehaviorSubject<MoviesReleasesItemNode[]>([]);
        this.kdmService.getCurrentMoviesCinemaRoom(cinema_room)
            .subscribe(data => {
                const to_build: any = [];
                for (var _i = 0; _i < data.data.length; _i++) {
                    if (data.data[_i].movie_releases.length > 0) {
                        const childrens: any = [];
                        for (var _j = 0; _j < data.data[_i].movie_releases.length; _j++) {
                            childrens[data.data[_i].movie_releases[_j].id] = {
                                'name': data.data[_i].movie_releases[_j].name,
                                'date_time_from': data.data[_i].movie_releases[_j].kdm_date_from,
                                'date_time_to': data.data[_i].movie_releases[_j].kdm_date_to,
                                'movie_id': data.data[_i].movie_releases[_j].movie_id
                            };
                        }
                        to_build[data.data[_i].name] = {'id': data.data[_i].id, 'childrens': childrens};
                    }
                }
                let xdata = this.buildFileTree(to_build, 0);
                this.dataChangeMoviesReleases.next(xdata);
            });
        return this.dataChangeMoviesReleases;
    }
}