import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { MovieService } from 'app/_services/movie.service';
import { Router } from '@angular/router';
import { ReleaseType } from 'app/_models/release_type';
import { ToastrService } from 'ngx-toastr';
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { fuseAnimations } from '@fuse/animations';
import { ListsService } from '../../../../_services/lists.service';
import { MatSelectChange } from '@angular/material';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'YYYY-MM-DD HH:mm',
  fullPickerInput: 'YYYY-MM-DD HH:mm',
  datePickerInput: 'YYYY-MM-DD',
  timePickerInput: 'YYYY-MM-DD HH:mm',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'YYYY-MM-DD',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'fuse-distributor-movie-add',
  templateUrl: './distributor-movie-add.component.html',
  styleUrls: ['./distributor-movie-add.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'pl' },
  ],
  animations: fuseAnimations
})
export class DistributorMovieAddComponent implements OnInit {

  @ViewChild('coverWrapper') coverBox: ElementRef;
  @ViewChild('releasesWrapper') releasesBox: ElementRef;
  @ViewChild('coverHandle') coverImg: ElementRef;
  @ViewChildren('multiplexDateStart') multiplexDateStarts: QueryList<ElementRef>;
  @ViewChildren('multiplexDateEnd') multiplexDateEnds: QueryList<ElementRef>;
  @ViewChildren('othersDateStart') othersDateStarts: QueryList<ElementRef>;
  @ViewChildren('othersDateEnd') othersDateEnds: QueryList<ElementRef>;

  customBoxAutoHideTime = 300;
  dayPremiereOffset = 30;
  releaseTypes: ReleaseType[] = [];
  addFormMovie: FormGroup;
  formErrorsMovie: any;
  fileImg: any = '';
  custom = 0;
  custom_type = '';
  movieName = '';
  ite = 0;
  cover: any = null;
  customBoxTimeout: any = null;
  periods = [
    {
      label: 'Dni',
      value: 'days'
    },
    {
      label: 'Tygodnie',
      value: 'weeks'
    },
    {
      label: 'Miesiące',
      value: 'months'
    },
  ];
  premiereOldValue = this.minPremiereDate();
  submitted = false;
  sended = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private movieService: MovieService,
    private listsService: ListsService,
    private toastr: ToastrService
  ) {
    this.formErrorsMovie = {
      name: {},
      premiere: {},
      img: {},
      release_types: {},
      custom: {},
      time_windows: {},
      multiplex_periods: {},
      others_periods: {},
      test_days: {}
    };
  }

  ngOnInit() {
    this.addFormMovie = this.formBuilder.group({
      id: [],
      name: ['',
        [
          Validators.required,
          Validators.maxLength(500),
        ]
      ],
      premiere: [this.minPremiereDate(), [
        Validators.required,
      ]],
      img: [''],
      custom: [false],
      time_windows: new FormGroup({
        multiplex_time_window: new FormControl(true),
        others_time_window: new FormControl(false)
      }, this.minSelectedCheckboxesInGroup(1)),
      multiplex_periods: new FormArray([this.getWindowGroup()], this.windowsValidation()),
      others_periods: new FormArray([this.getWindowGroup()], this.windowsValidation()),
      test_days: new FormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(14)
      ])
    });

    this.listsService.releaseTypes()
      .subscribe(data => {
        this.releaseTypes = data.data;

        const controls = this.releaseTypes.map((type, i) => new FormControl(i === 2));
        this.addFormMovie.addControl('release_types', new FormArray(controls, this.minSelectedCheckboxes(1)));
      });

    this.addFormMovie.valueChanges.subscribe(() => {
      this.onFormValuesChangedMovie();
    });
  }

  getWindowGroup(): FormGroup {
    const baseDay = new moment(moment.max(this.minPremiereDate(), this.premiereOldValue));

    return new FormGroup({
      count: new FormControl(2, [
        Validators.required,
        Validators.min(1),
        Validators.max(30)
      ]),
      period: new FormControl('weeks', [
        Validators.required
      ]),
      date_start: new FormControl(baseDay, [
        Validators.required
      ]),
      date_end: new FormControl(moment(baseDay).add(2, 'weeks').startOf('day'), [
        Validators.required
      ])
    });
  }

  addNewMultiplexTimeWindow() {
    (<FormArray>this.addFormMovie.get('multiplex_periods')).push(this.getWindowGroup());
  }

  addNewOthersTimeWindow() {
    (<FormArray>this.addFormMovie.get('others_periods')).push(this.getWindowGroup());
  }

  removeMultiplexTimeWindow(event: any, item: FormGroup, i: number) {
    (<FormArray>this.addFormMovie.get('multiplex_periods')).removeAt(i);
  }

  removeOthersTimeWindow(event: any, item: FormGroup, i: number) {
    (<FormArray>this.addFormMovie.get('others_periods')).removeAt(i);
  }

  addCustomType(event: any, hide = true) {
    const value = this.custom_type.trim();
    if (value) {
      const item = new ReleaseType();
      item.name = value;
      this.releaseTypes.push(item);
      (<FormArray>this.addFormMovie.controls.release_types).push(new FormControl(true));
      this.custom_type = '';
      this.clearCustomTypeTimeout();
      if (hide) {
        this.customBoxTimeout = setTimeout(() => {
          this.custom = 0;
        }, this.customBoxAutoHideTime);
      }
    }
  }

  clearCustomTypeTimeout() {
    if (this.customBoxTimeout) {
      clearTimeout(this.customBoxTimeout);
    }
  }

  onFormValuesChangedMovie() {
    for (const field in this.formErrorsMovie) {
      if (!this.formErrorsMovie.hasOwnProperty(field)) {
        continue;
      }
      this.formErrorsMovie[field] = {};
      const control = this.addFormMovie.get(field);
      if (control && !control.valid) {
        this.formErrorsMovie[field] = control.errors;
      }
    }
    // console.log(this.addFormMovie.value, this.formErrorsMovie);
  }

  onSubmit() {
    this.submitted = true;
    this.onFormValuesChangedMovie();
    if (this.addFormMovie.invalid) {
      return;
    }

    const values = this.addFormMovie.value;
    if (this.fileImg !== undefined) {
      values.img = this.fileImg.name;
      values.img_result = this.fileImg.result;
      values.img_type = this.fileImg.type;
    }

    for (let i = 0; i < values.others_periods.length; i++) {
      values.others_periods[i].date_from = values.others_periods[i].date_start.format('YYYY-MM-DD');
      values.others_periods[i].date_to = values.others_periods[i].date_end.format('YYYY-MM-DD');
    }

    for (let i = 0; i < values.multiplex_periods.length; i++) {
      values.multiplex_periods[i].date_from = values.multiplex_periods[i].date_start.format('YYYY-MM-DD');
      values.multiplex_periods[i].date_to = values.multiplex_periods[i].date_end.format('YYYY-MM-DD');
    }

    values.premiere_date = values.premiere.format('YYYY-MM-DD');

    values.releases = [];
    for (let i = 0; i < values.release_types.length; i++) {
      if (values.release_types[i]) {
        values.releases.push(
          this.releaseTypes[i]
        );
      }
    }

    this.sended = true;
    this.movieService.movieProposal(values)
        .subscribe(
            data => {
                this.toastr.success('Zmiany zostały zapisane.', 'Sukces');
                this.router.navigate(['/distributor_movies']);
            },
            error => {
                this.toastr.error(error.error.message, 'Błąd');
                this.sended = false;
            });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  minSelectedCheckboxesInGroup(min = 1) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      const totalSelected = Object.keys(formGroup.controls).map(control => formGroup.controls[control].value).reduce((prev, next) => next ? prev + next : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  windowsValidation() {
    const validator: ValidatorFn = (formArray: FormArray) => {

      const invalid = formArray.controls.map((item, index) => {
        const dateStart = moment(item.get('date_start').value),
          dateEnd = moment(item.get('date_end').value);
        return dateStart.isSameOrBefore(dateEnd, 'day') ? false : index;
      }).filter(value => value !== false);

      if (invalid.length !== 0) {
        return { daterange: invalid };
      }

      for (let i = 0; i < formArray.length; i++) {
        const valueA: AbstractControl = formArray.at(i),
          dateStartA = new moment(valueA.get('date_start').value),
          dateEndA = new moment(valueA.get('date_end').value);

        for (let j = i + 1; j < formArray.length; j++) {
          const valueB: AbstractControl = formArray.at(j),
            dateStartB = new moment(valueB.get('date_start').value),
            dateEndB = new moment(valueB.get('date_end').value);

          if (dateStartA.isSameOrAfter(dateStartB, 'day') && dateStartA.isSameOrBefore(dateEndB, 'day')) {
            return { conflict: true };
          }

          if (dateEndA.isSameOrAfter(dateStartB, 'day') && dateStartA.isSameOrBefore(dateEndB, 'day')) {
            return { conflict: true };
          }

          if (dateStartB.isSameOrAfter(dateStartA, 'day') && dateStartB.isSameOrBefore(dateEndA, 'day')) {
            return { conflict: true };
          }

          if (dateEndB.isSameOrAfter(dateStartA, 'day') && dateStartB.isSameOrBefore(dateEndA, 'day')) {
            return { conflict: true };
          }
        }
      }

      return null;
    };

    return validator;
  }

  coverLoaded() {
    (<HTMLElement>(this.releasesBox.nativeElement)).style.marginTop = -((<HTMLElement>(this.coverBox.nativeElement)).clientHeight - 88) + 'px';
  }

  openCover() {
    const w = window.open('');
    w.document.write(this.coverImg.nativeElement.outerHTML);
  }

  handleFileInput(event: any, what: string) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (what === 'img' && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
          this.cover = reader.result;
        }
        const filex: Object = {
          ite: this.ite,
          name: file.name,
          type: file.type,
          result: (<string>(reader.result)).split(',')[1]
        };
        if (what === 'img') {
          if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            this.fileImg = filex;
          } else {
            this.toastr.error('błędny format wymagany jpg lub png', 'Błąd');
          }
        }
        this.ite++;
      };
    }
  }

  othersCheckboxChange(event: any) {
    (<FormGroup>this.addFormMovie.controls.time_windows).controls.others_time_window.setValue(event.checked);
    setTimeout(() => { this.mainDateChanged(); }, 500);
  }

  multiplexCheckboxChange(event: any) {
    (<FormGroup>this.addFormMovie.controls.time_windows).controls.multiplex_time_window.setValue(event.checked);
    setTimeout(() => { this.mainDateChanged(); }, 500);
  }

  minPremiereDate() {
    return new moment().add(this.dayPremiereOffset, 'days').startOf('day');
  }

  mainDateChanged() {
    const baseDay = this.minPremiereDate();
    const value = new moment(this.addFormMovie.controls.premiere.value);

    if (baseDay.isAfter(value)) {
      // invalid premiere date  
      return true;
    }

    (<FormArray>(this.addFormMovie.get('multiplex_periods'))).controls.forEach((element, index) => {
      const start = new moment(element.get('date_start').value),
        end = new moment(element.get('date_end').value),
        tmp = this.multiplexDateStarts.toArray()[index];

      if (start.isSame(this.premiereOldValue, 'day')) {
        element.get('date_start').setValue(value.startOf('day'));

        if (moment(start).add(element.get('count').value, element.get('period').value).isSame(end, 'day')) {
          element.get('date_end').setValue(moment(value).add(element.get('count').value, element.get('period').value).startOf('day'));
        }
      }

      if (!tmp) {
        return true;
      }

      const row = tmp.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.children;

      this.periodDatesChanged(<FormGroup>element, row, value);
    });

    (<FormArray>(this.addFormMovie.get('others_periods'))).controls.forEach((element, index) => {
      const start = new moment(element.get('date_start').value),
        end = new moment(element.get('date_end').value),
        tmp = this.othersDateStarts.toArray()[index];

      if (start.isSame(this.premiereOldValue, 'day')) {
        element.get('date_start').setValue(value.startOf('day'));

        if (moment(start).add(element.get('count').value, element.get('period').value).isSame(end, 'day')) {
          element.get('date_end').setValue(moment(value).add(element.get('count').value, element.get('period').value).startOf('day'));
        }
      }
      
      if (!tmp) {
        return true;
      }

      const row = tmp.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.children;

      this.periodDatesChanged(<FormGroup>element, row, value);
    });

    this.premiereOldValue = value;
  }

  lengthDateChanged(event: any, item: FormGroup) {
    const value = new moment(item.controls.date_start.value),
      row = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.children;
    item.get('date_end').setValue(value.add(item.get('count').value, item.get('period').value).startOf('day'));
    this.periodDatesChanged(item, row);
  }

  periodDateChanged(event: MatSelectChange, item: FormGroup) {
    const value = new moment(item.controls.date_start.value),
      row = event.source._elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.children;
    item.get('date_end').setValue(value.add(item.get('count').value, event.value).startOf('day'));
    this.periodDatesChanged(item, row);
  }

  periodStartDateChanged(event: any, item: FormGroup) {
    const row = event.input.parentElement.parentElement.parentElement.parentElement.parentElement.children;
    this.periodDatesChanged(item, row);
  }

  periodEndDateChanged(event: any, item: FormGroup) {
    const row = event.input.parentElement.parentElement.parentElement.parentElement.parentElement.children;
    this.periodDatesChanged(item, row);
  }

  periodDatesChanged(item: FormGroup, items: any, premiereValue = this.premiereOldValue) {
    const start = new moment(item.get('date_start').value),
      end = new moment(item.get('date_end').value)/* ,
        items = this.multiplexDateStarts.toArray()[(<FormArray>item.parent).controls.indexOf(item)]
            .nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.children */;
    let opacity = 0;

    if (moment(start).add(item.get('count').value, item.get('period').value).isSame(end, 'day')) {
      opacity = 1;
    }
    else {
      opacity = 0.3;
    }

    items[0].style.opacity = opacity;
    items[1].style.opacity = opacity;

    if (start.isSame(premiereValue, 'day')) {
      items[2].style.opacity = opacity * 1;
    }
    else {
      items[2].style.opacity = opacity * 0;
    }
  }
}
