import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from "@angular/forms";
import {CinemaService} from 'app/_services/cinema.service';
import {Router} from "@angular/router";
import {Multiplex} from 'app/_models/multiplex';
import {CinemaRoom} from 'app/_models/cinema_room';
import {MultiplexService} from 'app/_services/multiplex.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-cinema-add',
    templateUrl: './cinema-add.component.html',
    styleUrls: ['./cinema-add.component.scss']
})
export class CinemaAddComponent implements OnInit {

    multiplexes: Multiplex[] = [];
    addFormCinema: FormGroup;
    addFormCinemaRooms: FormGroup;
    formErrorsCinema: any;
    formErrorsCinemaRooms: any;
    fileToUpload: any[] = [];
    ite = 0;
    mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private cinemaService: CinemaService,
        private multiplexService: MultiplexService,
        private toastr: ToastrService
    ) {
        this.formErrorsCinema = {
            name: {},
            address: {},
            postcode: {},
            city: {},
            email: {},
            multiplex_id: {},
        };
        this.formErrorsCinemaRooms = {
            name: {},
            certificate_path: {},
            cert_control: {},
        };
    }


    ngOnInit() {
        this.multiplexService.getAll()
            .subscribe(data => {
                this.multiplexes = data.data;
            });
        this.addFormCinema = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.maxLength(500),
            ]],
            address: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            postcode: ['',
                [Validators.pattern('\\d{2}-\\d{3}')]
            ],
            city: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            email: ['', [
                Validators.required,
                Validators.maxLength(500),
            ]],
            multiplex_id: ['', Validators.required],
        });
        this.addFormCinemaRooms = this.formBuilder.group({
            cinemaRooms: this.formBuilder.array([]),
        });
        this.addFormCinema.valueChanges.subscribe(() => {
            this.onFormValuesChangedCinema();
        });
        this.addFormCinemaRooms.valueChanges.subscribe(() => {
            this.onFormValuesChangedCinemaRooms();
        });

    }

    get cinemaRooms(): FormArray {
        return this.addFormCinemaRooms.get('cinemaRooms') as FormArray;
    };

    addLair() {
        this.cinemaRooms.push(this.formBuilder.group(new CinemaRoom()));
    }

    removeLair(i: any) {
        this.cinemaRooms.removeAt(i);
    }

    finishCinemaStepper() {
        this.onFormValuesChangedCinema();
        this.onFormValuesChangedCinemaRooms();
        if (this.addFormCinema.invalid) {
            return;
        }
        if (this.addFormCinemaRooms.invalid) {
            return;
        }
        const newCinema = this.addFormCinema.value;
        const roomsDeepCopy: CinemaRoom[] = this.addFormCinemaRooms.value.cinemaRooms.map(
            (rooms: CinemaRoom) => Object.assign({}, rooms));
        for (const room in roomsDeepCopy) {
            if (this.fileToUpload[room] != undefined) {
                roomsDeepCopy[room].certificate_path = this.fileToUpload[room].name;
                roomsDeepCopy[room].certificate_result = this.fileToUpload[room].result;
                roomsDeepCopy[room].certificate_type = this.fileToUpload[room].type;
                roomsDeepCopy[room].active = true;
            }
        }
        newCinema.rooms = roomsDeepCopy;
        this.cinemaService.create(newCinema)
            .subscribe(data => {
                this.cinemaService.locate(data.data).subscribe(data => {});
                this.router.navigate(['cinemas']);
            },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChangedCinema() {
        for (const field in this.formErrorsCinema) {
            if (!this.formErrorsCinema.hasOwnProperty(field)) {
                continue;
            }
            this.formErrorsCinema[field] = {};
            const control = this.addFormCinema.get(field);
            if (control && !control.valid) {
                this.formErrorsCinema[field] = control.errors;
            }
        }
    }

    onFormValuesChangedCinemaRooms() {
        for (const field in this.formErrorsCinemaRooms) {
            if (!this.formErrorsCinemaRooms.hasOwnProperty(field)) {
                continue;
            }
            this.formErrorsCinemaRooms[field] = {};
            const control = this.addFormCinemaRooms.get(field);
            if (control && !control.valid) {
                this.formErrorsCinemaRooms[field] = control.errors;
            }
        }
    }
    handleFileInput(event: any, i: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                let filex: Object = {
                    ite: this.ite,
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                this.cinemaRooms.controls[i].patchValue({cert_control: file.name});
                this.fileToUpload.push(filex);
                this.ite++;
            };
        }
    }
}
