import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CinemaService } from '../../../../_services/cinema.service';
import { SEMICOLON, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { CinemaHideMultiplexInfoPipe } from '../../../../_pipes/cinema_hide_multiplex_info.pipe';
@Component({
  selector: 'fuse-app-user-cinema-address',
  templateUrl: './user-cinema-address.component.html',
  styleUrls: ['./user-cinema-address.component.scss']
})
export class UserCinemaAddressComponent implements OnInit {

  @ViewChild('emails') emailsInput: ElementRef;
  changeCinemaDetails: FormGroup;
  cinema = '';
  submitted = false;
  readonly separatorKeysCodes: number[] = [ SEMICOLON, ENTER ];
  emailList: string[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  returnUrl: string;
  formErrors: any;

  constructor(
    private formBuilder: FormBuilder,
    private cinemaService: CinemaService,
    private toastr: ToastrService
  ) {
    this.formErrors = {
      address: {},
      city: {},
      postcode: {},
      email: {},
    };
  }

  ngOnInit() {
    this.changeCinemaDetails = this.formBuilder.group({
      address: ['', [
        Validators.required,
        Validators.maxLength(500),
      ]],
      postcode: ['', [
        Validators.required,
        Validators.pattern('\\d{2}-\\d{3}')
      ]],
      city: ['', [
        Validators.required,
        Validators.maxLength(500),
      ]],
      email: ['']
    });

    this.changeCinemaDetails.valueChanges
      .subscribe(() => {
        this.onFormValuesChanged();
        this.validateEmails();
      });
    this.cinemaService.getMyCinema()
      .subscribe(data => {
        const values = {
          address: data.data.address,
          postcode: data.data.postcode,
          city: data.data.city,
          email: ''
        };

        this.cinema = (new CinemaHideMultiplexInfoPipe()).transform(data.data, false);
        this.emailList = data.data.email.split(';');
        this.changeCinemaDetails.setValue(values);
        // this.validateEmails();
      });
  }

  get f() {
    return this.changeCinemaDetails.controls;
  }

  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    email = String(email).trim().toLowerCase();
    if (email.length === 0 || re.test(email)) {
      return true;
    }

    this.formErrors.email['email'] = true;
    this.changeCinemaDetails.get('email').setErrors({ email: true/* , required: false, maxlength: false */ });

    return false;
  }

  onSubmit() {
    this.onFormValuesChanged();
    this.submitted = true;
    if (this.changeCinemaDetails.invalid || !this.validateEmails()) {
      return;
    }

    const values = this.changeCinemaDetails.value;
    values.email = this.emailList.join(';');
    this.cinemaService.updateMyCinema(values)
        .subscribe(
            data => {
                this.cinemaService.locate(data.data.id).subscribe(_data => {});
                
                this.toastr.success('Zmiany zostały zapisane.', 'Sukces');

                this.changeCinemaDetails.markAsPristine();
            },
            error => {
                this.toastr.error(error.error.message, 'Błąd');
            });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.changeCinemaDetails.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  validateEmails(): boolean {
    if (this.emailList.length === 0) {
      this.formErrors['email']['required'] = true;
      this.changeCinemaDetails.get('email').setErrors({ required: true/* , email: false, maxlength: false */ });
      return false;
    }

    if (this.emailList.join(';').length > 500) {
      this.formErrors['email']['maxlength'] = true;
      this.changeCinemaDetails.get('email').setErrors({ maxlength: true/* , required: false, email: false */ });
      return false;
    }

    for (let i = 0; i < this.emailList.length; i++) {
      if (!this.validateEmail(this.emailList[i])) {
        return false;
      }
    }

    this.formErrors['email']['required'] = false;
    this.formErrors['email']['email'] = false;
    this.formErrors['email']['maxlength'] = false;
    this.changeCinemaDetails.get('email').setErrors(null);

    return true;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.validateEmail(value)) {
      this.formErrors['email']['email'] = true;
      this.changeCinemaDetails.get('email').setErrors({ required: true/* , email: false, maxlength: false */ });
      return;
    }

    // Add our fruit
    if ((value || '').trim()) {
      this.emailList.push(value.trim());

      this.changeCinemaDetails.markAsDirty();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.validateEmails();
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);

      this.changeCinemaDetails.markAsDirty();
    }

    this.validateEmails();
  }

  getSubmitColor(): string {
    if (this.changeCinemaDetails.dirty) {
      return 'red-bg';
    }

    return 'accent';
  }
}

