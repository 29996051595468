export class CinemaChart {
    values: any = [];
    legend: any = false;
    all: any = null;
    explodeSlices: any = false;
    labels: any = true;
    doughnut: any = false;
    gradient: any = false;
    scheme: any = {
        domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63', '#ffc107']
    };
}
