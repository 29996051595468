import {Component, OnInit} from '@angular/core';
import {Multiplex} from 'app/_models/multiplex';
import {MultiplexService} from 'app/_services/multiplex.service';
import {ToastrService} from 'ngx-toastr';
import {MultiplexAddComponent} from './multiplex-add/multiplex-add.component';
import {MultiplexEditComponent} from './multiplex-edit/multiplex-edit.component';
import {MatDialog} from '@angular/material';
@Component({
    selector: 'app-multiplexes',
    templateUrl: './multiplexes.component.html',
    styleUrls: ['./multiplexes.component.scss']
})
export class MultiplexesComponent implements OnInit {

    rows: Multiplex[] = [];
    loadingIndicator = true;
    reorderable = true;
    constructor(
        public dialog: MatDialog,
        private multiplexService: MultiplexService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.multiplexService.getAll()
            .subscribe(data => {
                this.rows = data.data;
                this.loadingIndicator = false;
            });
    }

    deleteMultiplex(multiplex: number): void {
        this.multiplexService.delete(multiplex)
            .subscribe(
                data => {
                    this.rows = this.rows.filter(u => u.id !== multiplex);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                })
    };

    editMultiplex(multiplex: Multiplex): void {
        localStorage.removeItem("editMultiplexId");
        localStorage.setItem("editMultiplexId", multiplex.toString());
        let multiplexEditDialog = this.dialog.open(MultiplexEditComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        multiplexEditDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].id == Number(multiplex)) {
                        let rows = [...this.rows];
                        rows[i] = result.data;
                        this.rows = rows;
                    }
                }
            }
        });
    };

    addMultiplex(): void {
        let multiplexAddDialog = this.dialog.open(MultiplexAddComponent, {
            width: '500px',
            data: {name: this.reorderable}
        });

        multiplexAddDialog.afterClosed().subscribe(result => {
            if (this.rows.length > 0 && result != undefined && result.data != undefined) {
                let rows = [...this.rows];
                rows.push(result.data);
                this.rows = rows;
            }
        });
    };
}
