import {Component, OnInit, Inject} from '@angular/core';
import {DistributorService} from 'app/_services/distributor.service';
import {Router} from "@angular/router";
import {Distributor} from 'app/_models/distributor';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-distributor-edit',
    templateUrl: './distributor-edit.component.html',
    styleUrls: ['./distributor-edit.component.scss']
})
export class DistributorEditComponent implements OnInit {

    distributor: Distributor;
    editForm: FormGroup;
    formErrors: any;
    mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
    fileImg: any = '';
    logo: any = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private distributorService: DistributorService,
        public dialogRef: MatDialogRef<DistributorEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            email: {},
            description: {},
            active: {},
            logo: {},
        };
    }


    ngOnInit() {
        let distributorId = localStorage.getItem("editDistributorId");
        if (!distributorId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['distributors']);
            return;
        }
        this.editForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            email: ['', [
                Validators.required,
            ]],
            contact_address: [''],
            contact_city: [''],
            contact_postcode: [''],
            billing_address: [''],
            billing_postcode: [''],
            billing_city: [''],
            nip: [''],
            description: [''],
            active: [''],
            created: [''],
            modified: [''],
            deleted: [''],
            movies_count: [''],
            logo: [''],
        });

        this.distributorService.getById(+distributorId)
            .subscribe(data => {
                this.editForm.setValue(data.data);
            });
        this.editForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editForm.invalid) {
            return;
        }
        const editDistributor = this.editForm.value;
        if (this.fileImg !== '') {
            editDistributor.logo = this.fileImg.name;
            editDistributor.logo_result = this.fileImg.result;
            editDistributor.logo_type = this.fileImg.type;
        }
        this.distributorService.update(editDistributor)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }
    handleFileInput(event: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.logo = reader.result;
                }
                let filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.fileImg = filex;
                } else {
                    this.toastr.error('błędny format wymagany jpg lub png', 'Błąd');
                }
            };
        }
    }
}
