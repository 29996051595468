import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostBinding, HostListener, Renderer2, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {CompleterService, RemoteData} from 'ng2-completer';
import {environment} from 'environments/environment';
import {KdmService} from 'app/_services/kdm.service';
import {MovieKdmService} from 'app/_services/movie_kdm.service';
import {MovieService} from 'app/_services/movie.service';
import {CinemaService} from 'app/_services/cinema.service';
import {LogService} from 'app/_services/log.service';
import {MessageService} from 'app/_services/message.service';
import {ChoosedRelease} from 'app/_models/choosed_release';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable, BehaviorSubject} from 'rxjs';
import {KdmDatabase, TodoItemFlatNode, TodoItemNode} from './kdm_database';
import {KdmDatabaseFinish, TodoItemFlatNodeFinish, TodoItemNodeFinish} from './kdm_database_finish';
import {CinemaDatabase, CinemaItemFlatNode, CinemaItemNode} from './cinema_database';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {style, animate, AnimationBuilder, AnimationPlayer} from '@angular/animations';
import {fuseAnimations} from '@fuse/animations';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {DOCUMENT} from '@angular/common';
import {MatStepper} from '@angular/material';
import {Subscription} from 'rxjs';
import {FuseConfigService} from '@fuse/services/config.service';
import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import {Http} from '@angular/http'
import 'rxjs/Rx';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD HH:mm',
    fullPickerInput: 'YYYY-MM-DD HH:mm',
    datePickerInput: 'YYYY-MM-DD HH:mm',
    timePickerInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'YYYY-MM-DD HH:mm',
    dateA11yLabel: 'YYYY-MM-DD HH:mm',
    monthYearA11yLabel: 'YYYY-MM-DD HH:mm',
};

// tslint:disable:no-inferrable-types

const API_URL = environment.apiUrl;
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-kdm',
    templateUrl: './kdm.component.html',
    styleUrls: ['./kdm.component.scss'],
    providers: [KdmDatabaseFinish, KdmDatabase, CinemaDatabase,
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    ],
    animations: fuseAnimations
})
export class KdmComponent implements OnInit, AfterViewInit {
    @ViewChild('openButton') openButton;
    @ViewChild('panel') panel;
    @ViewChild('xstepper') xstepper: MatStepper;
    @ViewChild('chooseMovie') chooseMovieInput: ElementRef;
    @ViewChild('chooseCinema') chooseCinemaInput: ElementRef;
    @HostBinding('class.bar-closed') barClosed: boolean;
    public player: AnimationPlayer;
    enableKeypress = false;
    currentStep: number = 0;
    editable: any = true;
    selectMovie: FormGroup;
    selectCinema: FormGroup;
    sendMessage: FormGroup;
    summary: FormGroup;
    generate_kdm: FormGroup;
    selectMovieErrors: any;
    selectCinemaErrors: any;
    dateFrom: any;
    dateTo: any;
    sendedMessages: any[];
    // AUTOCOMPLETE
    searchStr: string;
    protected dataService: RemoteData;
    temp: any[] = [];
    selectedMovieGroup: any;
    choosedRelease: ChoosedRelease = new ChoosedRelease;
    moviesFilter: string = '';
    // TREE
    flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();
    nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();
    treeControl: FlatTreeControl<TodoItemFlatNode>;
    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    selectedCinemas: any[];
    cinemaSearch: string;
    // TREE FINISH
    flatNodeMapFinish: Map<TodoItemFlatNodeFinish, TodoItemNodeFinish> = new Map<TodoItemFlatNodeFinish, TodoItemNodeFinish>();
    nestedNodeMapFinish: Map<TodoItemNodeFinish, TodoItemFlatNodeFinish> = new Map<TodoItemNodeFinish, TodoItemFlatNodeFinish>();
    treeControlFinish: FlatTreeControl<TodoItemFlatNodeFinish>;
    treeFlattenerFinish: MatTreeFlattener<TodoItemNodeFinish, TodoItemFlatNodeFinish>;
    dataSourceFinish: MatTreeFlatDataSource<TodoItemNodeFinish, TodoItemFlatNodeFinish>;
    checklistSelectionFinish = new SelectionModel<TodoItemFlatNodeFinish>(true /* multiple */);
    // TREE CINEMA
    flatNodeMapCinema: Map<CinemaItemFlatNode, CinemaItemNode> = new Map<CinemaItemFlatNode, CinemaItemNode>();
    nestedNodeMapCinema: Map<CinemaItemNode, CinemaItemFlatNode> = new Map<CinemaItemNode, CinemaItemFlatNode>();
    treeControlCinema: FlatTreeControl<CinemaItemFlatNode>;
    treeFlattenerCinema: MatTreeFlattener<CinemaItemNode, CinemaItemFlatNode>;
    dataSourceCinema: MatTreeFlatDataSource<CinemaItemNode, CinemaItemFlatNode>;
    cinemaListSelection = new SelectionModel<CinemaItemFlatNode>(true /* multiple */);

    selectedCinemaCopy: any[] = [];
    unselectedCinemaCopy: any[] = [];
    processing: any = false;
    generatedKdm = false;

    cinemasToSend: number = 0;
    generated_kdms_count: number = 0;
    rows: any;
    api_url = API_URL;
    hiddenModal: any = true;
    // -TREE
    _timeout: any = null;
    movie_kdms_list: any = [];
    finded: any = false;
    all_marked = false;

    onConfigChanged: Subscription;
    fuseSettings: any;
    movieSubscribe: any;
    cinemaSubscribe: any;
    @HostListener('document:keyup', ['$event']) handleDeleteKeyboardEvent = (event: KeyboardEvent) => {
        const predefinedKeys = ['Tab', 'Shift', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', ' '];
        const el = (<HTMLInputElement> event.target);
        if (this.enableKeypress && predefinedKeys.indexOf(event.key) === -1
            && ((<HTMLElement> event.target).nodeName !== 'INPUT' || el.type === 'checkbox') && !event.key.match(/^[0-9]$/g)) {
            this.chooseCinemaInput.nativeElement.focus();

            if ((el.type === 'checkbox' || this.chooseCinemaInput.nativeElement.value === '') && event.key.match(/^[a-zA-Z]$/g)) {
                this.chooseCinemaInput.nativeElement.value = event.key;
            }
            else {
                this.chooseCinemaInput.nativeElement.select();
            }
        }
    }

    constructor(
        private fuseConfig: FuseConfigService,
        @Inject(DOCUMENT) private document: any,
        private animationBuilder: AnimationBuilder,
        private renderer: Renderer2,

        private formBuilder: FormBuilder,
        private completerService: CompleterService,
        private kdmService: KdmService,
        private cinemaService: CinemaService,
        private messageService: MessageService,
        private movieKdmService: MovieKdmService,
        private router: Router,
        private movieService: MovieService,
        private logService: LogService,
        // TREE
        public database: KdmDatabase,
        public databaseFinish: KdmDatabaseFinish,
        public databaseCinema: CinemaDatabase,
        private toastr: ToastrService,
        // -TREE
        private sidebarService: FuseSidebarService,

    ) {
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.fuseSettings = newSettings;
                    }
                );
        this.fuseSettings.layout.lockedOpen = '';

        this.fuseConfig.setConfig(this.fuseSettings);
        this.barClosed = true;
        // TREE
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
        this.treeFlattenerCinema = new MatTreeFlattener(this.transformerCinema, this.getLevelCinema, this.isExpandableCinema, this.getChildrenCinema);
        this.treeControlCinema = new FlatTreeControl<CinemaItemFlatNode>(this.getLevelCinema, this.isExpandableCinema);
        this.dataSourceCinema = new MatTreeFlatDataSource(this.treeControlCinema, this.treeFlattenerCinema);
        this.databaseCinema.dataChangeCinema.subscribe(data => {
            this.dataSourceCinema.data = data;
        });
        this.treeFlattenerFinish = new MatTreeFlattener(this.transformerFinish, this.getLevelFinish, this.isExpandableFinish, this.getChildrenFinish);
        this.treeControlFinish = new FlatTreeControl<TodoItemFlatNodeFinish>(this.getLevelFinish, this.isExpandableFinish);
        this.dataSourceFinish = new MatTreeFlatDataSource(this.treeControlFinish, this.treeFlattenerFinish);
        this.databaseFinish.dataChange.subscribe(data => {
            this.dataSourceFinish.data = data;
        });
        // -TREE

        this.selectMovieErrors = {
            movie: {},
        };

        this.selectCinemaErrors = {
            address: {},
            date_from: {},
            date_to: {}
        };

    }

    ngOnInit() {
        this.movieService.getAllActiveFiltrated('')
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
            });
        this.selectMovie = this.formBuilder.group({
            radio: ['', Validators.required],
            movie: ['', Validators.required],
        });
        this.sendMessage = this.formBuilder.group({
            message: [''],
        });
        const todayMin = new moment(moment().format('YYYY-MM-DD') + ' 00:00');
        const todayMax = new moment(moment().format('YYYY-MM-DD') + ' 23:59');
        this.selectCinema = this.formBuilder.group({
            cinemas: ['', Validators.required],
            date_from: [todayMin],
            date_to: [todayMax],
            days: todayMax.diff(todayMin, 'days') + 1,
            cinemaSearch: [''],
        });
        this.searchCinema(null);
        this.summary = this.formBuilder.group({});
        this.generate_kdm = this.formBuilder.group({});
        this.selectMovie.valueChanges.subscribe(() => {
            this.onFormValuesChangedMovie();
        });

        this.selectCinema.valueChanges.subscribe(() => {
            this.onFormValuesChangedCinema();
        });

    }

    ngAfterViewInit() {
        this.afterViewInitFunctions();
    }
    afterViewInitFunctions() {
        setTimeout(() => {
            this.chooseMovieInput.nativeElement.focus();
        }, 250);
        Observable.fromEvent(this.chooseCinemaInput.nativeElement, 'keyup')
            .map((evt: any) => evt.target.value)
            .filter(res => res.length > 2)
            .debounceTime(500)
            .distinctUntilChanged()
            .subscribe((text: string) => this.selectAllItems(text));

        Observable.fromEvent(this.chooseMovieInput.nativeElement, 'keyup')
            .map((evt: any) => evt.target.value)
            .debounceTime(500)
            .distinctUntilChanged()
            .subscribe((text: string) => this.updateFilter(text));
    }
    public onStepperSelectionChange(evant: any) {
        const element = document.querySelector('#KdmStep' + evant.selectedIndex);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth', block: 'start', inline:
                        'nearest'
                });
            }, 250);
        }

        if (evant.selectedIndex === 1) {
            this.enableKeypress = true;
        }
        else {
            this.enableKeypress = false;
        }
    }
    closeBar() {
        this.player =
            this.animationBuilder
                .build([
                    style({transform: 'translate3d(0,0,0)'}),
                    animate('400ms ease', style({transform: 'translate3d(100%,0,0)'}))
                ]).create(this.panel.nativeElement);

        this.player.play();

        this.player.onDone(() => {
            this.barClosed = true;
        });
    }

    openBar() {
        this.barClosed = false;

        this.player =
            this.animationBuilder
                .build([
                    style({transform: 'translate3d(100%,0,0)'}),
                    animate('400ms ease', style({transform: 'translate3d(0,0,0)'}))
                ]).create(this.panel.nativeElement);

        this.player.play();
    }




    doSomething(event: StepperSelectionEvent) {
        this.onStepperSelectionChange(event);
        switch (event.selectedIndex) {
            case 1:
                this.searchCinema(null);
                break;
            case 2:
                if (this.generatedKdm) { // TODO ewentualne usuniecie dotychczasowo wygenerowanych KDMow (niewyslanych - wycofany krok 5 > 3)
                    this.generatedKdm = false;
                    break;
                }

                if (event.previouslySelectedIndex > event.selectedIndex) {
                    break;
                }

                this.nestedNodeMapFinish = new Map<TodoItemNodeFinish, TodoItemFlatNodeFinish>();
                this.getSelected();
                this.dataSourceFinish.data = [];
                this.databaseFinish.generateKdmFinishTree(this.selectedCinemas);

                // tslint:disable-next-line:triple-equals
                if (this.databaseFinish != undefined) {
                    this.databaseFinish.dataChange.subscribe(data => {
                        this.dataSourceFinish.data = data;
                        this.selectAllFinish();
                    });
                }

                break;
            case 3:
                this.generateMessage();
                break;
            case 4:
                this.generateKDM();
                break;
            case 5:
                this.sendMessages();
                break;
        }
    }


    onFormValuesChangedMovie() {
        for (const field in this.selectMovieErrors) {
            if (!this.selectMovieErrors.hasOwnProperty(field)) {
                continue;
            }
            this.selectMovieErrors[field] = {};
            const control = this.selectMovie.get(field);
            if (control && !control.valid) {
                this.selectMovieErrors[field] = control.errors;
            }
        }
    }
    onFormValuesChangedCinema() {
        for (const field in this.selectCinemaErrors) {
            if (!this.selectCinemaErrors.hasOwnProperty(field)) {
                continue;
            }
            this.selectCinemaErrors[field] = {};
            const control = this.selectCinema.get(field);
            if (control && !control.valid) {
                this.selectCinemaErrors[field] = control.errors;
            }
        }
    }
    updateFilter(val: string) {
        if (this.movieSubscribe) {
            this.movieSubscribe.unsubscribe();
        }
        this.movieSubscribe = this.movieService.getAllActiveFiltrated(val)
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
            });
        this.moviesFilter = val;
    }
    releaseChange(event: any) {
        for (let i = 0; i < this.rows.length; i++) {
            for (let j = 0; j < this.rows[i].movie_releases.length; j++) {
                if (event.value === this.rows[i].movie_releases[j].id.toString()) {
                    this.choosedRelease.name = this.rows[i].movie_releases[j].movie_name;
                    this.choosedRelease.movieName = this.rows[i].name;
                    this.choosedRelease.releaseName = this.rows[i].movie_releases[j].name;
                    this.selectMovie.controls['movie'].setValue(this.choosedRelease.name);
                    this.choosedRelease.id = this.rows[i].movie_releases[j].id;
                    this.choosedRelease.img = this.rows[i].img;
                    this.movieKdmService.getList(this.rows[i].movie_releases[j].id)
                        .subscribe(data => {
                            this.movie_kdms_list = data.data;
                        });
                }
            }
        }
        this.choosedRelease.isSelected = true;
        setTimeout(() => {
            this.choosedRelease.headingShown = true;
        }, 10);
    }
    headingClose(event: any) {
        this.choosedRelease.headingShown = false;
        this.chooseMovieInput.nativeElement.focus();
        this.chooseMovieInput.nativeElement.select();
        setTimeout(() => {
            this.choosedRelease.isSelected = false;
            this.choosedRelease = new ChoosedRelease;
            this.selectMovie.controls['movie'].setValue('');
            this.selectMovie.controls['radio'].setValue('');
        }, 500);
    }
    // TREE
    getLevel = (node: TodoItemFlatNode) => node.level;
    isExpandable = (node: TodoItemFlatNode) => node.expandable;
    getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
        return ofObservable(node.children);
    }
    hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';
    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: TodoItemNode, level: number) => {
        // tslint:disable-next-line:no-non-null-assertion
        const flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item ? this.nestedNodeMap.get(node)! : new TodoItemFlatNode();
        flatNode.id = node.id;
        flatNode.item = node.item;
        flatNode.date_time_from = node.date_time_from;
        flatNode.date_time_to = node.date_time_to;
        flatNode.kdms_list = node.kdms_list;
        flatNode.level = level;
        flatNode.cinema_id = (node.cinema_id) ? node.cinema_id : null;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }
    /** Whether all the descendants of the node are selected */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every(child => this.checklistSelection.isSelected(child));
    }
    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }
    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleSmall(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
    }
    todoItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        this.getSelected();
    }
    normalizeSelectedCinemas(): void {
        // tslint:disable-next-line:triple-equals
        if (this.selectedCinemas == undefined) {
            this.selectedCinemas = [];
        }
    }
    hasSelectedCinemas(): boolean {
        this.normalizeSelectedCinemas();
        return this.selectedCinemas.length > 0;
    }
    getSelected(map: any = this.nestedNodeMap, types: any = 'basic') {
        this.normalizeSelectedCinemas();
        map.forEach((val: any) => {
            let isSelected = null;
            let partlySelected = null;
            let allSelected = null;
            if (types === 'basic') {
                isSelected = this.checklistSelection.isSelected(val);
                partlySelected = this.descendantsPartiallySelected(val);
                allSelected = this.descendantsAllSelected(val);
            } else {
                isSelected = this.checklistSelectionFinish.isSelected(val);
                partlySelected = this.descendantsPartiallySelectedFinish(val);
                allSelected = this.descendantsAllSelectedFinish(val);
            }
            val.error = false;
            let cinema_index = -1;
            if (val.cinema_id != null) {
                cinema_index = this.selectedCinemas.findIndex(
                    // tslint:disable-next-line:triple-equals
                    selectedCinema => selectedCinema.id == val.cinema_id
                );
            } else {
                cinema_index = this.selectedCinemas.findIndex(
                    // tslint:disable-next-line:triple-equals
                    selectedCinema => selectedCinema.id == val.id
                );
            }
            // tslint:disable-next-line:triple-equals
            if (val.expandable == false && isSelected) {
                const arrayToAdd: any = {};
                arrayToAdd.id = val.id;
                arrayToAdd.item = val.item;
                arrayToAdd.status = 'W TRAKCIE';
                arrayToAdd.message = '';
                arrayToAdd.cinema_id = val.cinema_id;
                if (this.movie_kdms_list.length > 0 && cinema_index > -1) {
                    // tslint:disable-next-line:triple-equals
                    const filtreted_kdms_list = this.movie_kdms_list.filter((row: any) => row.cinema_room_id == val.id);
                    if (filtreted_kdms_list.length > 0) {
                        const cinema_from = moment(this.selectedCinemas[cinema_index].from).format('YYYY-MM-DD HH:mm');
                        const cinema_to = moment(this.selectedCinemas[cinema_index].to).format('YYYY-MM-DD HH:mm');
                        for (let i = 0; i < filtreted_kdms_list.length; i++) {
                            const kdm_from_date = moment(filtreted_kdms_list[i].kdm_date_from).format('YYYY-MM-DD HH:mm');
                            const kdm_to_date = moment(filtreted_kdms_list[i].kdm_date_to).format('YYYY-MM-DD HH:mm');
                            if ((cinema_from >= kdm_from_date && cinema_from <= kdm_to_date) || (cinema_to <= kdm_to_date && cinema_to >= kdm_from_date)) {
                                if (!val.error) {
                                    val.errorMessage = 'Ta sala ma już aktywny KDM w tym samym czasie: ';
                                }
                                val.error = true;
                                const conflict =  {from: kdm_from_date, to: kdm_to_date};
                                val.errorMessage += '\n - ' + conflict.from + ' - ' + conflict.to;
                                map.forEach((valx: any) => {
                                    // tslint:disable-next-line:triple-equals
                                    if (valx.id == val.cinema_id && valx.expandable == true) {
                                        valx.error = true;
                                    }
                                });
                            }
                        }
                    }
                }
                let added = false;
                if (cinema_index > -1) {
                    for (let j = 0; j < this.selectedCinemas[cinema_index].rooms.length; j++) {
                        // tslint:disable-next-line:triple-equals
                        if (this.selectedCinemas[cinema_index].rooms[j].id == val.id && this.selectedCinemas[cinema_index].rooms[j].item == val.item) {
                            this.selectedCinemas[cinema_index].rooms[j] = arrayToAdd;
                            added = true;
                        }
                    }
                    if (!added) {
                        this.selectedCinemas[cinema_index].rooms.push(arrayToAdd);
                    }
                }
                // tslint:disable-next-line:triple-equals
            } else if (val.expandable == true && (
                (isSelected && partlySelected && allSelected) ||
                (isSelected && partlySelected && !allSelected) ||
                (isSelected && !partlySelected && allSelected) ||
                (!isSelected && partlySelected && !allSelected) ||
                (!isSelected && !partlySelected && allSelected)
            )) {
                if (!isSelected) {
                    if (types === 'basic') {
                        this.checklistSelection.select(val);
                    } else {
                        this.checklistSelectionFinish.select(val);
                    }
                }
                // tslint:disable-next-line:triple-equals
                if (val.date_time_from == undefined) {
                    val.date_time_from = this.selectCinema.controls.date_from.value;
                }
                // tslint:disable-next-line:triple-equals
                if (val.date_time_to == undefined) {
                    val.date_time_to = this.selectCinema.controls.date_to.value;
                }
                // tslint:disable-next-line:triple-equals
                if (val.days == undefined) {
                    val.days = this.selectCinema.controls.days.value;
                }
                const arrayToAdd: any = {};
                arrayToAdd.id = val.id;
                arrayToAdd.item = val.item;
                arrayToAdd.from = moment(val.date_time_from).format('YYYY-MM-DD HH:mm');
                arrayToAdd.to = moment(val.date_time_to).format('YYYY-MM-DD HH:mm');
                arrayToAdd.days = val.days;
                arrayToAdd.rooms = [];
                let added = false;
                if (cinema_index > -1) {
                    this.selectedCinemas[cinema_index] = arrayToAdd;
                    added = true;
                }
                if (!added) {
                    this.selectedCinemas.push(arrayToAdd);
                }
                // tslint:disable-next-line:triple-equals
            } else if (cinema_index > -1 && val.expandable == true && (
                (!allSelected && !isSelected && !partlySelected) ||
                (!allSelected && !isSelected && partlySelected) ||
                (!allSelected && isSelected && !partlySelected)
            )) {
                if (types === 'basic') {
                    this.checklistSelection.deselect(val);
                } else {
                    this.checklistSelectionFinish.deselect(val);
                }
                val.date_time_from = null;
                val.date_time_to = null;
                val.days = null;
                this.selectedCinemas.splice(cinema_index, 1);

            }
        });
        for (let i = 0; i < this.selectedCinemas.length; i++) {
            if (this.selectedCinemas[i].rooms.length === 0) {
                this.selectedCinemas.splice(i, 1);
            }
        }
        if (this.selectedCinemas.length !== 0) {
            this.selectCinema.controls['cinemas'].setValue(this.selectedCinemas.length);
        } else {
            this.selectCinema.controls['cinemas'].setValue('');
        }
    }
    dateChanged(node: TodoItemFlatNode, what: string, $event: any) {
        if (what === 'from') {
            node.date_time_from = moment($event.value);
        } else if (what === 'to') {
            node.date_time_to = moment($event.value);
        }
        const min = new moment(node.date_time_from);
        const max = new moment(node.date_time_to);
        let dif = max.diff(min, 'days');
        if (max.format('HH:mm') === '23:59' && min.format('HH:mm') === '00:00') {
            dif = dif + 1;
        }
        node.days = dif;
        this.getSelected();

    }
    dateChangedFinish(node: TodoItemFlatNodeFinish, what: string, $event: any) {
        if (what === 'from') {
            node.date_time_from = moment($event.value);
        } else if (what === 'to') {
            node.date_time_to = moment($event.value);
        }
        const min = new moment(node.date_time_from);
        const max = new moment(node.date_time_to);
        let dif = max.diff(min, 'days');
        if (max.format('HH:mm') === '23:59' && min.format('HH:mm') === '00:00') {
            dif = dif + 1;
        }
        node.days = dif;
        this.getSelected(this.nestedNodeMapFinish, 'finish');

    }
    selectedNode(node: TodoItemFlatNode) {
        this.treeControl.expand(node);
        this.checklistSelection.toggle(node);
        if (this.checklistSelection.isSelected(node)) {
            // tslint:disable-next-line:triple-equals
            if (node.expandable == true) {
                node.date_time_from = this.selectCinema.controls.date_from.value;
                node.date_time_to = this.selectCinema.controls.date_to.value;
                node.days = this.selectCinema.controls.days.value;
            } else {
                this.nestedNodeMap.forEach((val: TodoItemFlatNode) => {
                    // tslint:disable-next-line:triple-equals
                    if (val.id == node.cinema_id && val.expandable == true) {
                        val.date_time_from = this.selectCinema.controls.date_from.value;
                        val.date_time_to = this.selectCinema.controls.date_to.value;
                        val.days = this.selectCinema.controls.days.value;
                    }
                });
            }
        }
        this.getSelected();
    }
    selectAllTree() {
        if (this.all_marked) {
            this.nestedNodeMap.forEach((val: TodoItemFlatNode) => {
                if (this.checklistSelection.isSelected(val)) {
                    this.checklistSelection.toggle(val);
                }
            });
            this.all_marked = false;
        } else {
            this.nestedNodeMap.forEach((val: TodoItemFlatNode) => {
                if (!this.checklistSelection.isSelected(val)) {
                    this.checklistSelection.toggle(val);
                }
            });
            this.all_marked = true;
        }

        this.getSelected();
    }
    // TREE-FINISH
    getLevelFinish = (node: TodoItemFlatNodeFinish) => node.level;
    isExpandableFinish = (node: TodoItemFlatNodeFinish) => node.expandable;
    getChildrenFinish = (node: TodoItemNodeFinish): Observable<TodoItemNodeFinish[]> => {
        return ofObservable(node.children);
    }
    hasChildFinish = (_: number, _nodeData: TodoItemFlatNodeFinish) => _nodeData.expandable;
    hasNoContentFinish = (_: number, _nodeData: TodoItemFlatNodeFinish) => _nodeData.item === '';
    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformerFinish = (node: TodoItemNodeFinish, level: number) => {
        // tslint:disable-next-line:no-non-null-assertion max-line-length
        const flatNode = this.nestedNodeMapFinish.has(node) && this.nestedNodeMapFinish.get(node)!.item === node.item ? this.nestedNodeMapFinish.get(node)! : new TodoItemFlatNodeFinish();
        flatNode.id = node.id;
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.date_time_from = moment(node.date_time_from);
        flatNode.date_time_to = moment(node.date_time_to);
        flatNode.days = node.days;
        flatNode.cinema_id = node.cinema_id;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapFinish.set(flatNode, node);
        this.nestedNodeMapFinish.set(node, flatNode);
        return flatNode;
    }
    /** Whether all the descendants of the node are selected */
    descendantsAllSelectedFinish(node: TodoItemFlatNodeFinish): boolean {
        const descendants = this.treeControlFinish.getDescendants(node);
        return descendants.every(child => this.checklistSelectionFinish.isSelected(child));

    }
    selectAllFinish() {
        this.nestedNodeMapFinish.forEach((val: TodoItemFlatNodeFinish) => {
            this.checklistSelectionFinish.toggle(val);
        });
        this.getSelected(this.nestedNodeMapFinish, 'finish');
    }
    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedFinish(node: TodoItemFlatNodeFinish): boolean {
        const descendants = this.treeControlFinish.getDescendants(node);
        const result = descendants.some(child => this.checklistSelectionFinish.isSelected(child));
        return result && !this.descendantsAllSelectedFinish(node);
    }
    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleFinish(node: TodoItemFlatNodeFinish): void {
        this.checklistSelectionFinish.toggle(node);
        const descendants = this.treeControlFinish.getDescendants(node);
        this.checklistSelectionFinish.isSelected(node)
            ? this.checklistSelectionFinish.select(...descendants)
            : this.checklistSelectionFinish.deselect(...descendants);
        this.getSelected(this.nestedNodeMapFinish, 'finish');
    }

    selectedNodeFinish(node: TodoItemFlatNodeFinish) {
        this.treeControlFinish.expand(node);
        this.checklistSelectionFinish.toggle(node);
        this.getSelected(this.nestedNodeMapFinish, 'finish');
    }

    // TREE-CINEMA
    getLevelCinema = (node: CinemaItemFlatNode) => node.level;
    isExpandableCinema = (node: CinemaItemFlatNode) => node.expandable;
    getChildrenCinema = (node: CinemaItemNode): Observable<CinemaItemNode[]> => {
        return ofObservable(node.children);
    }
    hasChildCinema = (_: number, _nodeData: CinemaItemFlatNode) => _nodeData.expandable;
    hasNoContentCinema = (_: number, _nodeData: CinemaItemFlatNode) => _nodeData.item === '';
    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformerCinema = (node: CinemaItemNode, level: number) => {
        // tslint:disable-next-line:no-non-null-assertion max-line-length
        const flatNode = this.nestedNodeMapCinema.has(node) && this.nestedNodeMapCinema.get(node)!.item === node.item ? this.nestedNodeMapCinema.get(node)! : new CinemaItemFlatNode();
        flatNode.id = node.id;
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapCinema.set(flatNode, node);
        this.nestedNodeMapCinema.set(node, flatNode);
        return flatNode;
    }
    /** Whether all the descendants of the node are selected */
    descendantsAllSelectedCinema(node: CinemaItemFlatNode): boolean {
        const descendants = this.treeControlCinema.getDescendants(node);
        return descendants.every(child => this.cinemaListSelection.isSelected(child));
    }
    selectAllCinema() {
        this.cinemasToSend = 0;
        setTimeout(() => {
            this.nestedNodeMapCinema.forEach((val: CinemaItemFlatNode) => {
                if (val.expandable) {
                    this.treeControlCinema.expand(val);
                } else {
                    this.cinemasToSend++;
                }
                this.cinemaListSelection.toggle(val);
            });
        }, 10);
    }
    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedCinema(node: CinemaItemFlatNode): boolean {
        const descendants = this.treeControlCinema.getDescendants(node);
        const result = descendants.some(child => this.cinemaListSelection.isSelected(child));
        return result && !this.descendantsAllSelectedCinema(node);
    }
    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleCinema(node: CinemaItemFlatNode): void {
        this.cinemaListSelection.toggle(node);
        const descendants = this.treeControlCinema.getDescendants(node);
        this.cinemaListSelection.isSelected(node)
            ? this.cinemaListSelection.select(...descendants)
            : this.cinemaListSelection.deselect(...descendants);
    }

    selectedNodeCinema(node: CinemaItemFlatNode) {
        this.treeControlCinema.expand(node);
        this.cinemaListSelection.toggle(node);
    }
    generateMessage() {
        this.editable = true;
        this.messageService.kdm(this.choosedRelease.id, this.selectedCinemas[0].rooms[0].file)
            .subscribe(data => {
                this.sendMessage.controls['message'].setValue(data.data);
            });
    }
    // -TREE
    generateKDM() {
        let ite = 0;
        this.editable = true;
        for (let i = 0; i < this.selectedCinemas.length; i++) {
            for (let j = 0; j < this.selectedCinemas[i].rooms.length; j++) {
                ite++;
                this.kdmService.generate(
                    this.choosedRelease.id,
                    this.selectedCinemas[i].rooms[j].id,
                    this.selectedCinemas[i].rooms[j].cinema_id,
                    this.selectedCinemas[i].from,
                    this.selectedCinemas[i].to
                ).subscribe(
                    data => {
                        this.generated_kdms_count++;
                        this.selectedCinemas[i].rooms[j].status = data.data;
                        this.selectedCinemas[i].rooms[j].file = data.message;
                        // tslint:disable-next-line:triple-equals
                        if (i == this.selectedCinemas.length - 1 && j == this.selectedCinemas[i].rooms.length - 1) {
                            this.finishGenerationKdms();
                        }
                    },
                    error => {
                        this.selectedCinemas[i].rooms[j].status = 'BŁĄD';
                        this.selectedCinemas[i].rooms[j].message = error.error.message;
                        // tslint:disable-next-line:triple-equals
                        if (i == this.selectedCinemas.length - 1 && j == this.selectedCinemas[i].rooms.length - 1) {
                            this.finishGenerationKdms();
                        }
                    }


                );
            }
        }
        this.logService.saveLog('KDM/generate', this.choosedRelease.name + ' KDM: ' + ite).subscribe();
    }
    finishGenerationKdms() {
        this.flatNodeMapCinema = new Map<CinemaItemFlatNode, CinemaItemNode>();
        this.nestedNodeMapCinema = new Map<CinemaItemNode, CinemaItemFlatNode>();
        this.databaseCinema.dataChangeCinema = new BehaviorSubject<CinemaItemNode[]>([]);
        this.cinemaListSelection = new SelectionModel<CinemaItemFlatNode>(true /* multiple */);
        this.generatedKdm = true;
        this.databaseCinema.generateCinemaTree(this.selectedCinemas);
        // tslint:disable-next-line:triple-equals
        if (this.databaseCinema != undefined) {
            this.databaseCinema.dataChangeCinema.subscribe(data => {
                this.dataSourceCinema.data = data;
                this.selectAllCinema();
            });
        }
    }
    selectAllItems(val: string) {
        if (val != null && val.includes("/")) {
            if (this.nestedNodeMap.size) {
                this.selectAllTree();
                const inputElem = <HTMLInputElement> this.chooseCinemaInput.nativeElement;
                this.selectCinema.controls['cinemaSearch'].setValue(val.replace('/', ''));
                (<HTMLInputElement> inputElem).select();
            }
            return false;
        } else {
            this.searchCinema(val);
        }
    }
    searchCinema(val: string) {
        if (val != null && val.includes("/")) {
            const inputElem = <HTMLInputElement> this.chooseCinemaInput.nativeElement;
            this.selectCinema.controls['cinemaSearch'].setValue(val.replace('/', ''));
            (<HTMLInputElement> inputElem).select();
            return false;
        }

        this.generatedKdm = false;
        this.finded = false;
        if (this._timeout) {
            window.clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout(() => {
            this._timeout = null;
            if (this.selectCinema.controls.cinemaSearch.value.length > 2) {
                // tslint:disable-next-line:triple-equals
                if (this.selectedCinemas != undefined) {
                    const selectedCinemasCopy: any[] = JSON.parse(JSON.stringify(this.selectedCinemas));
                    this.nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
                    this.database.generateKdmTree(this.selectCinema.controls.cinemaSearch.value);
                    // tslint:disable-next-line:triple-equals
                    if (this.database != undefined) {
                        this.database.dataChange.subscribe(data => {
                            this.dataSource.data = data;
                            window.setTimeout(() => {
                                this.finded = true;
                                this.all_marked = false;
                            }, 550);
                            if (data.length > 0) {
                                this.nestedNodeMap.forEach((val: TodoItemFlatNode) => {
                                    // tslint:disable-next-line:triple-equals
                                    if (this.selectedCinemas != undefined) {
                                        for (let i = 0; i < selectedCinemasCopy.length; i++) {
                                            // tslint:disable-next-line:triple-equals
                                            if (val.expandable == true && val.id == selectedCinemasCopy[i].id && val.item == selectedCinemasCopy[i].item) {
                                                val.date_time_from = moment(selectedCinemasCopy[i].from);
                                                val.date_time_to = moment(selectedCinemasCopy[i].to);
                                                const min = new moment(val.date_time_from);
                                                const max = new moment(val.date_time_to);
                                                let dif = max.diff(min, 'days');
                                                if (max.format('HH:mm') === '23:59' && min.format('HH:mm') === '00:00') {
                                                    dif = dif + 1;
                                                }
                                                val.days = dif;
                                                this.selectedCinemas[i] = selectedCinemasCopy[i];
                                            }
                                            // tslint:disable-next-line:triple-equals
                                            if (val.expandable == false && selectedCinemasCopy[i].rooms != undefined && selectedCinemasCopy[i].rooms.length > 0) {
                                                for (let j = 0; j < selectedCinemasCopy[i].rooms.length; j++) {
                                                    // tslint:disable-next-line:max-line-length triple-equals
                                                    if (val.id == selectedCinemasCopy[i].rooms[j].id && val.item == selectedCinemasCopy[i].rooms[j].item && val.cinema_id == selectedCinemasCopy[i].rooms[j].cinema_id) {
                                                        this.todoItemSelectionToggleSmall(val);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                            this.getSelected();
                        });
                    }

                } else {
                    this.nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
                    this.database.generateKdmTree(this.selectCinema.controls.cinemaSearch.value);
                    // tslint:disable-next-line:triple-equals
                    if (this.database != undefined) {
                        this.database.dataChange.subscribe(data => {
                            this.dataSource.data = data;
                            window.setTimeout(() => {
                                this.finded = true;
                                this.all_marked = false;
                            }, 550);
                        });
                    }
                }

            }

        }, 500);
    }
    sendMessages() {
        this.editable = false;
        this.sendedMessages = [];
        this.nestedNodeMapCinema.forEach((val: CinemaItemFlatNode) => {
            if (this.cinemaListSelection.isSelected(val) && val.expandable === false) {
                const arrayToAdd: any = {};
                arrayToAdd.id = val.id;
                arrayToAdd.cinema = val.item;
                arrayToAdd.status = 'W TRAKCIE';
                this.sendedMessages.push(arrayToAdd);
            }
        });
        this.sendedMessages.forEach((val: any, key: any) => {
            setTimeout(() => {
                this.SendKdmEmail(val.id, key);
            }, 1000 * key);
        });
        this.logService.saveLog('KDM/sent', this.choosedRelease.name + ' Kina: ' + this.sendedMessages.length).subscribe();
    }
    SendKdmEmail(id: any, key: any) {
        this.kdmService.sendEmail(this.selectedCinemas, id, this.sendMessage.value, this.choosedRelease.id)
            .subscribe(
                data => {
                    this.sendedMessages[key].status = data.message;
                },
                error => {
                    this.sendedMessages[key].status = error.error.message;
                });
    }
    finishKDM(): void {
        this.router.navigate(['/']);
    }
    modalControl() {
        this.hiddenModal = !this.hiddenModal;
        if (this.hiddenModal) {
            this.nestedNodeMapCinema.forEach((val: CinemaItemFlatNode) => {
                for (let i = 0; i < this.selectedCinemaCopy.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (!this.cinemaListSelection.isSelected(val) && val.expandable === false && this.selectedCinemaCopy[i] == val) {
                        this.cinemaListSelection.toggle(val);
                    }
                    // tslint:disable-next-line:triple-equals
                    if (this.cinemaListSelection.isSelected(val) && val.expandable === false && this.unselectedCinemaCopy[i] == val) {
                        this.cinemaListSelection.toggle(val);
                    }
                }
            });
        } else {
            this.selectedCinemaCopy = [];
            this.unselectedCinemaCopy = [];
            this.nestedNodeMapCinema.forEach((val: CinemaItemFlatNode) => {
                if (this.cinemaListSelection.isSelected(val) && val.expandable === false) {
                    this.selectedCinemaCopy.push(val);
                } else if (!this.cinemaListSelection.isSelected(val) && val.expandable === false) {
                    this.unselectedCinemaCopy.push(val);
                }
            });
        }
    }
    modalControlSave() {
        this.hiddenModal = !this.hiddenModal;
        this.cinemasToSend = 0;
        this.nestedNodeMapCinema.forEach((val: CinemaItemFlatNode) => {
            if (!val.expandable && this.cinemaListSelection.isSelected(val)) {
                this.cinemasToSend++;
            }
        });
    }
    mainDateChanged() {
        const min = new moment(this.selectCinema.controls.date_from.value);
        const max = new moment(this.selectCinema.controls.date_to.value);
        let dif = max.diff(min, 'days');
        if (max.format('HH:mm') === '23:59' && min.format('HH:mm') === '00:00') {
            dif = dif + 1;
        }
        this.selectCinema.controls['days'].setValue(dif);
    }
    changeMovie() {
        this.processing = true;
        this.movieService.getAllActiveFiltrated('')
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
            });
        setTimeout(() => {
            this.selectMovie = this.formBuilder.group({
                radio: ['', Validators.required],
                movie: ['', Validators.required],
            });
            this.sendedMessages = [];
            this.flatNodeMapCinema = new Map<CinemaItemFlatNode, CinemaItemNode>();
            this.nestedNodeMapCinema = new Map<CinemaItemNode, CinemaItemFlatNode>();
            this.databaseCinema.dataChangeCinema = new BehaviorSubject<CinemaItemNode[]>([]);
            this.cinemaListSelection = new SelectionModel<CinemaItemFlatNode>(true /* multiple */);
            this.dataSourceCinema.data = [];
            this.cinemasToSend = 0;
            this.getSelected();
            this.choosedRelease = new ChoosedRelease;
            this.editable = true;
            this.currentStep = 0;
            this.generatedKdm = false;
            this.processing = false;
            this.movieFocus();
            setTimeout(() => {
                this.afterViewInitFunctions();
            }, 1000);
        }, 500);

    }
    movieFocus() {
        setTimeout(() => {
            if (this.chooseMovieInput !== undefined) {
                this.chooseMovieInput.nativeElement.focus();
            }
        }, 100);
    }
    newMovie() {
        this.processing = true;
        this.movieService.getAllActiveFiltrated('')
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
            });
        setTimeout(() => {
            this.selectMovie = this.formBuilder.group({
                radio: ['', Validators.required],
                movie: ['', Validators.required],
            });
            this.sendedMessages = [];
            this.selectedCinemas = [];
            this.flatNodeMapCinema = new Map<CinemaItemFlatNode, CinemaItemNode>();
            this.nestedNodeMapCinema = new Map<CinemaItemNode, CinemaItemFlatNode>();
            this.databaseCinema.dataChangeCinema = new BehaviorSubject<CinemaItemNode[]>([]);
            this.cinemaListSelection = new SelectionModel<CinemaItemFlatNode>(true /* multiple */);
            this.flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
            this.nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
            this.database.dataChange = new BehaviorSubject<TodoItemNode[]>([]);
            this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
            this.selectCinema.controls['cinemaSearch'].setValue('');
            this.searchCinema(null);
            this.dataSourceCinema.data = [];
            this.cinemasToSend = 0;
            this.nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
            this.getSelected();
            this.choosedRelease = new ChoosedRelease;
            this.editable = true;
            this.currentStep = 0;
            this.generatedKdm = false;
            this.processing = false;
            this.movieFocus();
            setTimeout(() => {
                this.afterViewInitFunctions();
            }, 1000);
        }, 500);
    }
}
