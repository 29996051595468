import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {roles} from './roles';

@Injectable()
export class AuthGuard implements CanActivate {
    roles: any;
    user: any;
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.roles = roles;
        if (localStorage.getItem('currentUser')) {
            this.user = JSON.parse(localStorage.getItem('currentUser'));
            const current_roles = this.roles[this.user.role_id];
            if (current_roles.filter(element => element === '__all').length > 0) {
                return true;
            } else if (current_roles.filter(element => element === route.routeConfig.path).length > 0) {
                return true;
            }
            this.router.navigate(['/dashboard']);
            return false;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}