import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from 'app/_models/user';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;
@Injectable()
export class MessageService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    kdm(id: number = null, fille: any = null) {
        return this.http.post<any>(API_URL + '/api/messages/kdm/' + id, {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
            fille
        });
    }
    getAll() {
        return this.http.post<any>(API_URL + '/api/messages', {loggedInId: this.currentUser.id, token: this.currentUser.token});
    }
}