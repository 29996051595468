import {Component, OnInit, ViewChild} from '@angular/core';
import {Message} from 'app/_models/message';
import {MessageService} from 'app/_services/message.service';
import {ListsService} from 'app/_services/lists.service';

import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD H:mm',
    fullPickerInput: 'YYYY-MM-DD H:mm',
    datePickerInput: 'YYYY-MM-DD H:mm',
    timePickerInput: 'YYYY-MM-DD H:mm',
    monthYearLabel: 'YYYY-MM-DD H:mm',
    dateA11yLabel: 'YYYY-MM-DD H:mm',
    monthYearA11yLabel: 'YYYY-MM-DD H:mm',
};

@Component({
    selector: 'app-message-reports',
    templateUrl: './message-reports.component.html',
    styleUrls: ['./message-reports.component.scss'],
    providers: [
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    ]
})
export class MessageReportsComponent implements OnInit {
    @ViewChild('myTable') table: any;

    rows: Message[] = [];
    loadingIndicator = true;
    reorderable = true;
    temp: any = [];
    cinema: string = null;
    cinemas: any[] = [];
    //Search Input--
    selectedCinemas: any[] = this.cinemas;
    searchInput: string = null;
    //--Search Input
    range: any = null;
    title: string = null;
    constructor(
        private messageService: MessageService,
        private listsService: ListsService,
    ) {}

    ngOnInit() {
        this.messageService.getAll()
            .subscribe(data => {
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
        this.listsService.cinema()
            .subscribe(data => {
                this.cinemas = data.data;
                this.selectedCinemas = this.cinemas;
            });
    }

    //Search Input--
    search(query: string) {
        let result = this.select(query)
        this.selectedCinemas = result;
    }

    select(query: string): string[] {
        let result: string[] = [];
        for (let a of this.cinemas) {
            if (a.full_name.toLowerCase().indexOf(query) > -1) {
                result.push(a)
            }
        }
        return result
    }
    cleanSearchImput() {
        this.searchInput = '';
        this.selectedCinemas = this.cinemas;
    }
    //--Search Input

    sortTable() {
        let temp_sort = this.temp;
        if (this.cinema != null) {
            temp_sort = this.sortCinema(temp_sort);
        }
        if (this.range != null) {
            temp_sort = this.rangeChanged(temp_sort);
        }
        if (this.title != null) {
            temp_sort = this.updateFilter(temp_sort);
        }
        this.rows = temp_sort;
        this.table.offset = 0;
    }
    sortCinema(temp_sort: any) {
        const val = this.cinema;
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.cinema_id != undefined && d.cinema_id == val) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    updateFilter(temp_sort: any) {
        const val = this.title.toLowerCase();
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.title.toLowerCase().indexOf(val) !== -1) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    rangeChanged(temp_sort: any) {
        const val_from = moment(this.range[0]).format('YYYY-MM-DD HH:mm');
        const val_to = moment(this.range[1]).format('YYYY-MM-DD HH:mm');
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.created >= val_from && d.created <= val_to) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }

    clearValue() {
        this.range = null;
        this.sortTable();
    }

}
