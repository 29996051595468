import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;

@Injectable()
export class PasswordResetService {
    constructor(private http: HttpClient) {}

    reset(username: string) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.http.post<any>(API_URL + '/api/password', {username: username})
            .pipe(map(data => {
                return data;
            }));
    }
    resetFinish(password: string, passkey: string) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.http.post<any>(API_URL + '/api/password-reset/'+passkey, {password: password})
            .pipe(map(data => {
                return data;
            }));
    }

}