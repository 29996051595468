import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MultiplexService } from 'app/_services/multiplex.service';
import { Router } from '@angular/router';
import { Multiplex } from 'app/_models/multiplex';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { SEMICOLON, ENTER } from '@angular/cdk/keycodes';
@Component({
  selector: 'fuse-app-multiplex-basic-edit',
  templateUrl: './multiplex-basic-edit.component.html',
  styleUrls: ['./multiplex-basic-edit.component.scss']
})
export class MultiplexBasicEditComponent implements OnInit {

  @ViewChild('emails') emailsInput: ElementRef;
  readonly separatorKeysCodes: number[] = [SEMICOLON, ENTER];
  selectable = true;
  removable = true;
  addOnBlur = true;
  submitted = false;
  emailList: string[] = [];
  multiplex: Multiplex;
  editForm: FormGroup;
  formErrors: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private multiplexService: MultiplexService,
    public dialogRef: MatDialogRef<MultiplexBasicEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
    this.formErrors = {
      email: {},
    };
  }

  ngOnInit() {
    const multiplexId = this.data.model.id;
    if (!multiplexId) {
      this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
      this.router.navigate(['user_cinema/addresses']);
      return;
    }
    this.editForm = this.formBuilder.group({
      id: [],
      email: [''],
    });

    this.multiplexService.getById(+multiplexId)
      .subscribe(data => {
        const values = {
          id: data.data.id,
          email: data.data.email
        };
        this.emailList = (data.data.email ? data.data.email.split(';') : []);
        values.email = '';
        this.editForm.setValue(values);
        // this.validateEmails();
      });
    this.editForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
      this.validateEmails();
    });
  }

  onSubmit() {
    this.onFormValuesChanged();
    this.submitted = true;
    if (this.editForm.invalid || !this.validateEmails()) {
      return;
    }

    const values = this.editForm.value;
    values.email = this.emailList.join(';');

    this.multiplexService.update(values)
      .pipe(first())
      .subscribe(
        data => {
          this.dialogRef.close(data);
        },
        error => {
          this.toastr.error(error.error.message, 'Błąd');
        });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.editForm.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  get f() {
    return this.editForm.controls;
  }

  validateEmails(): boolean {
    if (this.emailList.join(';').length > 500) {
      this.formErrors['email']['maxlength'] = true;
      this.editForm.get('email').setErrors({ maxlength: true/* , required: false, email: false */ });
      return false;
    }

    for (let i = 0; i < this.emailList.length; i++) {
      if (!this.validateEmail(this.emailList[i])) {
        return false;
      }
    }

    this.formErrors['email']['required'] = false;
    this.formErrors['email']['email'] = false;
    this.formErrors['email']['maxlength'] = false;
    this.editForm.get('email').setErrors(null);

    return true;
  }

  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    email = String(email).trim().toLowerCase();
    if (email.length === 0 || re.test(email)) {
      return true;
    }

    this.formErrors.email['email'] = true;
    this.editForm.get('email').setErrors({ email: true/* , required: false, maxlength: false */ });

    return false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.validateEmail(value)) {
      this.formErrors['email']['email'] = true;
      this.editForm.get('email').setErrors({ required: true/* , email: false, maxlength: false */ });
      return;
    }

    // Add our fruit
    if ((value || '').trim()) {
      this.emailList.push(value.trim());

      this.editForm.markAsDirty();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.validateEmails();
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);

      this.editForm.markAsDirty();
    }

    this.validateEmails();
  }

  getSubmitColor(): string {
    if (this.editForm.dirty) {
      return 'red-bg';
    }

    return 'accent';
  }

}
