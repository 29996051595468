import {Component, OnInit, Inject} from '@angular/core';
import {MultiplexService} from 'app/_services/multiplex.service';
import {Router} from "@angular/router";
import {Multiplex} from 'app/_models/multiplex';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-multiplex-edit',
    templateUrl: './multiplex-edit.component.html',
    styleUrls: ['./multiplex-edit.component.scss']
})
export class MultiplexEditComponent implements OnInit {
    multiplex: Multiplex;
    editForm: FormGroup;
    formErrors: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private multiplexService: MultiplexService,
        public dialogRef: MatDialogRef<MultiplexEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            email: {},
            hide_name: {},
        };
    }

    ngOnInit() {
        let multiplexId = localStorage.getItem("editMultiplexId");
        if (!multiplexId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['multiplexes']);
            return;
        }
        this.editForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required],
            email: [],
            deleted: [],
            hide_name: [],
        });

        this.multiplexService.getById(+multiplexId)
            .subscribe(data => {
                this.editForm.setValue(data.data);
            });
        this.editForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editForm.invalid) {
            return;
        }
        this.multiplexService.update(this.editForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

}
