import {Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {Subscription} from 'rxjs';

import {FuseConfigService} from '@fuse/services/config.service';
import {Router} from '@angular/router';

import {navigation} from 'app/navigation/navigation';
import {navigation_cinema} from 'app/navigation/navigation_cinema';
import {navigation_distributor} from 'app/navigation/navigation_distributor';
import {navigation_multiplex} from 'app/navigation/navigation_multiplex';
import {User} from 'app/_models/user';

@Component({
    selector: 'fuse-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseMainComponent implements OnDestroy {
    onConfigChanged: Subscription;
    fuseSettings: any;
    navigation: any;
    currentUser: User;

    @HostBinding('attr.fuse-layout-mode') layoutMode;

    constructor(
        private router: Router,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private fuseConfig: FuseConfigService,
        private platform: Platform,
        @Inject(DOCUMENT) private document: any
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.fuseSettings = newSettings;
                        this.layoutMode = this.fuseSettings.layout.mode;
                    }
                );
        if (this.platform.ANDROID || this.platform.IOS) {
            this.document.body.className += ' is-mobile';
        }

        if (this.currentUser !== null && this.currentUser.role_id === 2 && this.currentUser.cinema_id !== null) {
            this.navigation = navigation_cinema;
        } else if (this.currentUser !== null && this.currentUser.role_id === 3 && this.currentUser.distributor_id !== null) {
            this.navigation = navigation_distributor;
        } else if (this.currentUser !== null && this.currentUser.role_id === 4 && this.currentUser.multiplex_id !== null) {
            this.navigation = navigation_multiplex;
        } else {
            this.navigation = navigation;
        }
    }


    ngOnDestroy() {
        this.onConfigChanged.unsubscribe();
    }

    addClass(className: string) {
        this._renderer.addClass(this._elementRef.nativeElement, className);
    }

    removeClass(className: string) {
        this._renderer.removeClass(this._elementRef.nativeElement, className);
    }

}
