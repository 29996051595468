import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from 'app/_models/user';
import {environment} from 'environments/environment';
const API_URL = environment.apiUrl;

@Injectable()
export class SettingsService {
    currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    getAll() {
        return this.http.post<any>(API_URL + '/api/settings', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
    version() {
        return this.http.post<any>(API_URL + '/api/settings/version', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
    certificate() {
        return this.http.post<any>(API_URL + '/api/settings/kdm_config', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
    certificateXml() {
        return this.http.post<any>(API_URL + '/api/settings/kdm_config_xml', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
    import() {
        return this.http.post<any>(API_URL + '/api/import', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
        });
    }
    update(data: any) {
        return this.http.post<any>(API_URL + '/api/settings/update', {
            loggedInId: this.currentUser.id,
            token: this.currentUser.token,
            data
        });
    }
}