import {Component, OnInit, Inject} from '@angular/core';
import {MovieService} from 'app/_services/movie.service';
import {Router} from '@angular/router';
import {Movie} from 'app/_models/movie';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DistributorService} from 'app/_services/distributor.service';
import {Distributor} from 'app/_models/distributor';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { fuseAnimations } from '@fuse/animations';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'YYYY-MM-DD',
  fullPickerInput: 'YYYY-MM-DD',
  datePickerInput: 'YYYY-MM-DD',
  timePickerInput: 'YYYY-MM-DD',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'YYYY-MM-DD',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
    selector: 'app-movie-edit',
    templateUrl: './movie-edit.component.html',
    providers: [
      { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
      { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
      { provide: OWL_DATE_TIME_LOCALE, useValue: 'pl' },
    ],
    animations: fuseAnimations
})
export class MovieEditComponent implements OnInit {

    distributors: Distributor[] = [];
    movie: Movie;
    editForm: FormGroup;
    formErrors: any;
    fileToUpload: any = '';
    mask = [/\d/, /\d/, /\d/, /\d/];
    cover: any = null;

    constructor(
        public dialogRef: MatDialogRef<MovieEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private router: Router,
        private movieService: MovieService,
        private distributorService: DistributorService,
        private toastr: ToastrService
    ) {
        this.formErrors = {
            name: {},
            premiere: {},
            distributor_id: {},
            img: {},
            active: {},
        };
    }

    ngOnInit() {
        this.distributorService.getAll()
            .subscribe(data => {
                this.distributors = data.data;
            });

        const movieId = localStorage.getItem('editMovieId');
        if (!movieId) {
            this.toastr.error('Błąd nie ma takiego ID', 'Błąd');
            this.router.navigate(['movies']);
            return;
        }

        this.editForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            premiere: ['', [
              Validators.required,
            ]],
            distributor_id: ['', Validators.required],
            img: [''],
            active: ['']
        });

        this.movieService.getById(+movieId)
            .subscribe(data => {
              const values = {
                id: data.data.id,
                name: data.data.name,
                premiere: data.data.premiere,
                distributor_id: data.data.distributor_id,
                img: data.data.img,
                active: data.data.active
              };
              this.editForm.setValue(values);
            });
        this.editForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editForm.invalid) {
            return;
        }
        const editMovie = this.editForm.value;
        editMovie.premiere = editMovie.premiere.format('YYYY-MM-DD');
        if (this.fileToUpload !== undefined) {
            editMovie.img = this.fileToUpload.name;
            editMovie.img_result = this.fileToUpload.result;
            editMovie.img_type = this.fileToUpload.type;
        }
        this.movieService.update(editMovie)
            .pipe(first())
            .subscribe(
                data => {
                    (<any> data).data.distributor = (<any> data).data.distributor ? (<any> data).data.distributor.name : '';
                    this.dialogRef.close(data);
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    handleFileInput(event: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.cover = reader.result;
                }
                let filex: Object = {
                    name: file.name,
                    type: file.type,
                    result: reader.result.split(',')[1]
                };
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                    this.fileToUpload = filex;
                } else {
                    this.toastr.error('błędny format', 'Błąd');
                }
            };
        }
    }

}