import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/_services/user.service';
import { Router } from '@angular/router';
import { Role } from 'app/_models/role';
import { RoleService } from 'app/_services/role.service';
import { Distributor } from 'app/_models/distributor';
import { DistributorService } from 'app/_services/distributor.service';
import { Cinema } from 'app/_models/cinema';
import { CinemaService } from 'app/_services/cinema.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PasswordResetService } from 'app/_services/password-reset.service';
import { first } from 'rxjs/operators';
import { MultiplexService } from '../../../../_services/multiplex.service';
import { Multiplex } from '../../../../_models/multiplex';
import { ListsService } from 'app/_services/lists.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent implements OnInit {

  roles: Role[] = [];
  distributors: Distributor[] = [];
  cinemas: Cinema[] = [];
  multiplexes: Multiplex[] = [];
  addForm: FormGroup;
  formErrors: any;
  random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  withPassword = false;
  selectedCinemas: any[] = this.cinemas;
  searchInputCinema: string = null;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private cinemaService: CinemaService,
    private passwordResetService: PasswordResetService,
    private distributorService: DistributorService,
    private multiplexService: MultiplexService,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private listsService: ListsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
    this.formErrors = {
      first_name: {},
      last_name: {},
      username: {},
      password: {},
      role_id: {},
      active: {},
      distributor_id: {},
      cinema_id: {},
      multiplex_id: {},
      repeat: {},
    };
  }

  ngOnInit() {
    this.roleService.getAll()
      .subscribe(data => {
        this.roles = data.data;
      });
    this.listsService.cinema(true)
      .subscribe(data => {
        this.cinemas = data.data;
        this.selectedCinemas = this.cinemas;
      });
    this.distributorService.getAll()
      .subscribe(data => {
        this.distributors = data.data;
      });
    this.multiplexService.getAll()
      .subscribe(data => {
        this.multiplexes = data.data;
      });
    this.addForm = this.formBuilder.group({
      id: [],
      username: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]],
      first_name: ['', [
        Validators.required,
        Validators.maxLength(100),
      ]],
      last_name: ['', [
        Validators.required,
        Validators.maxLength(100),
      ]],
      password: [this.random, Validators.required],
      repeat: [this.random, Validators.required],
      role_id: ['', Validators.required],
      active: [true],
    }, { validator: this.matchingPasswords('password', 'repeat') });
    this.addForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }
  onSubmit() {
    this.onFormValuesChanged();
    if (this.addForm.invalid) {
      return;
    }
    this.userService.create(this.addForm.value)
      .subscribe(
        data => {
          if (!this.withPassword) {
            this.sendResetEmailUser(data.data.username);
          }
          this.dialogRef.close(data);
        },
        error => {
          this.toastr.error(error.error.message, 'Błąd');
        });
  }
  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.addForm.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
  matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      }
    };
  }
  changeRole(event: any) {
    this.addForm.removeControl('cinema_id');
    this.addForm.removeControl('distributor_id');
    this.addForm.removeControl('multiplex_id');
    if (event.value === 2) {
      this.addForm.addControl('cinema_id', new FormControl('', Validators.required));
    }
    else if (event.value === 3) {
      this.addForm.addControl('distributor_id', new FormControl('', Validators.required));
    }
    else if (event.value === 4) {
      this.addForm.addControl('multiplex_id', new FormControl('', Validators.required));
    }
  }
  changeWithPassword() {
    this.withPassword = !this.withPassword;
    if (this.withPassword) {
      this.addForm.controls['password'].setValue('');
      this.addForm.controls['repeat'].setValue('');
    } else {
      this.addForm.controls['password'].setValue(this.random);
      this.addForm.controls['repeat'].setValue(this.random);
    }
  }
  sendResetEmailUser(user: any): void {
    this.passwordResetService.reset(user)
      .pipe(first())
      .subscribe(
        data => {
          this.toastr.success(data.message, 'Sukces');
        },
        error => {
          this.toastr.error(error.error.message, 'Błąd');
        });
  }

  searchCinema(query: string) {
    const result = this.selectCinema(query);
    this.selectedCinemas = result;
  }

  selectCinema(query: string): Cinema[] {
    const result: Cinema[] = [];
    // tslint:disable-next-line:prefer-const
    for (let a of this.cinemas) {
      if ((<any> a).full_name.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  cleanSearchImputCinema() {
    this.searchInputCinema = '';
    this.selectedCinemas = this.cinemas;
  }
}
