import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserProfileService} from 'app/_services/user_profile.service';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    changePassword: FormGroup;
    submitted = false;
    returnUrl: string;
    passkey: string;
    constructor(
        private formBuilder: FormBuilder,
        private userProfileService: UserProfileService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.changePassword = this.formBuilder.group({
            password: ['', Validators.required],
            repeat: ['', Validators.required],
        }, {validator: this.matchingPasswords('password', 'repeat')});
    }

    get f() {return this.changePassword.controls;}

    onSubmit() {
        this.submitted = true;
        if (this.changePassword.invalid) {
            return;
        }
        this.userProfileService.changePassword(this.f.password.value)
            .subscribe(
                data => {
                    this.toastr.success(data.message, 'Sukces');
                    this.changePassword = this.formBuilder.group({
                        password: ['', Validators.required],
                        repeat: ['', Validators.required],
                    }, {validator: this.matchingPasswords('password', 'repeat')});
                },
                error => {
                    this.toastr.error(error.error.message, 'Błąd');
                });
    }
    matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            let passwordInput = group.controls[passwordKey];
            let passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({notEquivalent: true})
            }
        }
    }
}
