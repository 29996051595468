import {Injectable} from '@angular/core';
import {KdmService} from 'app/_services/kdm.service';
import {of as ofObservable, BehaviorSubject} from 'rxjs';

export class CinemaItemNode {
    children: CinemaItemNode[];
    item: string;
    id: string;
}

export class CinemaItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    id: string;
}

@Injectable()
export class CinemaDatabase {
    dataChangeCinema: BehaviorSubject<CinemaItemNode[]> = new BehaviorSubject<CinemaItemNode[]>([]);
    get data(): CinemaItemNode[] {return this.dataChangeCinema.value;}
    constructor(
        private kdmService: KdmService
    ) {
        this.initialize();
    }

    initialize() {
        this.dataChangeCinema = new BehaviorSubject<CinemaItemNode[]>([]);
    }

    buildFileTree(value: any, level: number) {
        let data: any[] = [];
        for (let k in value) {
            let v = value[k];
            let node = new CinemaItemNode();
            node.item = `${k}`;
            if (typeof v.id !== undefined) {
                node.id = `${v.id}`;
            } else {
                node.id = v;
            }
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v.childrens === 'object') {
                node.children = this.buildFileTree(v.childrens, level + 1);
            } else {
                node.item = v;
                node.id = k;
            }
            data.push(node);
        }
        return data;
    }
    insertItem(parent: CinemaItemNode, name: string) {
        const child = <CinemaItemNode> {item: name};
        if (parent.children) {
            parent.children.push(child);
            this.dataChangeCinema.next(this.data);
        }
    }

    updateItem(node: CinemaItemNode, name: string) {
        node.item = name;
        this.dataChangeCinema.next(this.data);
    }
    generateCinemaTree(cinemas: any) {
        this.dataChangeCinema = new BehaviorSubject<CinemaItemNode[]>([]);
        this.kdmService.getByRoom(cinemas)
            .subscribe(data => {
                const to_build: any = [];
                if (data.data != undefined) {
                    for (var _i = 0; _i < data.data.length; _i++) {
                        if (data.data[_i].cinemas.length > 0) {
                            const childrens: any = [];
                            for (var _j = 0; _j < data.data[_i].cinemas.length; _j++) {
                                childrens[data.data[_i].cinemas[_j].id] = data.data[_i].cinemas[_j].full_name;
                            }
                            to_build[data.data[_i].name] = {'id': data.data[_i].id, 'childrens': childrens};
                        }
                    }
                }
                let xdata = this.buildFileTree(to_build, 0);
                this.dataChangeCinema.next(xdata);
            });
        return this.dataChangeCinema;


    }
}