import {Component, OnInit, Inject} from '@angular/core';
import {MovieReleaseService} from 'app/_services/movie_release.service';
import {Router} from "@angular/router";
import {MovieRelease} from 'app/_models/movie_release';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-movie-release-edit',
    templateUrl: './movie-release-edit.component.html',
    styleUrls: ['./movie-release-edit.component.scss']
})
export class MovieReleaseEditComponent implements OnInit {

    movieRelease: MovieRelease;
    editForm: FormGroup;
    formErrors: any;

    constructor(
        public dialogRef: MatDialogRef<MovieReleaseEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private router: Router,
        private movieReleaseService: MovieReleaseService
    ) {
        this.formErrors = {
            name: {},
            active: {},
        };
    }


    ngOnInit() {
        let movieReleaseId = localStorage.getItem("editMovieReleaseId");
        if (!movieReleaseId) {
            alert("Invalid action.")
            this.router.navigate(['movies']);
            return;
        }
        this.editForm = this.formBuilder.group({
            id: [],
            name: ['', [
                Validators.required,
                Validators.maxLength(500)
            ]],
            active: [''],
            movie_id: [],
            created: [],
            img: [],
            certificate_path: [],
            modified: [],
            kdms: [],
            valid_to: [],
            movie_name: [],
            invalid: [],
            deleted: [],
            supplement: [],
        });

        this.movieReleaseService.getById(+movieReleaseId)
            .subscribe(data => {
                this.editForm.setValue(data.data);
            });
        this.editForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onSubmit() {
        this.onFormValuesChanged();
        if (this.editForm.invalid) {
            return;
        }
        this.movieReleaseService.update(this.editForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.dialogRef.close(data);
                },
                error => {
                    alert(error);
                });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.editForm.get(field);
            if (control && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

}
