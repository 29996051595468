import {Component, OnInit, ViewChild} from '@angular/core';
import {MovieKdm} from 'app/_models/movie_kdm';
import {MovieKdmService} from 'app/_services/movie_kdm.service';
import {ListsService} from 'app/_services/lists.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';
import {Http, ResponseContentType} from '@angular/http';
import {CinemaService} from 'app/_services/cinema.service';
import {Page} from 'app/_models/page';

const API_URL = environment.apiUrl;

import * as _moment from 'moment';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime-moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'YYYY-MM-DD H:mm',
    fullPickerInput: 'YYYY-MM-DD H:mm',
    datePickerInput: 'YYYY-MM-DD H:mm',
    timePickerInput: 'YYYY-MM-DD H:mm',
    monthYearLabel: 'YYYY-MM-DD H:mm',
    dateA11yLabel: 'YYYY-MM-DD H:mm',
    monthYearA11yLabel: 'YYYY-MM-DD H:mm',
};

@Component({
    selector: 'app-kdm-reports',
    templateUrl: './kdm-reports.component.html',
    styleUrls: ['./kdm-reports.component.scss'],
    providers: [
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    ]
})
export class KdmReportsComponent implements OnInit {
    @ViewChild('myTable') table: any;
    rows: MovieKdm[] = [];
    loadingIndicator = true;
    reorderable = true;
    api_url = API_URL;
    cinema: string = null;
    cinemas: any[] = [];
    //Search Input--
    selectedCinemas: any[] = this.cinemas;
    searchInputCinema: string = null;
    //--Search Input
    multiplex: string = null;
    multiplexes: any[] = [];
    //Search Input--
    selectedMultiplexes: any[] = this.multiplexes;
    searchInputMultiplex: string = null;
    //--Search Input
    distributor: string = null;
    distributors: any[] = [];
    //Search Input--
    selectedDistributors: any[] = this.distributors;
    searchInputDistributor: string = null;
    //--Search Input
    temp: any = [];
    range: any = null;
    active: number = 1;
    movie: string = null;
    user: any;
    page = new Page();
    sort = null;
    constructor(
        private movieKdmService: MovieKdmService,
        private listsService: ListsService,
        private cinemaService: CinemaService,
        private toastr: ToastrService,
        private http: Http,
    ) {
        this.page.pageNumber = 0;
        this.page.size = 300;
    }

    ngOnInit() {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.user = user;
        this.setPage({offset: 0});
        this.listsService.multiplex(true)
            .subscribe(data => {
                this.multiplexes = data.data;
                this.selectedMultiplexes = this.multiplexes;
            });
        this.listsService.cinema(true)
            .subscribe(data => {
                this.cinemas = data.data;
                this.selectedCinemas = this.cinemas;
            });
        this.listsService.distributor(true)
            .subscribe(data => {
                this.distributors = data.data;
                this.selectedDistributors = this.distributors;
                if (user.role_id == 3 && user.distributor_id) {
                    this.distributor = user.distributor_id;
                }
            });
    }

    setPage(pageInfo: any) {
        this.loadingIndicator = true;
        this.rows = [];
        this.page.pageNumber = pageInfo.offset;
        this.movieKdmService.getAll(this.page, this.sort)
            .subscribe(data => {
                this.page = data.paging;
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
    }

    //Search Input--
    searchMultiplex(query: string) {
        let result = this.selectMultiplex(query)
        this.selectedMultiplexes = result;
    }

    selectMultiplex(query: string): string[] {
        let result: string[] = [];
        for (let a of this.multiplexes) {
            if (a.name.toLowerCase().indexOf(query) > -1) {
                result.push(a)
            }
        }
        return result
    }
    cleanSearchImputMultiplex() {
        this.searchInputMultiplex = '';
        this.selectedMultiplexes = this.multiplexes;
    }
    //--Search Input
    //Search Input--
    searchCinema(query: string) {
        let result = this.selectCinema(query)
        this.selectedCinemas = result;
    }

    selectCinema(query: string): string[] {
        let result: string[] = [];
        for (let a of this.cinemas) {
            if (a.full_name.toLowerCase().indexOf(query) > -1) {
                result.push(a)
            }
        }
        return result
    }
    cleanSearchImputCinema() {
        this.searchInputCinema = '';
        this.selectedCinemas = this.cinemas;
    }
    //--Search Input
    //Search Input--
    searchDistributor(query: string) {
        let result = this.selectDistributor(query)
        this.selectedDistributors = result;
    }

    selectDistributor(query: string): string[] {
        let result: string[] = [];
        for (let a of this.distributors) {
            if (a.name.toLowerCase().indexOf(query) > -1) {
                result.push(a)
            }
        }
        return result
    }
    cleanSearchImputDistributor() {
        this.searchInputDistributor = '';
        this.selectedDistributors = this.distributors;
    }
    //--Search Input

    downloadFile(package_id: any, movie_release_id: any) {
        this.cinemaService.downloadPackage(package_id, movie_release_id)
            .subscribe(data => {
                let url = this.api_url + '/' + data.data;
                const filename = url.split('/').pop();
                return this.http
                    .get(url, {
                        responseType: ResponseContentType.Blob,
                    })
                    .map(res => {
                        return {
                            filename: filename,
                            data: res.blob()
                        };
                    })
                    .subscribe(res => {
                        var url = window.URL.createObjectURL(res.data);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove(); // remove the element
                    }, error => {
                        this.toastr.error(error.error.message, 'Błąd');
                    }, () => {
                    });
            });
    }
    sortTable() {
        this.loadingIndicator = true;
        this.rows = [];
        this.sort = {multiplex: null, distributor: null, cinema: null, range: null, movie: null, active: null};
        if (this.multiplex != null) {
            this.sort.multiplex = this.multiplex;
        }
        if (this.distributor != null) {
            this.sort.distributor = this.distributor;
        }
        if (this.cinema != null) {
            this.sort.cinema = this.cinema;
        }
        if (this.range != null) {
            this.sort.range = this.range;
        }
        if (this.movie != null) {
            this.sort.movie = this.movie;
        }
        this.sort.active = this.active;
        this.movieKdmService.getAll(this.page, this.sort)
            .subscribe(data => {
                this.page = data.paging;
                this.rows = data.data;
                this.temp = [...data.data];
                this.loadingIndicator = false;
            });
    }
    sortMultiplex(temp_sort: any) {
        const val = this.multiplex;
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.multiplex_id == val) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    sortDistributor(temp_sort: any) {
        const val = this.distributor;
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.distributor_id == val) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    sortCinema(temp_sort: any) {
        const val = this.cinema;
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.cinema_id == val) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    updateFilter(temp_sort: any) {
        const val = this.movie.toLowerCase();
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.full_data.release_name.toLowerCase().indexOf(val) !== -1) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    rangeChanged(temp_sort: any) {
        const val_from = moment(this.range[0]).format('YYYY-MM-DD HH:mm');
        const val_to = moment(this.range[1]).format('YYYY-MM-DD HH:mm');
        const temp = temp_sort.filter(function (d) {
            let to_return = false;
            if (d.full_data.generation_date >= val_from && d.full_data.generation_date <= val_to) {
                to_return = true;
            }
            return to_return;
        });
        return temp;
    }
    showActive(temp_sort: any) {
        if (this.active == 1) {
            const temp = temp_sort.filter(function (d) {
                let to_return = false;
                if (d.kdm_date_to > moment().format('YYYY-MM-DD H:mm')) {
                    to_return = true;
                }
                return to_return;
            });
            return temp;
        } else {
            return temp_sort;
        }
    }
    clearValue() {
        this.range = null;
        this.sortTable();
    }
    getRowClass(row: any) {
        if (row.cert_update == true) {
            return 'error-row';
        }
        return '';
    }
    sendAgain(message_id: any) {
        this.movieKdmService.sendAgain(message_id)
            .subscribe(data => {
                this.toastr.success('Wiadomość wysłana', data.data);
            });
    }

    deleteRow(package_id: any) {
        this.movieKdmService.deletePackage(package_id)
            .subscribe(data => {
                this.toastr.success('Paczka usunięta (' + data.data + ' KDM)', 'Sukces');
            });
    }
}
